import React, { useState, useEffect } from 'react'
import { ethers } from 'ethers'
import { useSelector } from 'react-redux'
import { FiRefreshCw } from 'react-icons/fi'
import AnimatedNumber from 'animated-number-react'
import { BiLoaderAlt } from 'react-icons/bi'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { TiLockClosedOutline, TiLockOpenOutline } from 'react-icons/ti'
import Countdown from 'react-countdown'
import AddNetwork from './AddNetwork'
/* global BigInt */
const VCPOOL = process.env.REACT_APP_VPPOOL
const CCOIN = process.env.REACT_APP_CCOIN
const CCSX = process.env.REACT_APP_CCSX

/*
const VCPOOL = '0xE7aAda0d0Fcf7b69244dEB6C50DEeff76e23509C'
const CCOIN = '0xbFB0be698655eCdEb6dDe80831dfb3C6553c4C6D' //BSC Testnet
const CCSX = '0x0D82Bdd0Db436dBc6473B58f0B55d9854cAE3F87' //BSC Testnet

const VCPOOL = '0x805cC3dA74d12a585e4D069Bb4bF22ED5562d8A6' //BSC Mainnet
const CCOIN = '0xc209831f7349D4E200d420326B3401899Ab9Ae68' //BSC Mainnet
const CCSX = '0x1819615B0643c54A6096af42a72C761f85E7ec37' //BSC Mainnet
*/

const abi = require('../abi/abivppool')
const IERC20 = require('../abi/IERC20')
const formatValue = (value) => `${separator(Number(value).toFixed(0))}`
function separator(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const isMetaMaskInstalled = () => {
    const { ethereum } = window
    return Boolean(ethereum && ethereum.isMetaMask)
}

function dynamicSort(property) {
    var sortOrder = 1
    if (property[0] === '-') {
        sortOrder = -1
        property = property.substr(1)
    }
    return function (a, b) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        var result =
            a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
        return result * sortOrder
    }
}

function AddrList({
    unlockTime,
    amount,
    currentTimeStamp,
    isClaimed,
    viewDate,
}) {
    //console.log('currentTimeStamp', currentTimeStamp)
    let str = 'col-span-2 px-3 text-center '
    return (
        <div className="w-full grid grid-cols-3 md:grid-cols-7 gap-3 md:gap-3 grid-flow-row text-gray-700 text-xm border-b py-1 items-center select-none">
            <div className="col-span-3 md:col-span-2 px-3 text-center font-bold md:font-normal md:text-center text-xs">
                {viewDate ? (
                    <Countdown date={unlockTime * 1000} />
                ) : (
                    new Date(unlockTime * 1000).toLocaleString('en-US', {
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hourCycle: 'h24',
                    })
                )}
            </div>
            <div className="col-span-1 md:col-span-2 text-center w-100 text-rose-500 ">
                {unlockTime > currentTimeStamp ? (
                    separator(amount)
                ) : (
                    <div>-</div>
                )}
            </div>

            <div
                className={
                    isClaimed
                        ? 'col-span-1 md:col-span-2 px-3 text-center text-gray-600 line-through'
                        : 'col-span-1 md:col-span-2 px-3 text-center text-blue-600'
                }
            >
                {unlockTime < currentTimeStamp ? (
                    separator(amount)
                ) : (
                    <div>-</div>
                )}
            </div>
            <div className="col-span-1 md:col-span-1 px-3 text-center text-grey-300 text-xs">
                {unlockTime < currentTimeStamp ? (
                    isClaimed ? (
                        <div className="text-center text-gray-600 text-xs">
                            Claimed
                        </div>
                    ) : (
                        <div className="text-center text-blue-600 text-xs">
                            Unclaimed
                        </div>
                    )
                ) : (
                    <div />
                )}
            </div>
        </div>
    )
}

function VCWallet() {
    const [open, setOpen] = useState(false)
    const [totalUnlockToken, setTotalUnlockToken] = useState(0)
    const [totalLockToken, setTotalLockToken] = useState(0)
    const [toatlClaimAbleToken, setToatlClaimAbleToken] = useState(0)
    const [toatlClaimedToken, setToatlClaimedToken] = useState(0)
    const [error, setError] = useState()
    const [info, setInfo] = useState()
    const [account, setAccount] = useState()
    const [ledgers, setLedgers] = useState([])
    const [state, setState] = useState(0)
    const [viewDate, setViewDate] = useState(false)
    const [reLoad, setReload] = useState(0)

    const wc = useSelector((state) => state.walletConnect.value)

    const handleClose = () => {
        setOpen(false)
    }
    const handleToggle = () => {
        setOpen(!open)
    }

    const currentTimeStamp = Math.floor(Date.now() / 1000)

    let provider
    let ethereum = window.ethereum
    let tempSigner

    if (!!wc.account) {
        console.log('WC!')
        ethereum = wc.provider
        provider = wc.web3Provider
        tempSigner = provider.getSigner()
        console.log('tempSignerW:', tempSigner)
        ethereum.on('accountsChanged', (accounts) => setReload(reLoad + 1))
    } else if (isMetaMaskInstalled()) {
        console.log('MT!')
        ethereum = window.ethereum
        provider = new ethers.providers.Web3Provider(ethereum)
        tempSigner = provider.getSigner()
        ethereum.on('accountsChanged', (accounts) => setReload(reLoad + 1))
        console.log('tempSigner:', tempSigner)
    }

    useEffect(() => {
        if (!!wc.account || isMetaMaskInstalled()) {
            getTotalUnlockToken()
            getTotalLockToken()
            getClaimAbleTokens()
            getClaimedToken()

            console.log('account:', !!account)
            console.log('account:', account)
            setInfo('')
            const getWalletConnect = JSON.parse(
                localStorage.getItem('walletconnect')
            )
            if (!!getWalletConnect) {
                console.log('getWalletConnect:', getWalletConnect.accounts[0])
                setAccount(getWalletConnect.accounts[0])
            } else {
                getCurrentAccount()
            }
        }
        return () => {
            setLedgers([])
        }
    }, [state, reLoad, wc.account])

    console.log('account:', account)

    async function getCurrentAccount() {
        const accounts = await ethereum.request({
            method: 'eth_accounts',
        })
        setAccount(accounts[0])
        return accounts[0]
    }

    async function signMgs() {
        const message = 'The Smallest Parts Are Every Where!'
        await tempSigner.signMessage(message).then((signature) => {
            console.log('Signature:', signature)
            // Recover Address from signature
            const result = ethers.utils.verifyMessage(message, signature)
            console.log('Recovered Address:', result)
        })
    }

    async function getTotalUnlockToken() {
        const smcPool = new ethers.Contract(VCPOOL, abi, tempSigner)
        setTotalUnlockToken(
            ethers.utils.formatEther(
                await smcPool.callStatic.checkUnLockedTokens()
            )
        )
    }
    async function getTotalLockToken() {
        const smcPool = new ethers.Contract(VCPOOL, abi, tempSigner)
        //const address = await tempSigner.getAddress()
        //const numOfLedger = await smcPool.callStatic.ledgerLength(account)
        setTotalLockToken(
            ethers.utils.formatEther(
                await smcPool.callStatic.checkLockedTokens()
            )
        )
    }

    async function getClaimedToken() {
        const smcPool = new ethers.Contract(VCPOOL, abi, tempSigner)
        //const address = await tempSigner.getAddress()
        //const numOfLedger = await smcPool.callStatic.ledgerLength(account)
        setToatlClaimedToken(
            ethers.utils.formatEther(
                await smcPool.callStatic.checkClaimedTokens()
            )
        )
    }

    async function getClaimAbleTokens() {
        //console.log('setLedgersAfterReset', ledgers)
        const smcPool = new ethers.Contract(VCPOOL, abi, tempSigner)
        const address = await tempSigner.getAddress()
        const numOfLedger = await smcPool.callStatic.ledgerLength(address)
        //console.log('tempSigner.getAddress()', await tempSigner.getAddress())

        console.log('numOfLedger', numOfLedger.toString())
        const checkClaimAbleTokens =
            await smcPool.callStatic.checkClaimAbleTokens()
        console.log(
            'checkClaimAbleTokens',
            ethers.utils.formatEther(checkClaimAbleTokens)
        )
        setToatlClaimAbleToken(ethers.utils.formatEther(checkClaimAbleTokens))
        let ledgerAr = []
        for (let i = 0; i < numOfLedger.toString(); i++) {
            ledgerAr.push(await smcPool.callStatic.ledgers(address, i))
        }

        ledgerAr.sort(dynamicSort('unlockTime'))
        setLedgers(ledgerAr)
    }

    async function claimStake() {
        const smcPool = new ethers.Contract(VCPOOL, abi, tempSigner)
        console.log('tempSigner:', tempSigner)
        const tx = await smcPool.claimStake().catch((err) => {
            console.log(err)
            handleClose()
            //setError(err)
        })

        setInfo('Please wait for block confirmation.')
        const receipt = await tx.wait(1).then((tx) => {
            setState(0)
            setReload(reLoad + 1)
            setInfo('')
            handleClose()
        })
        setInfo('')
    }

    return (
        <div className="flex flex-col w-full justifyitem-center items-center mt-20 select-none">
            <AddNetwork CCOIN={CCOIN} CCSX={CCSX} />
            <div className="md:w-3/5 w-full p-5 flex flex-col justifyitem-center items-center drop-shadow-lg rounded-xl text-black bg-neutral-100 bg-opacity-70 backdrop-blur-xl">
                <FiRefreshCw
                    className="self-end mt-2 text-gray-600 cursor-pointer hover:animate-spin"
                    onClick={() => {
                        setReload(reLoad + 1)
                    }}
                />
                <span className="text-blue-400 font-bold text-sm my-1">
                    <br />
                </span>
                <div className="hidden md:block blur-sm absolute -top-4 md:right-4 text-3xl md:text-4xl self-center -mb-3 font-bold text-yellow-700 ">
                    M y A c c o u n t
                </div>
                <div className="hidden md:block absolute -top-5 md:right-5 text-3xl md:text-4xl self-center -mb-3 font-bold text-white ">
                    M y A c c o u n t
                </div>
                <div className="md:hidden -mt-14 text-3xl font-bold text-yellow-700 blur-sm">
                    M y A c c o u n t
                </div>
                <div className="md:hidden -mt-10 text-3xl font-bold text-white z-40">
                    M y A c c o u n t
                </div>

                <span className="text-blue-600 font-bold text-sm my-1">
                    {info}
                </span>
                <div className="text-xl md:block hidden self-center mb-3 font-bold text-gray-700 break-all">
                    {!!account ? account : null}
                </div>
                <div className="text-xl md:hidden self-center mb-3 font-bold text-gray-700 break-all">
                    {!!account
                        ? account.slice(0, 5) + '....' + account.slice(-6)
                        : null}
                </div>

                <br />

                <div className="w-full mt-5 grid grid-cols-2 md:grid-cols-3 gap-1 grid-flow-row text-sm text-center text-gray-500 font-bold content-center ">
                    <div className="col-span-3 justify-self-center text-md md:text-2xl text-black flex flex-col border rounded-md w-full p-2 border-[#4b4314]/50">
                        <span className="text-xs font-medium -mt-1">
                            Total Owned
                        </span>

                        <AnimatedNumber
                            value={
                                parseInt(totalLockToken) +
                                parseInt(totalUnlockToken)
                            }
                            formatValue={formatValue}
                            duration="600"
                        />
                    </div>
                    <div className="col-span-2 justify-self-center text-md md:text-2xl text-purple-600 flex flex-col border rounded-md w-full p-2 border-[#4b4314]/50">
                        <span className="text-xs font-medium -mt-1">
                            Account Balance
                        </span>

                        <AnimatedNumber
                            className=""
                            value={
                                parseInt(totalLockToken) +
                                parseInt(totalUnlockToken) -
                                parseInt(toatlClaimedToken)
                            }
                            formatValue={formatValue}
                            duration="600"
                        />
                    </div>

                    <div className="col-span-1 row-span-1 justify-self-center text-md md:text-lg text-gray-600 flex flex-col rounded-md border w-full p-2 border-[#4b4314]/50">
                        <span className="text-xs font-medium -mt-1 ">
                            Total Claimed
                        </span>
                        <AnimatedNumber
                            className="line-through "
                            value={toatlClaimedToken}
                            formatValue={formatValue}
                            duration="600"
                        />
                    </div>

                    <div className="col-span-1 justify-self-center text-md md:text-lg text-rose-500 flex flex-col rounded-md border w-full p-2 border-[#4b4314]/50">
                        <span className="text-xs font-medium -mt-1">
                            Total Locked
                        </span>
                        <AnimatedNumber
                            value={totalLockToken}
                            formatValue={formatValue}
                            duration="600"
                        />
                    </div>
                    <div className="col-span-1 justify-self-center text-md md:text-lg text-green-600 flex flex-col rounded-md border w-full p-2 border-[#4b4314]/50">
                        <span className="text-xs font-medium -mt-1">
                            Total Unlocked
                        </span>
                        <AnimatedNumber
                            value={totalUnlockToken}
                            formatValue={formatValue}
                            duration="600"
                        />
                    </div>
                    <div className="col-span-1 row-span-1 justify-self-center text-md md:text-lg text-blue-600 flex flex-col rounded-md border w-full p-2 border-[#4b4314]/50">
                        <span className="text-xs font-medium -mt-1">
                            Total Unclaimed
                        </span>
                        <AnimatedNumber
                            value={toatlClaimAbleToken}
                            formatValue={formatValue}
                            duration="600"
                        />
                    </div>
                </div>
                <div className="w-full mt-5 grid grid-cols-3 md:grid-cols-7 gap-3 grid-flow-row border-b text-xs md:text-sm text-center text-gray-500 font-bold content-center border-[#4b4314]/50 ">
                    <div
                        onClick={() => setViewDate(!viewDate)}
                        className="col-span-3 md:col-span-2 cursor-pointer select-none underline "
                    >
                        {viewDate ? 'Unlock Time' : 'Unlock Date'}
                    </div>
                    <div className="col-span-1 md:col-span-2 justify-self-center text-lg text-rose-500 ">
                        <TiLockClosedOutline />
                    </div>
                    <div className="col-span-1 md:col-span-2 justify-self-center text-lg text-blue-600">
                        <TiLockOpenOutline />
                    </div>
                    <div className="col-span-1 md:col-span-1">Status</div>
                </div>
                <div className="w-full flex flex-col rounded-md justify-between text-sm ">
                    {ledgers.length > 0 ? (
                        ledgers.map((x, i) => (
                            <AddrList
                                key={i}
                                unlockTime={x[0].toString()}
                                amount={ethers.utils.formatEther(x[1])}
                                currentTimeStamp={currentTimeStamp}
                                isClaimed={x[2]}
                                viewDate={viewDate}
                            />
                        ))
                    ) : (
                        <div className="flex flex-row text-zinc-600 my-1  items-center content-center text-center mt-5">
                            <div
                                id="loadingBox"
                                className="flex flex-row items-center ml-10"
                            >
                                <BiLoaderAlt className="animate-spin mr-2 " />
                                <span className="animate-pulse">
                                    Loading ...
                                </span>
                            </div>
                            <div className="hidden">
                                {setTimeout(() => {
                                    try {
                                        document.getElementById(
                                            'loadingBox'
                                        ).textContent = 'Unable to load data.'
                                    } catch (e) {
                                        //console.error(e)
                                    }
                                }, 9000)}
                            </div>
                        </div>
                    )}
                </div>

                {toatlClaimAbleToken > 0 ? (
                    <button
                        type="button"
                        onClick={() => {
                            setInfo('Waiting for your confirmation on Metamask')
                            handleToggle()
                            setError('')
                            claimStake()
                        }}
                        className="text-white w-full mt-2 p-2 border rounded-md bg-[#474747] transition-all hover:bg-[#ffae00] cursor-pointer"
                    >
                        Claim {separator(toatlClaimAbleToken)} Token
                    </button>
                ) : (
                    <div className="text-white text-center w-full mt-2 border-[1px] p-2 bg-[#a8a8a8] rounded-xl select-none">
                        No Claimable Token
                    </div>
                )}
                <span className="text-red-600 font-bold text-sm my-1">
                    {error}
                </span>
            </div>

            {
                ////////////////////// Stake CCSX /////////////////////////
            }

            {
                ///////////////////////////////////////////////
            }
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
                className="flex flex-col"
            >
                <div className="bg-white m-4 text-center text-gray-900 text-lg p-3 rounded-lg md:w-2/5 w-full display-linebreak">
                    <CircularProgress color="inherit" />
                    <br />
                    {info}
                </div>
                <br />
            </Backdrop>
        </div>
    )
}

export default VCWallet
