import React, { useState, useEffect } from 'react'
import { ethers } from 'ethers'
import { useSelector } from 'react-redux'
import { AiOutlineCopy, AiOutlineLoading3Quarters } from 'react-icons/ai'
import Backdrop from '@mui/material/Backdrop'
import AnimatedNumber from 'animated-number-react'

import 'react-calendar/dist/Calendar.css'
import Links from './Links'

const CCOIN = process.env.REACT_APP_CCOIN
const CCSX = process.env.REACT_APP_CCSX
const DMTEC = process.env.REACT_APP_DMTEC
const SLRZ1 = process.env.REACT_APP_SLRZ1
const OLMSV = process.env.REACT_APP_OLMSV
const MINMT = process.env.REACT_APP_MINMT
const KRTMT = process.env.REACT_APP_KRTMT
const ICEMINT = process.env.REACT_APP_ICEMINT
/* global BigInt */

const formatValueBNB = (value) => `${separator(Number(value).toFixed(0))}`

function separator(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function ERC20() {
    const [account, setAccount] = useState()

    const [DMTECTotalSupply, setDMTECTotalSupply] = useState()
    const [DMTECAdmin, setDMTECAdmin] = useState()
    const [DMTECOperator, setDMTECOperator] = useState()
    const [DMTECname, setDMTECname] = useState()
    const [DMTECSymbol, setDMTECSymbol] = useState()

    const [SLRZ1TotalSupply, setSLRZ1TotalSupply] = useState()
    const [SLRZ1Admin, setSLRZ1Admin] = useState()
    const [SLRZ1Operator, setSLRZ1Operator] = useState()
    const [SLRZ1name, setSLRZ1name] = useState()
    const [SLRZ1Symbol, setSLRZ1Symbol] = useState()

    const [OLMSVTotalSupply, setOLMSVTotalSupply] = useState()
    const [OLMSVAdmin, setOLMSVAdmin] = useState()
    const [OLMSVOperator, setOLMSVOperator] = useState()
    const [OLMSVname, setOLMSVname] = useState()
    const [OLMSVSymbol, setOLMSVSymbol] = useState()

    const [MINMTTotalSupply, setMINMTTotalSupply] = useState()
    const [MINMTAdmin, setMINMTAdmin] = useState()
    const [MINMTOperator, setMINMTOperator] = useState()
    const [MINMTname, setMINMTname] = useState()
    const [MINMTSymbol, setMINMTSymbol] = useState()

    const [KRTMTTotalSupply, setKRTMTTotalSupply] = useState()
    const [KRTMTAdmin, setKRTMTAdmin] = useState()
    const [KRTMTOperator, setKRTMTOperator] = useState()
    const [KRTMTname, setKRTMTname] = useState()
    const [KRTMTSymbol, setKRTMTSymbol] = useState()

    const [ICEMINTTotalSupply, setICEMINTTotalSupply] = useState()
    const [ICEMINTAdmin, setICEMINTAdmin] = useState()
    const [ICEMINTOperator, setICEMINTOperator] = useState()
    const [ICEMINTname, setICEMINTname] = useState()
    const [ICEMINTSymbol, setICEMINTSymbol] = useState()

    const [error, setError] = useState()
    const [info, setInfo] = useState()
    const [state, setState] = useState(false)
    const [loading, setLoading] = useState(false)

    const wc = useSelector((state) => state.walletConnect.value)

    const ierc20 = require('../abi/IERC20')
    let provider
    let ethereum = window.ethereum
    let tempSigner

    const isMetaMaskInstalled = () => {
        const { ethereum } = window
        return Boolean(ethereum && ethereum.isMetaMask)
    }

    if (!!wc.account) {
        console.log('WC!')
        ethereum = wc.provider
        provider = wc.web3Provider
        tempSigner = provider.getSigner()
        ethereum.on('accountsChanged', (accounts) => setState(!state))
    } else if (isMetaMaskInstalled()) {
        console.log('MT!')
        ethereum = window.ethereum
        provider = new ethers.providers.Web3Provider(ethereum)
        tempSigner = provider.getSigner()
        ethereum.on('accountsChanged', (accounts) => setState(!state))
    }

    useEffect(() => {
        if (!!wc.account || isMetaMaskInstalled()) {
            getCurrentAccount()
        }

        getTotalSupply(DMTEC).then((x) => setDMTECTotalSupply(x))
        getAdminAddr(DMTEC).then((x) => setDMTECAdmin(x))
        getName(DMTEC).then((x) => setDMTECname(x))
        getSymbol(DMTEC).then((x) => setDMTECSymbol(x))
        getOperatorAddr(DMTEC).then((x) => setDMTECOperator(x))

        getTotalSupply(SLRZ1).then((x) => setSLRZ1TotalSupply(x))
        getAdminAddr(SLRZ1).then((x) => setSLRZ1Admin(x))
        getName(SLRZ1).then((x) => setSLRZ1name(x))
        getSymbol(SLRZ1).then((x) => setSLRZ1Symbol(x))
        getOperatorAddr(SLRZ1).then((x) => setSLRZ1Operator(x))

        getTotalSupply(OLMSV).then((x) => setOLMSVTotalSupply(x))
        getAdminAddr(OLMSV).then((x) => setOLMSVAdmin(x))
        getName(OLMSV).then((x) => setOLMSVname(x))
        getSymbol(OLMSV).then((x) => setOLMSVSymbol(x))
        getOperatorAddr(OLMSV).then((x) => setOLMSVOperator(x))

        getTotalSupply(MINMT).then((x) => setMINMTTotalSupply(x))
        getAdminAddr(MINMT).then((x) => setMINMTAdmin(x))
        getName(MINMT).then((x) => setMINMTname(x))
        getSymbol(MINMT).then((x) => setMINMTSymbol(x))
        getOperatorAddr(MINMT).then((x) => setMINMTOperator(x))

        getTotalSupply(KRTMT).then((x) => setKRTMTTotalSupply(x))
        getAdminAddr(KRTMT).then((x) => setKRTMTAdmin(x))
        getName(KRTMT).then((x) => setKRTMTname(x))
        getSymbol(KRTMT).then((x) => setKRTMTSymbol(x))
        getOperatorAddr(KRTMT).then((x) => setKRTMTOperator(x))

        getTotalSupply(ICEMINT).then((x) => setICEMINTTotalSupply(x))
        getAdminAddr(ICEMINT).then((x) => setICEMINTAdmin(x))
        getName(ICEMINT).then((x) => setICEMINTname(x))
        getSymbol(ICEMINT).then((x) => setICEMINTSymbol(x))
        getOperatorAddr(ICEMINT).then((x) => setICEMINTOperator(x))

        return () => {}
    }, [state, wc.account, account])

    async function getCurrentAccount() {
        const accounts = await ethereum.request({
            method: 'eth_accounts',
        })
        setAccount(accounts[0])
        console.log('accounts[0]:', accounts[0])
        return accounts[0]
    }

    async function getTotalSupply(_Addr) {
        let _total
        const _SLRZ1Supply = new ethers.Contract(_Addr, ierc20, tempSigner)
        const Direct = await _SLRZ1Supply.totalSupply().then((x) => {
            //console.log('totalSupply():', parseInt(ethers.utils.formatEther(x)))
            _total = parseInt(ethers.utils.formatEther(x))
        })
        return _total
    }

    async function getAdminAddr(_Addr) {
        let _addr
        const _AdminAddr = new ethers.Contract(_Addr, ierc20, tempSigner)
        const Direct = await _AdminAddr.adminAddress().then((x) => {
            //console.log('adminAddress:', x.toLowerCase())
            _addr = x.toLowerCase()
        })
        return _addr
    }

    async function getOperatorAddr(_Addr) {
        let _addr
        const _AdminAddr = new ethers.Contract(_Addr, ierc20, tempSigner)
        const Direct = await _AdminAddr.operatorAddress().then((x) => {
            //console.log('adminAddress:', x.toLowerCase())
            _addr = x.toLowerCase()
        })
        return _addr
    }

    async function getName(_Addr) {
        let _name
        const _getName = new ethers.Contract(_Addr, ierc20, tempSigner)
        const Direct = await _getName.name().then((x) => {
            //console.log('getName:', x)
            _name = x
        })
        return _name
    }

    async function getSymbol(_Addr) {
        let _symbol
        const _getSymbol = new ethers.Contract(_Addr, ierc20, tempSigner)
        const Direct = await _getSymbol.symbol().then((x) => {
            //console.log('getSymbol:', x)
            _symbol = x
        })
        return _symbol
    }

    async function mint(_Addr, _to, _amt) {
        const mint = new ethers.Contract(_Addr, ierc20, tempSigner)
        const tx = await mint.mint(_to, _amt.toString() + '000000000000000000')
        setInfo('Waiting for block confirmation')
        const txx = await tx.wait(3).then(() => {
            setInfo('Transaction Complete')
            setLoading(false)
            setState(!state)
        })
    }

    async function burn(_Addr, _to, _amt) {
        const burn = new ethers.Contract(_Addr, ierc20, tempSigner)
        const tx = await burn.burn(_to, _amt.toString() + '000000000000000000')
        setInfo('Waiting for block confirmation')
        const txx = await tx.wait(3).then(() => {
            setInfo('Transaction Complete')
            setLoading(false)
            setState(!state)
        })
    }

    function Coin({ name, symbol, admin, operator, totalSupply, address }) {
        !!admin ? (admin = admin) : (admin = '00000000000000000000000000')
        !!operator
            ? (operator = operator)
            : (operator = '00000000000000000000000000')
        return (
            <div className="w-full mt-5 grid grid-flow-row grid-cols-2 gap-1">
                <div className="col-span-2 justify-self-center text-mg text-grey-500 flex flex-col border rounded-md w-full p-2 border-[#4b4314]">
                    <div className="flex flex-row">
                        <div className="w-1/2  justify-start">
                            {name}({symbol})
                        </div>
                        <div className="w-1/2 justify-end  flex flex-row">
                            <a
                                href={`https://bscscan.com/address/${address}`}
                                target="_blank"
                            >
                                {address.slice(0, 6) +
                                    '....' +
                                    address.slice(-6)}
                            </a>{' '}
                            <a href="#">
                                <AiOutlineCopy
                                    className="text-lg transition-all hover:text-[#ffae00] ml-2"
                                    onClick={() =>
                                        navigator.clipboard.writeText(address)
                                    }
                                />
                            </a>
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <div className="w-1/2 text-sm justify-start">Admin</div>
                        <div className="w-1/2 text-sm justify-end  flex flex-row">
                            <div className="w-1/2 justify-end  flex flex-row">
                                <a
                                    href={`https://bscscan.com/address/${admin}`}
                                    target="_blank"
                                >
                                    {admin.slice(0, 6) +
                                        '....' +
                                        admin.slice(-6)}
                                </a>{' '}
                                <a href="#">
                                    <AiOutlineCopy
                                        className="text-lg transition-all hover:text-[#ffae00] ml-2"
                                        onClick={() =>
                                            navigator.clipboard.writeText(admin)
                                        }
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <div className="w-1/2 text-sm justify-start">
                            Operator
                        </div>
                        <div className="w-1/2 text-sm justify-end  flex flex-row">
                            <div className="w-1/2 justify-end  flex flex-row">
                                <a
                                    href={`https://bscscan.com/address/${operator}`}
                                    target="_blank"
                                >
                                    {operator.slice(0, 6) +
                                        '....' +
                                        operator.slice(-6)}
                                </a>{' '}
                                <a href="#">
                                    <AiOutlineCopy
                                        className="text-lg transition-all hover:text-[#ffae00] ml-2"
                                        onClick={() =>
                                            navigator.clipboard.writeText(
                                                operator
                                            )
                                        }
                                    />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-row">
                        <div className="w-1/2 text-sm justify-start">
                            Total Supply
                        </div>
                        <div className="w-1/2 text-sm justify-end  flex flex-row">
                            <AnimatedNumber
                                className=""
                                value={totalSupply}
                                formatValue={formatValueBNB}
                                duration="600"
                            />
                            <span className="ml-2">{symbol}</span>
                        </div>
                    </div>

                    <div className="flex flex-row">
                        <input
                            id={'recipient' + address}
                            placeholder="Recipient"
                            type="text"
                            autoComplete="off"
                            className="w-full justify-start rounded-lg p-1 text-sm font-bold active:outline-none focus:outline-none text-center"
                        />
                    </div>

                    <div className="flex flex-row mt-1">
                        <input
                            id={'amt' + address}
                            placeholder="Amount"
                            type="text"
                            keyboardtype="decimal-pad"
                            pattern="[0-9]*"
                            step="1"
                            autoComplete="off"
                            className="w-1/2 rounded-lg p-1 text-sm font-bold active:outline-none focus:outline-none text-center"
                        />
                        {!!account && !!admin ? (
                            account.toLowerCase() == admin ||
                            account.toLowerCase() == operator ? (
                                <>
                                    <button
                                        onClick={() => {
                                            if (
                                                document.getElementById(
                                                    'recipient' + address
                                                ).value == '' ||
                                                document.getElementById(
                                                    'amt' + address
                                                ).value == ''
                                            ) {
                                                setInfo('')
                                                setError('Incomplete Input')
                                            } else {
                                                setLoading(true)
                                                setInfo(
                                                    'Please Confirm on Metamask'
                                                )
                                                setError('')

                                                mint(
                                                    address,
                                                    document.getElementById(
                                                        'recipient' + address
                                                    ).value,
                                                    document.getElementById(
                                                        'amt' + address
                                                    ).value
                                                )
                                            }
                                        }}
                                        id={'mint' + address}
                                        className=" mx-1 w-1/4 bg-[#0099ff] rounded-lg p-2 text-xs font-bold text-white transition-all hover:bg-[#86c6fa]"
                                    >
                                        M i n t
                                    </button>
                                    <button
                                        onClick={() => {
                                            if (
                                                document.getElementById(
                                                    'recipient' + address
                                                ).value == '' ||
                                                document.getElementById(
                                                    'amt' + address
                                                ).value == ''
                                            ) {
                                                setInfo('')
                                                setError('Incomplete Input')
                                            } else {
                                                setLoading(true)
                                                setInfo(
                                                    'Please Confirm on Metamask'
                                                )
                                                setError('')

                                                burn(
                                                    address,
                                                    document.getElementById(
                                                        'recipient' + address
                                                    ).value,
                                                    document.getElementById(
                                                        'amt' + address
                                                    ).value
                                                )
                                            }
                                        }}
                                        className=" w-1/4 bg-[#700000] rounded-lg p-2 text-xs font-bold text-white transition-all hover:bg-[#ff0000]"
                                    >
                                        B u r n
                                    </button>
                                </>
                            ) : (
                                <>
                                    <button className=" mx-1 w-1/4 bg-[#9c9c9c] rounded-lg p-2 text-xs font-bold text-white transition-all cursor-auto">
                                        M i n t
                                    </button>
                                    <button className=" w-1/4 bg-[#9c9c9c] rounded-lg p-2 text-xs font-bold text-white transition-all cursor-auto">
                                        B u r n
                                    </button>
                                </>
                            )
                        ) : (
                            <>
                                <button className=" mx-1 w-1/4 bg-[#9c9c9c] rounded-lg p-2 text-xs font-bold text-white transition-all cursor-auto">
                                    M i n t
                                </button>
                                <button className=" w-1/4 bg-[#9c9c9c] rounded-lg p-2 text-xs font-bold text-white transition-all cursor-auto">
                                    B u r n
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="flex flex-col w-full justifyitem-center items-center mt-20 h-screen select-none">
            <Links CCOIN={CCOIN} CCSX={CCSX} />

            <div className="mt-10 md:w-3/5 w-full flex flex-col p-5  justifyitem-center mb-10 items-center drop-shadow-lg rounded-xl text-black bg-neutral-100 bg-opacity-70 backdrop-blur-xl">
                <span className="text-blue-400 font-bold text-sm my-1">
                    <br />
                </span>
                <div className=" blur-sm absolute -top-4 right-4 text-3xl md:text-4xl self-center -mb-3 font-bold text-yellow-700 break-all">
                    E R C 2 0
                </div>
                <div className=" absolute -top-5 right-5 text-3xl md:text-4xl self-center -mb-3 font-bold text-white break-all">
                    E R C 2 0
                </div>
                <div className="w-full font-bold text-center text-md">
                    <span className="text-blue-500">{info}</span>
                    <span className="text-red-500">{error}</span>
                </div>
                <Coin
                    name={DMTECname}
                    symbol={DMTECSymbol}
                    admin={DMTECAdmin}
                    operator={DMTECOperator}
                    totalSupply={DMTECTotalSupply}
                    address={DMTEC}
                />
                <Coin
                    name={SLRZ1name}
                    symbol={SLRZ1Symbol}
                    admin={SLRZ1Admin}
                    operator={SLRZ1Operator}
                    totalSupply={SLRZ1TotalSupply}
                    address={SLRZ1}
                />
                <Coin
                    name={OLMSVname}
                    symbol={OLMSVSymbol}
                    admin={OLMSVAdmin}
                    operator={OLMSVOperator}
                    totalSupply={OLMSVTotalSupply}
                    address={OLMSV}
                />
                <Coin
                    name={MINMTname}
                    symbol={MINMTSymbol}
                    admin={MINMTAdmin}
                    operator={MINMTOperator}
                    totalSupply={MINMTTotalSupply}
                    address={MINMT}
                />
                <Coin
                    name={KRTMTname}
                    symbol={KRTMTSymbol}
                    admin={KRTMTAdmin}
                    operator={KRTMTOperator}
                    totalSupply={KRTMTTotalSupply}
                    address={KRTMT}
                />
                <Coin
                    name={ICEMINTname}
                    symbol={ICEMINTSymbol}
                    admin={ICEMINTAdmin}
                    operator={ICEMINTOperator}
                    totalSupply={ICEMINTTotalSupply}
                    address={ICEMINT}
                />
            </div>
            <div className="h-[200px]"></div>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
                className="flex flex-col"
            >
                <AiOutlineLoading3Quarters
                    color="inherit"
                    fontSize={20}
                    className="mx-3 font-bold animate-spin"
                />
            </Backdrop>
        </div>
    )
}

export default ERC20
