import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";

import {
  FaChevronRight,
  FaParachuteBox,
  FaRegChartBar,
  FaFileContract,
} from "react-icons/fa";
import { BiPackage, BiWalletAlt } from "react-icons/bi";
import { GiTwoCoins } from "react-icons/gi";
import { GrStakeholder } from "react-icons/gr";
import {
  BsCashCoin,
  BsCurrencyExchange,
  BsEgg,
  BsClockHistory,
  BsShop,
} from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { getWalletConnect, clearAccount } from "../redux/walletConnect";
import { MdOutlineAccountBalanceWallet, MdVerified } from "react-icons/md";
const isMetaMaskInstalled = () => {
  //Have to check the ethereum binding on the window object to see if it's installed
  const { ethereum } = window;
  return Boolean(ethereum && ethereum.isMetaMask);
};

function Links({ CCOIN, CCSX }) {
  const [showModal, setShowModal] = useState(false);
  const [account, setAccount] = useState(null);

  const onClick = () => {
    setShowModal(!showModal);
  };

  const WC = useDispatch();
  const wc = useSelector((state) => state.walletConnect.value);
  let chain;
  let web3Provider = window.ethereum;

  useEffect(() => {
    if (!!wc.account) {
      setAccount(wc.account);
      WC(getWalletConnect());
      web3Provider = wc.provider;
    } else if (isMetaMaskInstalled()) {
      web3Provider = window.ethereum;
    }
    return () => {};
  }, [account]);

  async function addTokenFunction(_coin, n) {
    let cimg;
    let symbol;
    if (n == 1) {
      cimg = "https://pet.crypterium.game/img/coins/ccoin.png";
      symbol = "CCOIN";
    } else {
      cimg = "https://pet.crypterium.game/img/coins/ccsx.png";
      symbol = "CCSX";
    }

    try {
      const wasAdded = await web3Provider.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: _coin, // BSC Testnet
            //address: '0xc209831f7349D4E200d420326B3401899Ab9Ae68', // BSC Mainnet
            symbol: symbol,
            decimals: 18,
            //image: 'http://ccoin.crypterium.game/img/ccoin.png',
            image: process.env.PUBLIC_URL + cimg,
          },
        },
      });

      if (wasAdded) {
        console.log("Thanks for your interest!");
      } else {
        console.log("HelloWorld Coin has not been added");
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function switchNetwork() {
    try {
      await web3Provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${Number(97).toString(16)}` }],
      });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await web3Provider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: `0x${Number(56).toString(16)}`,
                chainName: "BSC Network",
                nativeCurrency: {
                  name: "Binance Smart Chain Mainnet",
                  symbol: "BNB",
                  decimals: 18,
                },
                rpcUrls: ["https://bsc-dataseed1.binance.org"],
                blockExplorerUrls: ["https://bscscan.com"],
              },
              /*{
                                chainId: `0x${Number(97).toString(16)}`,
                                chainName: 'BSC Testnet',
                                nativeCurrency: {
                                    name: 'Binance Chain Native Token',
                                    symbol: 'BNB',
                                    decimals: 18,
                                },
                                rpcUrls: [
                                    'https://data-seed-prebsc-1-s1.binance.org:8545',
                                ],
                                blockExplorerUrls: [
                                    'https://testnet.bscscan.com',
                                ],
                            },*/
            ],
          });
        } catch (addError) {
          // handle "add" error
        }
      }
      // handle other "switch" errors
    }
  }

  return (
    <div
      className={` hidden fixed select-none left-0 right-0 -top-4 my-24 w-56 z-40 p-2 bg-white md:flex flex-col text-center self-center items-center drop-shadow-lg rounded-xl text-black transition-all duration-500  ${
        showModal ? "-translate-x-2" : "-translate-x-56"
      }`}
    >
      <span
        onClick={onClick}
        className={`absolute  w-20 justify-end bg-white flex flex-row rounded-lg  -right-8 top-0 p-2 text-xs items-center cursor-pointer transition-all duration-500 ${
          showModal ? "-translate-x-6 " : "hover:translate-x-4 "
        }`}
      >
        <img
          className={`hover:scale-125 transition-all duration-500 ${
            showModal ? "opacity-0" : "opacity-100 delay-100"
          }`}
          width={18}
          src={process.env.PUBLIC_URL + "/img/metamask.png"}
        />
        <FaChevronRight
          className={` transition-all ${showModal ? "rotate-180" : ""}`}
        />
      </span>
      <img
        onClick={onClick}
        className="scale-75 cursor-pointer"
        src={process.env.PUBLIC_URL + "/img/mm-logo.svg"}
      />

      <br />
      <button className="my-1 w-4/5 bg-white rounded-lg shadow-lg text-xs p-2 hover:scale-110 transition-all hover:bg-[#ffae00]">
        <Link to="/admin">
          <span className="flex flex-row items-center justify-center font-bold text-red-700">
            <BiPackage className="mx-2 -ml-3" /> Package Admin
          </span>
        </Link>
      </button>

      <button className="my-1 w-4/5 bg-white rounded-lg shadow-lg text-xs p-2 hover:scale-110 transition-all hover:bg-[#ffae00]">
        <Link to="/Relayers">
          <span className="flex flex-row items-center justify-center font-bold text-red-700">
            <FaFileContract className="mx-2 -ml-3" /> Relayers
          </span>
        </Link>
      </button>

      <button className="my-1 w-4/5 bg-white rounded-lg shadow-lg text-xs p-2 hover:scale-110 transition-all hover:bg-[#ffae00]">
        <Link to="/ERC20">
          <span className="flex flex-row items-center justify-center font-bold text-red-700">
            <GiTwoCoins className="mx-2 -ml-3" /> ERC20
          </span>
        </Link>
      </button>

      <button className="my-1 w-4/5 bg-white rounded-lg shadow-lg text-xs p-2 hover:scale-110 transition-all hover:bg-[#ffae00]">
        <Link to="/airdrop">
          <span className="flex flex-row items-center justify-center font-bold">
            <FaParachuteBox className="mx-2 -ml-3" /> Airdrop
          </span>
        </Link>
      </button>

      <button className="my-1 w-4/5 bg-white rounded-lg shadow-lg text-xs p-2 hover:scale-110 transition-all hover:bg-[#ffae00]">
        <Link to="/">
          <span className="flex flex-row items-center justify-center font-bold">
            <BsCurrencyExchange className="mx-2 -ml-3" />
            Cloud Sale
          </span>
        </Link>
      </button>
      <button className="my-1 w-4/5 bg-white rounded-lg shadow-lg text-xs p-2 hover:scale-110 transition-all hover:bg-[#ffae00]">
        <a href="https://pet.crypterium.game/egg" rel="noreferrer">
          <span className="flex flex-row items-center justify-center font-bold">
            <BsEgg className="mx-2 -ml-3" />
            Egg Hatch
          </span>
        </a>
      </button>
      <button className="my-1 w-4/5 bg-white rounded-lg shadow-lg text-xs p-2 hover:scale-110 transition-all hover:bg-[#ffae00]">
        <a href="https://ccoin.crypterium.game/stake" rel="noreferrer">
          <span className="flex flex-row items-center justify-center font-bold">
            <BsClockHistory className="mx-2 -ml-3" />
            Slow Release
          </span>
        </a>
      </button>
      <button className="my-1 w-4/5 bg-white rounded-lg shadow-lg text-xs p-2 hover:scale-110 transition-all hover:bg-[#ffae00]">
        <a href="https://nft.crypterium.game/" rel="noreferrer">
          <span className="flex flex-row items-center justify-center font-bold">
            <BsCashCoin className="mx-2 -ml-3" />
            Treasure Hunt
          </span>
        </a>
      </button>
      <button className="my-1 w-4/5 bg-white rounded-lg shadow-lg text-xs p-2 hover:scale-110 transition-all hover:bg-[#ffae00]">
        <a href="https://ccoin.crypterium.game/VCWallet" rel="noreferrer">
          <span className="flex flex-row items-center justify-center font-bold">
            <BiWalletAlt className="mx-2 -ml-3" />
            My Account Seed(VC)
          </span>
        </a>
      </button>
      <button className="my-1 w-4/5 bg-white rounded-lg shadow-lg text-xs p-2 hover:scale-110 transition-all hover:bg-[#ffae00]">
        <a href="https://ccoin.crypterium.game/adminconsole" rel="noreferrer">
          <span className="flex flex-row items-center justify-center font-bold text-red-700">
            <GrStakeholder className="mx-2 -ml-3" />
            Seed(VC) Admin
          </span>
        </a>
      </button>
      <button className="my-1 w-4/5 bg-white rounded-lg shadow-lg text-xs p-2 hover:scale-110 transition-all hover:bg-[#ffae00]">
        <a href="https://marketplace.crypterium.game/" rel="noreferrer">
          <span className="flex flex-row items-center justify-center font-bold">
            <BsShop className="mx-2 -ml-3" />
            Market Place
          </span>
        </a>
      </button>
      <button className="my-1 w-4/5 bg-white rounded-lg shadow-lg text-xs p-2 hover:scale-110 transition-all hover:bg-[#ffae00]">
        <a href="https://marketplace.crypterium.game/admin" rel="noreferrer">
          <span className="flex flex-row items-center justify-center font-bold text-red-700">
            <BsShop className="mx-2 -ml-3" />
            Market Place Admin
          </span>
        </a>
      </button>
      <button className="my-1 w-4/5 bg-white rounded-lg shadow-lg text-xs p-2 hover:scale-110 transition-all hover:bg-[#ffae00]">
        <a href="https://marketplace.crypterium.game/MyWallet" rel="noreferrer">
          <span className="flex flex-row items-center justify-center font-bold">
            <MdOutlineAccountBalanceWallet className="mx-2 -ml-3" />
            My NFT Wallet
          </span>
        </a>
      </button>
      <button className="my-1 w-4/5 bg-white rounded-lg shadow-lg text-xs p-2 hover:scale-110 transition-all hover:bg-[#ffae00]">
        <a href="https://ccoin.crypterium.game/Tokenomic" rel="noreferrer">
          <span className="flex flex-row items-center justify-center font-bold">
            <FaRegChartBar className="mx-2 -ml-3" />
            Tokenomic
          </span>
        </a>
      </button>

      <span className="text-xs text-blue-500 py-2">- Mainnet -</span>
      <button
        onClick={() => switchNetwork()}
        className="my-1 w-4/5 bg-white rounded-lg shadow-lg text-xs p-2 hover:scale-110 transition-all hover:bg-green-200 "
      >
        <span className="flex flex-row">
          <img
            className="px-1"
            width={25}
            src={process.env.PUBLIC_URL + "/img/bnb.svg"}
          />
          Add BSC Network
        </span>
      </button>
      <button
        onClick={() => addTokenFunction(CCOIN, 1)}
        className="my-1 w-4/5 bg-white rounded-lg shadow-lg text-xs p-2 hover:scale-105 transition-all hover:bg-green-200 duration-200"
      >
        <span className="flex flex-row">
          <img
            className="px-1"
            width={25}
            src={process.env.PUBLIC_URL + "/img/coins/ccoin.png"}
          />
          Add CCOIN
        </span>
      </button>
      <button
        onClick={() => addTokenFunction(CCSX, 2)}
        className="my-1 w-4/5 bg-white rounded-lg shadow-lg text-xs p-2 hover:scale-110 transition-all hover:bg-green-200 "
      >
        <span className="flex flex-row">
          <img
            className="px-1"
            width={25}
            src={process.env.PUBLIC_URL + "/img/coins/ccsx.png"}
          />
          Add CCSX
        </span>
      </button>
    </div>
  );
}

export default Links;
