import React, { useState, useEffect } from 'react'
import { ethers } from 'ethers'
import { useSelector } from 'react-redux'
import { AiOutlineCloudUpload, AiOutlineCopy } from 'react-icons/ai'
import { BiPlusMedical } from 'react-icons/bi'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import AnimatedNumber from 'animated-number-react'
import {
    useCSVReader,
    lightenDarkenColor,
    formatFileSize,
} from 'react-papaparse'
/* global BigInt */
const vPPOOL = process.env.REACT_APP_VPPOOL
const CCOIN = process.env.REACT_APP_CCOIN
const abi = require('../abi/abivppool')
const formatValue = (value) => `${separator(Number(value).toFixed(0))}`
let ierc20 = require('../abi/IERC20')

function separator(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const isMetaMaskInstalled = () => {
    const { ethereum } = window
    return Boolean(ethereum && ethereum.isMetaMask)
}

function dynamicSort(property) {
    var sortOrder = 1
    if (property[0] === '-') {
        sortOrder = -1
        property = property.substr(1)
    }
    return function (a, b) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        var result =
            a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
        return result * sortOrder
    }
}

function AddrList({ result, num, date, valid }) {
    return (
        <div className="w-full grid grid-cols-7 gap-3 grid-flow-row  border-b border-gray-700 py-1 text-gray-600 text-xm">
            <div className="col-span-1 px-3 text-center ">{num}</div>
            <div className="col-span-3 text-center w-100">{result[0]}</div>
            <div className="col-span-1 px-3 text-center">
                {separator(result[1])}
            </div>
            <div className="col-span-2 px-3 text-right">
                {new Date(date * 1000).toLocaleString('en-US', {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    hourCycle: 'h24',
                })}
            </div>
        </div>
    )
}

function AdminConsole() {
    const { CSVReader } = useCSVReader()
    const [csvResult, setCsvResult] = useState()
    const [open, setOpen] = useState(false)
    const [error, setError] = useState()
    const [info, setInfo] = useState()
    const [ledgers, setLedgers] = useState([])
    const [contractBalance, setContractBalance] = useState(0)
    const [adminAddress, setAdminAddress] = useState('')
    const [operatorAddress, setOperatorAddress] = useState('')
    const [ownerAddress, setOwnerAddress] = useState('')
    const [totalInStake, setTotalInStake] = useState()
    const [state, setState] = useState()
    const [account, setAccount] = useState()
    const wc = useSelector((state) => state.walletConnect.value)

    const handleClose = () => {
        setOpen(false)
    }
    const handleToggle = () => {
        setOpen(!open)
    }
    let addrsArr = []
    let amountsArr = []
    let invalidArr = []
    let timeStampsArr = []

    let provider
    let ethereum = window.ethereum
    let tempSigner

    if (!!wc.account) {
        console.log('WC!')
        ethereum = wc.provider
        provider = wc.web3Provider
        tempSigner = provider.getSigner()
    } else if (isMetaMaskInstalled()) {
        console.log('MT!')
        ethereum = window.ethereum
        provider = new ethers.providers.Web3Provider(ethereum)
        tempSigner = provider.getSigner()
    }

    useEffect(() => {
        setError('')
        return () => {}
    }, [csvResult])

    useEffect(() => {
        if (!!wc.account || isMetaMaskInstalled()) {
            getContractBalance()
            getAdminAddress()
            getOperatorAddress()
            getOwnerAddress()
            getTotalInStake()
            getCurrentAccount()
        }

        return () => {}
    }, [state])

    if (csvResult !== undefined) {
        console.log('csvResult:', csvResult)
        csvResult.map((result, i) => {
            const date = new Date(result[2])
            const unixTimestamp = Math.floor(date.getTime() / 1000)

            if (
                ethers.utils.isAddress(result[0]) &&
                result[1] !== null &&
                date instanceof Date &&
                !isNaN(date.valueOf())
            ) {
                addrsArr.push(result[0])
                amountsArr.push(ethers.utils.parseUnits(result[1], 18))
                timeStampsArr.push(unixTimestamp)
            } else {
                result.push(i + 1)
                result[1] == null ? (result[1] = 0) : invalidArr.push(result)
            }
        })
    }

    async function withdrawTokenCStake(token, amount) {
        const tokenContract = new ethers.Contract(vPPOOL, abi, tempSigner)
        const tx = await tokenContract.recoverToken(
            token,
            BigInt(amount.toString() + '000000000000000000')
            //BigInt(amount * 10 ** 18)
        )
    }

    async function getCurrentAccount() {
        const accounts = await ethereum.request({
            method: 'eth_accounts',
        })
        setAccount(accounts[0])
        return accounts[0]
    }

    function manualAddList() {
        const CSVToArray = (data, delimiter = ',', omitFirstRow = false) =>
            data
                .slice(omitFirstRow ? data.indexOf('\n') + 1 : 0)
                .split('\n')
                .map((v) => v.split(delimiter))

        console.log(
            'TextArea:',
            CSVToArray(document.getElementById('manualInput').value)
        )

        setCsvResult(CSVToArray(document.getElementById('manualInput').value))
        //document.getElementById('manualInput').value = ''
    }

    console.log('addrsArr:', addrsArr)
    console.log('amountsArr:', amountsArr)
    console.log('timeStampsArr:', timeStampsArr)
    console.log('invalidArr:', invalidArr)

    async function getContractBalance() {
        const smcTokenContract = new ethers.Contract(CCOIN, ierc20, tempSigner)
        await smcTokenContract.balanceOf(vPPOOL).then((x) => {
            setContractBalance((x / 10 ** 18).toFixed(2))
            console.log('balance', (x / 10 ** 18).toFixed(2))
        })
    }

    async function getAdminAddress() {
        const smcPool = new ethers.Contract(vPPOOL, abi, tempSigner)
        const getAdminAddress = await smcPool.callStatic.adminAddress()
        setAdminAddress(getAdminAddress.toString())
    }
    async function getOperatorAddress() {
        const smcPool = new ethers.Contract(vPPOOL, abi, tempSigner)
        const getOperatorAddress = await smcPool.callStatic.operatorAddress()
        setOperatorAddress(getOperatorAddress.toString())
    }
    async function getOwnerAddress() {
        const smcPool = new ethers.Contract(vPPOOL, abi, tempSigner)
        const getOwnerAddress = await smcPool.callStatic.owner()
        setOwnerAddress(getOwnerAddress.toString())
    }
    async function addMultiLedger() {
        const smcPool = new ethers.Contract(vPPOOL, abi, tempSigner)
        const addLedger = await smcPool.AddMultiLedgers(
            addrsArr,
            amountsArr,
            timeStampsArr
        )
        await addLedger
            .wait(2)
            .then((tx) => {
                console.log('tx:', tx)
                setInfo('Transaction Completed!')
                setCsvResult()
                handleClose()
                setState(() => state + 1)
                document.getElementById('manualInput').value = ''
            })
            .catch((err) => {
                handleClose()
                setError(err.data.message)
            })
    }

    async function getBlockTimeStamp() {
        const smcPool = new ethers.Contract(vPPOOL, abi, tempSigner)
        const blockTimeStamp = await smcPool.callStatic.getBlockTimeStamp()
        console.log('blockTimeStamp', blockTimeStamp.toString())
    }

    async function signMgs() {
        const message = 'The Smallest Parts Are Every Where!'
        await tempSigner.signMessage(message).then((signature) => {
            console.log('Signature:', signature)
            // Recover Address from signature
            const result = ethers.utils.verifyMessage(message, signature)
            console.log('Recovered Address:', result)
        })
    }

    async function getTotalInStake() {
        const cStake = new ethers.Contract(vPPOOL, abi, tempSigner)
        const Direct = await cStake.callStatic.totalInStake().then((x) => {
            console.log(
                'setTotalInStake:',
                parseInt(ethers.utils.formatEther(x))
            )
            setTotalInStake(parseInt(ethers.utils.formatEther(x)))
        })
    }

    async function checkClaimAbleTokens() {
        const smcPool = new ethers.Contract(vPPOOL, abi, tempSigner)
        const address = await tempSigner.getAddress()
        const numOfLedger = await smcPool.callStatic.ledgerLength(address)
        console.log('tempSigner.getAddress()', await tempSigner.getAddress())

        console.log('numOfLedger', numOfLedger.toString())
        const checkClaimAbleTokens =
            await smcPool.callStatic.checkClaimAbleTokens()
        console.log(
            'checkClaimAbleTokens',
            checkClaimAbleTokens.toString() / 10 ** 18
        )
        //let ledgers = []
        for (let i = 0; i < numOfLedger.toString(); i++) {
            setLedgers(
                ledgers.push(await smcPool.callStatic.ledgers(address, i))
            )
        }
        setLedgers(ledgers.sort(dynamicSort('unlockTime')))
        console.log('ledgers:', ledgers)
    }
    const date = new Date('Mar 5 2022 15:30')
    console.log('date:', date)
    console.log(date instanceof Date && !isNaN(date.valueOf()))
    const unixTimestamp = Math.floor(date.getTime() / 1000)
    console.log(unixTimestamp)

    let sumCoin = 0
    return (
        <div className="flex flex-col w-full justifyitem-center items-center mt-20">
            <div className="md:w-4/5 w-full p-5 flex flex-col justifyitem-center items-center drop-shadow-lg rounded-3xl bg-neutral-100 bg-opacity-70 backdrop-blur-xl">
                <span className="text-blue-400 font-bold text-sm my-1">
                    <br />
                </span>
                <div className=" blur-sm absolute -top-4 right-4 text-3xl md:text-4xl self-center -mb-3 font-bold text-yellow-700 break-all">
                    S E E D ( V C ) A d m i n
                </div>
                <div className=" absolute -top-5 right-5 text-3xl md:text-4xl self-center -mb-3 font-bold text-white break-all">
                    S E E D ( V C) A d m i n
                </div>
                <span className="text-blue-400 font-bold text-xl my-1">
                    {info}
                </span>
                <div className="flex flex-row text-sm md:text-xl self-center -mb-3 font-bold text-gray-700 break-all items-center">
                    {vPPOOL}
                    <AiOutlineCopy
                        className="text-lg transition-all hover:text-[#ffae00] cursor-pointer items-center"
                        onClick={() => navigator.clipboard.writeText(vPPOOL)}
                    />
                </div>
                <div className="w-full mt-5 grid grid-cols-4 gap-1 grid-flow-row text-sm text-center text-gray-500 font-bold content-center mb-5">
                    <div
                        className={`col-span-2 justify-self-center text-2xl  flex flex-col border rounded-md w-full p-2 border-gray-300 ${
                            contractBalance >= totalInStake
                                ? 'text-blue-500'
                                : 'text-rose-500'
                        }`}
                    >
                        <span className="text-xs font-medium -mt-1">
                            Contract Balance
                        </span>
                        <AnimatedNumber
                            value={contractBalance}
                            formatValue={formatValue}
                            duration="600"
                        />
                        <span className="text-lg font-medium">CCOIN</span>
                        <div className="flex flex-row w-full justify-center">
                            <input
                                id="cWithdraw"
                                type="text"
                                keyboardtype="decimal-pad"
                                pattern="[0-9]*"
                                step="1"
                                autoComplete="off"
                                className="w-1/4 rounded-lg px-1 text-sm text-black font-bold active:outline-none focus:outline-none text-right"
                            />
                            {!!account && !!ownerAddress ? (
                                ownerAddress.toLowerCase() ==
                                account.toLowerCase() ? (
                                    <button
                                        className=" m-1 w-16 bg-[#474747] rounded-lg p-1 text-xs text-white transition-all hover:bg-[#ffae00]"
                                        onClick={() => {
                                            if (
                                                parseInt(
                                                    document.getElementById(
                                                        'cWithdraw'
                                                    ).value
                                                ) > 0
                                            ) {
                                                withdrawTokenCStake(
                                                    CCOIN,
                                                    document.getElementById(
                                                        'cWithdraw'
                                                    ).value
                                                )
                                            } else {
                                                console.log(
                                                    'COIN STAKE : Invalid value'
                                                )
                                                document.getElementById(
                                                    'cWithdraw'
                                                ).value = 'Invalid value'
                                            }
                                        }}
                                    >
                                        Withdraw
                                    </button>
                                ) : (
                                    <button className=" m-1 w-16 bg-[#9c9c9c] rounded-lg p-1 text-xs text-white transition-all cursor-auto">
                                        Withdraw
                                    </button>
                                )
                            ) : (
                                <button className=" m-1 w-16 bg-[#9c9c9c] rounded-lg p-1 text-xs text-white transition-all cursor-auto">
                                    Withdraw
                                </button>
                            )}
                        </div>
                    </div>
                    <div className="col-span-2 justify-self-center text-2xl text-black flex flex-col border rounded-md w-full p-2 border-gray-300">
                        <span className="text-xs font-medium -mt-1">
                            Stake in Pool
                        </span>
                        <AnimatedNumber
                            value={totalInStake}
                            formatValue={formatValue}
                            duration="600"
                        />
                        <span className="text-lg font-medium">CCOIN</span>
                    </div>
                    <div className="col-span-2 justify-self-center text-mg text-grey-500 flex flex-col border rounded-md w-full p-2 border-gray-300">
                        <span className="text-xs font-medium -mt-1">
                            {adminAddress.slice(0, 5) +
                                '....' +
                                adminAddress.slice(-4)}
                        </span>
                        Admin Address
                    </div>
                    <div className="col-span-2 justify-self-center text-mg text-grey-500 flex flex-col border rounded-md w-full p-2 border-gray-300">
                        <span className="text-xs font-medium -mt-1">
                            {operatorAddress.slice(0, 5) +
                                '....' +
                                operatorAddress.slice(-4)}
                        </span>
                        Operator Address
                    </div>
                </div>

                <CSVReader
                    onUploadAccepted={(results: any) => {
                        setCsvResult(results.data)
                    }}
                >
                    {({
                        getRootProps,
                        acceptedFile,
                        ProgressBar,
                        getRemoveFileProps,
                    }: any) => (
                        <>
                            <div className="w-full flex flex-row justify-between items-center">
                                <button
                                    type="button"
                                    {...getRootProps()}
                                    className="flex flex-row bg-nature-100 rounded-lg p-5 text-xs border-dashed border-2 border-slate-400 text-slate-400"
                                >
                                    <AiOutlineCloudUpload fontSize={18} />{' '}
                                    Browse or Drag & Drop .csv here
                                </button>
                                <div className="text-sm">
                                    {acceptedFile && acceptedFile.name}
                                </div>
                                <button
                                    {...getRemoveFileProps()}
                                    className="border bg-[#474747] transition-all hover:bg-rose-500 text-white rounded-lg p-5 text-xs"
                                    onClick={() => setCsvResult()}
                                >
                                    Clear
                                </button>
                            </div>
                            <ProgressBar />
                        </>
                    )}
                </CSVReader>
                <div className="w-full flex flex-row">
                    <div className="m-2 p-2 rounded text-gray-500">
                        Example :
                    </div>
                    <div className="m-2 p-2 bg-black/10 rounded text-black">
                        <span className="text-blue-500">
                            0xa7608fD5B3176347e78241365fe1eC5369429AB7
                        </span>
                        ,<span className="text-rose-500">1500</span>,
                        <span className="text-yellow-700">
                            Jan 1 2023 20:00
                        </span>
                    </div>

                    <AiOutlineCopy
                        className="text-lg transition-all hover:text-[#ffae00] ml-0  self-center cursor-pointer"
                        onClick={() =>
                            navigator.clipboard.writeText(
                                '0xa7608fD5B3176347e78241365fe1eC5369429AB7,1500,Jan 1 2023 20:00'
                            )
                        }
                    />
                </div>
                <div className="w-full flex flex-row">
                    <textarea
                        id="manualInput"
                        className="rounded-lg p-2 w-11/12 my-2 text-md font-bold active:outline-none focus:outline-none text-left "
                    ></textarea>

                    <BiPlusMedical
                        className="w-1/12 text-[#474747] transition-all hover:text-[#ffae00] rounded-lg p-1 mx-1 h-1/2 m-2 text-2xl cursor-pointer"
                        onClick={() => manualAddList()}
                    />
                </div>
                <div className="w-full mt-5 grid grid-cols-7 gap-2 grid-flow-row border-b text-sm text-center text-gray-500 font-bold border-gray-600">
                    <div className="col-span-1 text-gray-600">#</div>
                    <div className="col-span-3 text-gray-600">Address</div>
                    <div className="col-span-1 text-gray-600">Amount </div>
                    <div className="col-span-2 text-gray-600">Unlock Date</div>
                </div>
                <div className="w-full flex flex-col rounded-md justify-between text-sm overflow-y-auto scroll-smooth">
                    {csvResult !== undefined ? (
                        csvResult.map((x, i) => {
                            const date = new Date(x[2])
                            if (
                                ethers.utils.isAddress(x[0]) &&
                                date instanceof Date &&
                                !isNaN(date.valueOf())
                            ) {
                                sumCoin += Number(x[1])

                                console.log('date:', date)
                                console.log(
                                    date instanceof Date &&
                                        !isNaN(date.valueOf())
                                )
                                const unixTimestamp = Math.floor(
                                    date.getTime() / 1000
                                )
                                console.log(unixTimestamp)
                                return (
                                    <AddrList
                                        key={i}
                                        result={x}
                                        date={unixTimestamp}
                                        num={i + 1}
                                        valid={true}
                                    />
                                )
                            }
                        })
                    ) : (
                        <div />
                    )}
                </div>

                {invalidArr.length !== 0 ? (
                    <div className="mt-2 w-full h-auto max-h-32 flex flex-col rounded-md justify-between text-sm overflow-y-auto scroll-smooth">
                        <div className="col-span-5 sticky top-0 text-rose-500 text-left bg-opacity-70 pl-3">
                            {invalidArr.length} Invalid Addresses or Date were
                            removed from list
                        </div>
                        {invalidArr.map((x, i) => {
                            const date = new Date(x[2])
                            const unixTimestamp = Math.floor(
                                date.getTime() / 1000
                            )

                            return (
                                <AddrList
                                    key={i}
                                    result={x}
                                    num={x[3]}
                                    date={unixTimestamp}
                                    valid={false}
                                />
                            )
                        })}
                    </div>
                ) : (
                    <br />
                )}
                {!!account && !!adminAddress ? (
                    adminAddress.toLowerCase() == account.toLowerCase() ||
                    operatorAddress.toLowerCase() == account.toLowerCase() ? (
                        <button
                            type="button"
                            onClick={() => {
                                setInfo(
                                    'Waiting for your confirmation on Metamask'
                                )
                                handleToggle()
                                setError('')
                                addMultiLedger()
                            }}
                            className="text-white w-full mt-2 p-2 border rounded-md bg-[#474747] transition-all hover:bg-[#ffae00] cursor-pointer"
                        >
                            Add - Total {separator(sumCoin)} CCOIN
                        </button>
                    ) : (
                        <button className="text-white w-full mt-2 p-2 border rounded-md bg-[#9c9c9c] transition-all cursor-auto">
                            Add - Total {separator(sumCoin)} CCOIN
                        </button>
                    )
                ) : (
                    <button className="text-white w-full mt-2 p-2 border rounded-md bg-[#9c9c9c] transition-all cursor-auto">
                        Add - Total {separator(sumCoin)} CCOIN
                    </button>
                )}

                <span className="text-red-600 font-bold text-sm my-1">
                    {error}
                </span>
            </div>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
                className="flex flex-col"
            >
                <div className="bg-white m-4 text-center text-gray-900 text-lg p-3 rounded-lg md:w-2/5 w-full display-linebreak">
                    <CircularProgress color="inherit" />
                    <br />
                    {info}
                </div>
                <br />
            </Backdrop>
        </div>
    )
}

export default AdminConsole
