const ABIMARKET = [
    { inputs: [], stateMutability: 'nonpayable', type: 'constructor' },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: '_newAdmin',
                type: 'address',
            },
        ],
        name: 'ClaimAdmin',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: '_current',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'address',
                name: '_newAdmin',
                type: 'address',
            },
        ],
        name: 'SetAdmin',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: '_newOperator',
                type: 'address',
            },
        ],
        name: 'SetOperator',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'token',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
            },
        ],
        name: 'TokenRecovery',
        type: 'event',
    },
    {
        inputs: [
            { internalType: 'uint256', name: '_nftId', type: 'uint256' },
            { internalType: 'uint256', name: '_price', type: 'uint256' },
            { internalType: 'address', name: '_nftAddr', type: 'address' },
            { internalType: 'address', name: '_seller', type: 'address' },
            { internalType: 'address', name: '_buyer', type: 'address' },
        ],
        name: 'AddList',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'BUSD',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'BUSD_Addr',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'uint256', name: '_ListId', type: 'uint256' }],
        name: 'Buy',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'CCOIN',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'CCOIN_Addr',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'uint256', name: '_ListId', type: 'uint256' }],
        name: 'CancelList',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint256', name: '_ListId', type: 'uint256' },
            { internalType: 'uint256', name: '_price', type: 'uint256' },
            { internalType: 'address', name: '_sellToAddr', type: 'address' },
        ],
        name: 'EditlList',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'uint256', name: '_fee', type: 'uint256' }],
        name: 'FeeCal',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'IID_IERC1155',
        outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'IID_IERC165',
        outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'IID_IERC721',
        outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'IID_ITEST',
        outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'admin',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'claimAdmin',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'claimAdminAddress',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'getAllList',
        outputs: [
            {
                components: [
                    { internalType: 'uint256', name: 'nftId', type: 'uint256' },
                    { internalType: 'uint256', name: 'price', type: 'uint256' },
                    {
                        internalType: 'address',
                        name: 'nftAddr',
                        type: 'address',
                    },
                    {
                        internalType: 'address',
                        name: 'seller',
                        type: 'address',
                    },
                    { internalType: 'address', name: 'buyer', type: 'address' },
                    { internalType: 'bool', name: 'isSold', type: 'bool' },
                    {
                        internalType: 'uint256',
                        name: 'dateList',
                        type: 'uint256',
                    },
                    {
                        internalType: 'uint256',
                        name: 'dateLast',
                        type: 'uint256',
                    },
                ],
                internalType: 'struct CFMarketPlaceV1.List[]',
                name: '',
                type: 'tuple[]',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: '_address', type: 'address' },
        ],
        name: 'getListFromSellerAddr',
        outputs: [
            {
                components: [
                    { internalType: 'uint256', name: 'nftId', type: 'uint256' },
                    { internalType: 'uint256', name: 'price', type: 'uint256' },
                    {
                        internalType: 'address',
                        name: 'nftAddr',
                        type: 'address',
                    },
                    {
                        internalType: 'address',
                        name: 'seller',
                        type: 'address',
                    },
                    { internalType: 'address', name: 'buyer', type: 'address' },
                    { internalType: 'bool', name: 'isSold', type: 'bool' },
                    {
                        internalType: 'uint256',
                        name: 'dateList',
                        type: 'uint256',
                    },
                    {
                        internalType: 'uint256',
                        name: 'dateLast',
                        type: 'uint256',
                    },
                ],
                internalType: 'struct CFMarketPlaceV1.List[]',
                name: '',
                type: 'tuple[]',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: '_address', type: 'address' },
        ],
        name: 'getNoListFromSellerAddr',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'nftAddress', type: 'address' },
        ],
        name: 'isERC1155',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'nftAddress', type: 'address' },
        ],
        name: 'isERC721',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'listingFee',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        name: 'nftLists',
        outputs: [
            { internalType: 'uint256', name: 'nftId', type: 'uint256' },
            { internalType: 'uint256', name: 'price', type: 'uint256' },
            { internalType: 'address', name: 'nftAddr', type: 'address' },
            { internalType: 'address', name: 'seller', type: 'address' },
            { internalType: 'address', name: 'buyer', type: 'address' },
            { internalType: 'bool', name: 'isSold', type: 'bool' },
            { internalType: 'uint256', name: 'dateList', type: 'uint256' },
            { internalType: 'uint256', name: 'dateLast', type: 'uint256' },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: '', type: 'address' },
            { internalType: 'address', name: '', type: 'address' },
            { internalType: 'uint256[]', name: '', type: 'uint256[]' },
            { internalType: 'uint256[]', name: '', type: 'uint256[]' },
            { internalType: 'bytes', name: '', type: 'bytes' },
        ],
        name: 'onERC1155BatchReceived',
        outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: '', type: 'address' },
            { internalType: 'address', name: '', type: 'address' },
            { internalType: 'uint256', name: '', type: 'uint256' },
            { internalType: 'uint256', name: '', type: 'uint256' },
            { internalType: 'bytes', name: '', type: 'bytes' },
        ],
        name: 'onERC1155Received',
        outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: '', type: 'address' },
            { internalType: 'address', name: '', type: 'address' },
            { internalType: 'uint256', name: '', type: 'uint256' },
            { internalType: 'bytes', name: '', type: 'bytes' },
        ],
        name: 'onERC721Received',
        outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: '_token', type: 'address' },
            { internalType: 'uint256', name: '_amount', type: 'uint256' },
        ],
        name: 'recoverToken',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'sellingFeePercent',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: '_adminAddress', type: 'address' },
        ],
        name: 'setAdmin',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: '_busd', type: 'address' }],
        name: 'setBUSD',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: '_ccoin', type: 'address' }],
        name: 'setCCOIN',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'uint256', name: '_ccoin', type: 'uint256' }],
        name: 'setListingFee',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint256', name: '_percent', type: 'uint256' },
        ],
        name: 'setSellingFeePercent',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'uint256', name: '_ccoin', type: 'uint256' }],
        name: 'setTransferFee',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' },
        ],
        name: 'supportsInterface',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'totalList',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'transferFee',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: '_nftAddr', type: 'address' },
            { internalType: 'address', name: '_to', type: 'address' },
            { internalType: 'uint256', name: '_id', type: 'uint256' },
        ],
        name: 'transferNFT',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
]

module.exports = ABIMARKET
