import React, { createContext, useContext, useState } from 'react'

// Create a context for pagination
const PaginationContext = createContext()

// Create a custom hook to access the pagination context
export function usePagination() {
    return useContext(PaginationContext)
}

// PaginationProvider component to manage the pagination state
export function PaginationProvider({ children }) {
    const [currentPage, setCurrentPage] = useState(1)

    const value = {
        currentPage,
        setCurrentPage,
    }

    return (
        <PaginationContext.Provider value={value}>
            {children}
        </PaginationContext.Provider>
    )
}
