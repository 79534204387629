import React, { useState, useEffect } from 'react'
import { ethers } from 'ethers'
import { useSelector } from 'react-redux'
import { AiOutlineCloudUpload, AiOutlineMinusCircle } from 'react-icons/ai'
import { MdAppRegistration } from 'react-icons/md'
import { BsCashCoin } from 'react-icons/bs'
import { CgSandClock } from 'react-icons/cg'
import Select from 'react-select'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import AnimatedNumber from 'animated-number-react'
import { useCSVReader, lightenDarkenColor } from 'react-papaparse'
/* global BigInt */

const CCOIN = process.env.REACT_APP_CCOIN
const CCSX = process.env.REACT_APP_CCSX
const BUSD = process.env.REACT_APP_BUSD
const AIRDROP = process.env.REACT_APP_AIRDROP

/*
const CCOIN = '0xc209831f7349D4E200d420326B3401899Ab9Ae68' // BSC Mainnet
const BUSD = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56' // BSC Mainnet
const AIRDROP = '0x57644410ceec629c770d779567375cec91dc02ba' // BSC Mainnet - Update 31-Aug-22

const CCOIN = '0xbFB0be698655eCdEb6dDe80831dfb3C6553c4C6D' // BSC Testnnet
const BUSD = '0x32ed57673EC8a0c6e5c4cd0c53e2d0a5be1497f9' // BSC Testnet
const AIRDROP = '0xB7e81d19D24B4EEB05360E8DCdCdc5C16C054779' // BSC Testnet
*/
const abi = require('../abi/airdrop')
const formatValue = (value) => `${separator(Number(value).toFixed(0))}`
let ierc20 = require('../abi/IERC20')

function separator(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const isMetaMaskInstalled = () => {
    const { ethereum } = window
    return Boolean(ethereum && ethereum.isMetaMask)
}

function dynamicSort(property) {
    var sortOrder = 1
    if (property[0] === '-') {
        sortOrder = -1
        property = property.substr(1)
    }
    return function (a, b) {
        var result =
            a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
        return result * sortOrder
    }
}

function AddrList({ result, num, valid }) {
    return (
        <div className="w-full grid grid-cols-5 gap-3 grid-flow-row  border-b border-gray-700 py-1 text-gray-600 text-xm">
            <div className="col-span-1 px-3 text-center ">{num}</div>
            <div className="col-span-3 text-center w-100">{result[0]}</div>
            <div className="col-span-1 px-3 text-center ">
                {separator(result[1])}
            </div>
        </div>
    )
}

const options = [
    {
        value: 'ccoin',
        label: (
            <div className="flex flex-row text-sm font-bold justify-between items-center">
                <img src={process.env.PUBLIC_URL + '/img/coins/ccoin.png'} />
                CCOIN
            </div>
        ),
    },
    {
        value: 'busd',
        label: (
            <div className="flex flex-row text-sm font-bold justify-between items-center">
                <img src={process.env.PUBLIC_URL + '/img/coins/busd.png'} />
                BUSD
            </div>
        ),
    },
    {
        value: 'ccsx',
        label: (
            <div className="flex flex-row text-sm font-bold justify-between items-center">
                <img src={process.env.PUBLIC_URL + '/img/coins/ccsx.png'} />
                CCSX
            </div>
        ),
    },
]

function Airdrop() {
    const { CSVReader } = useCSVReader()
    const [csvResult, setCsvResult] = useState()
    const [open, setOpen] = useState(false)
    const [error, setError] = useState()
    const [info, setInfo] = useState()
    const [totalCAmount, setTotalCAmount] = useState()
    const [totalAddress, setTotalAddress] = useState('')
    const [cBalance, setCBalance] = useState('')
    const [state, setState] = useState(0)
    const [account, setAccount] = useState()
    const [coin, setCoin] = useState(CCOIN)
    const wc = useSelector((state) => state.walletConnect.value)

    const handleClose = () => {
        setOpen(false)
    }
    const handleToggle = () => {
        setOpen(!open)
    }
    let addrsArr = []
    let amountsArr = []
    let invalidArr = []

    let provider
    let ethereum = window.ethereum
    let tempSigner

    if (!!wc.account) {
        ethereum = wc.provider
        provider = wc.web3Provider
        tempSigner = provider.getSigner()
        ethereum.on('accountsChanged', (accounts) => setState(state + 1))
    } else if (isMetaMaskInstalled()) {
        ethereum = window.ethereum
        provider = new ethers.providers.Web3Provider(ethereum)
        tempSigner = provider.getSigner()
        ethereum.on('accountsChanged', (accounts) => setState(state + 1))
    }

    useEffect(() => {
        setTotalCAmount(sumCoin)
        setTotalAddress(addrsArr.length)
        setError('')
        return () => {}
    }, [csvResult])

    useEffect(() => {
        console.clear()
        if (!!wc.account || isMetaMaskInstalled()) {
            getCurrentAccount()
            getCBalance()

            console.log('CCOIN:', cBalance)
        }
        setError('')
        return () => {}
    }, [state, wc.account, account])

    if (csvResult !== undefined) {
        csvResult.map((result, i) => {
            if (ethers.utils.isAddress(result[0]) && result[1] !== null) {
                addrsArr.push(result[0])
                amountsArr.push(ethers.utils.parseUnits(result[1], 18))
            } else {
                result.push(i + 1)
                result[1] == null ? (result[1] = 0) : invalidArr.push(result)
            }
        })
    }

    async function getCurrentAccount() {
        const accounts = await ethereum.request({
            method: 'eth_accounts',
        })
        setAccount(accounts[0])
        return accounts[0]
    }
    const ccoinTokenContract = new ethers.Contract(coin, ierc20, tempSigner)

    getCBalance()

    async function getCBalance() {
        console.log('coin:', coin)
        await ccoinTokenContract.balanceOf(account).then((x) => {
            setCBalance((x / 10 ** 18).toFixed(2))
            console.log('getCBalance():', (x / 10 ** 18).toFixed(2))
        })
    }

    async function bulkSend() {
        setInfo('Please Confirm your transaction \n on Metamask')
        try {
            const airDropContract = new ethers.Contract(
                AIRDROP,
                abi,
                tempSigner
            )
            addrsArr.unshift(account)
            amountsArr.unshift(BigInt((sumCoin + 1) * 10 ** 18).toString())

            const bulkTransfer = await airDropContract.bulksendToken(
                coin,
                addrsArr,
                amountsArr
            )
            setInfo('Transfering your coin')
            const complete = await bulkTransfer.wait(1).then((x) => {
                setInfo('Transfer Complete')
                setTimeout(function () {
                    getCBalance()
                    handleClose()
                }, 5000)
                setCsvResult()
            })
        } catch (error) {
            console.log('error:', error)
            handleClose()
        }
    }

    async function addMultiLedger() {
        const checkAllowance = await ccoinTokenContract.callStatic.allowance(
            account,
            AIRDROP
        )
        const allowance = Number(checkAllowance.toString()) / 10 ** 18
        if (allowance >= totalCAmount) {
            setInfo('Allowance amount is ready for Airdrop')
            bulkSend()
        } else {
            setInfo('Waiting for your approve allowance \n on Metamask')
            try {
                /* global BigInt */
                const approve = await ccoinTokenContract.approve(
                    AIRDROP,
                    BigInt(cBalance * 10 ** 18)
                )
                setInfo('Waiting for block confirmation')
                const receipt = await approve.wait(3).then((tx) => {
                    setInfo('Please Confirm your transaction \n on Metamask')
                    bulkSend()
                })
            } catch (error) {
                console.log('error:', error)
                handleClose()
            }
        }
    }
    function onSelect(optionSelected) {
        console.log('Coin Select :', optionSelected.value)
        if (optionSelected.value == 'ccoin') {
            setError('')
            setCoin(CCOIN)
            console.log('ccoin : set coin to:', CCOIN)
        } else if (optionSelected.value == 'busd') {
            setError('')
            setCoin(BUSD)
            console.log('busd : set coin to:', BUSD)
        } else if (optionSelected.value == 'ccsx') {
            setError('')
            setCoin(CCSX)
            console.log('ccsx : set coin to:', CCSX)
        }

        getCBalance()
    }
    let sumCoin = 0
    return (
        <div className="flex flex-col w-full justifyitem-center items-center mt-20 h-screen">
            <div className="md:w-3/5 w-full p-5 flex flex-col justifyitem-center items-center drop-shadow-lg rounded-xl text-black bg-neutral-100 bg-opacity-70 backdrop-blur-xl">
                <span className="text-blue-400 font-bold text-sm my-1">
                    <br />
                </span>
                <div className=" blur-sm absolute -top-4 right-4 text-3xl md:text-4xl self-center -mb-3 font-bold text-yellow-700 break-all">
                    A i r d r o p
                </div>
                <div className=" absolute -top-5 right-5 text-3xl md:text-4xl self-center -mb-3 font-bold text-white break-all">
                    A i r d r o p
                </div>

                <div className=" w-full flex justify-between">
                    <Select
                        defaultValue={options[0]}
                        options={options}
                        className="w-1/2 md:w-1/4 m-2"
                        onChange={onSelect}
                    />
                </div>

                <div className="w-full mt-5 grid grid-cols-5 gap-1 grid-flow-row text-sm text-center text-gray-600 font-bold content-center mb-5">
                    <div className="col-span-1 justify-self-center text-mg text-grey-500 flex flex-col border rounded-md w-full p-2 border-[#4b4314]">
                        <span className="text-xs font-medium -mt-1">
                            Total Recipient
                        </span>
                        <AnimatedNumber
                            className="text-2xl font-medium"
                            value={totalAddress}
                            formatValue={formatValue}
                            duration="600"
                        />
                        <span>Address</span>
                    </div>
                    <div className="col-span-2 justify-self-center text-mg text-grey-500 flex flex-col border rounded-md w-full p-2 border-[#4b4314]">
                        <span className="text-xs font-medium -mt-1">
                            Total Amount
                        </span>
                        <AnimatedNumber
                            className="text-2xl font-medium"
                            value={totalCAmount}
                            formatValue={formatValue}
                            duration="600"
                        />
                        {coin == BUSD ? (
                            <span className="text-yellow-700 size-xs flex flex-row justify-center">
                                <img
                                    className="scale-75"
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/img/coins/busd.png'
                                    }
                                />
                                BUSD
                            </span>
                        ) : coin == CCOIN ? (
                            <span className="text-gray-700 size-xs flex flex-row justify-center">
                                <img
                                    className="scale-75"
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/img/coins/ccoin.png'
                                    }
                                />
                                CCOIN
                            </span>
                        ) : (
                            <span className="text-gray-400 size-xs flex flex-row justify-center">
                                <img
                                    className="scale-75"
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/img/coins/ccsx.png'
                                    }
                                />
                                CCSX
                            </span>
                        )}
                    </div>
                    <div className="col-span-2 justify-self-center text-mg text-grey-500 flex flex-col border rounded-md w-full p-2 border-[#4b4314]">
                        <span className="text-xs font-medium -mt-1">
                            Your Balance
                        </span>
                        <AnimatedNumber
                            className="text-2xl font-medium"
                            value={cBalance}
                            formatValue={formatValue}
                            duration="600"
                        />

                        {coin == BUSD ? (
                            <span className="text-yellow-700 size-xs flex flex-row justify-center">
                                <img
                                    className="scale-75"
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/img/coins/busd.png'
                                    }
                                />
                                BUSD
                            </span>
                        ) : coin == CCOIN ? (
                            <span className="text-gray-700 size-xs flex flex-row justify-center">
                                <img
                                    className="scale-75"
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/img/coins/ccoin.png'
                                    }
                                />
                                CCOIN
                            </span>
                        ) : (
                            <span className="text-gray-400 size-xs flex flex-row justify-center">
                                <img
                                    className="scale-75"
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/img/coins/ccsx.png'
                                    }
                                />
                                CCSX
                            </span>
                        )}
                    </div>
                </div>

                <CSVReader
                    onUploadAccepted={(results: any) => {
                        setCsvResult(results.data)
                    }}
                >
                    {({
                        getRootProps,
                        acceptedFile,
                        ProgressBar,
                        getRemoveFileProps,
                    }: any) => (
                        <>
                            <div className="w-full flex flex-row justify-between items-center">
                                <button
                                    type="button"
                                    {...getRootProps()}
                                    className="flex flex-row bg-[#36300f] rounded-lg p-5 text-xs border-dashed border-2 border-[#4b4314] text-gray-300"
                                >
                                    <AiOutlineCloudUpload fontSize={18} />{' '}
                                    Browse or Drag & Drop .csv here
                                </button>
                                <div className="text-md text-white">
                                    {/*acceptedFile && acceptedFile.name*/}
                                </div>
                                <button
                                    {...getRemoveFileProps()}
                                    className=" bg-[#474747] rounded-lg p-4 text-xs text-white hover:bg-[#e40202] transition-all"
                                    onClick={() => setCsvResult()}
                                >
                                    Clear
                                </button>
                            </div>
                            <ProgressBar />
                        </>
                    )}
                </CSVReader>

                <br />
                <button
                    type="button"
                    onClick={() => {
                        if (csvResult == undefined) {
                            setError('No *.csv file Found!')
                        } else if (cBalance < totalCAmount) {
                            setError('Insufficient COIN in your wallet!')
                        } else {
                            setInfo('Checking your allowance amount')
                            handleToggle()
                            setError('')
                            addMultiLedger()
                        }
                    }}
                    className="text-white w-full mt-2 border-[0px] p-2 bg-[#36300f] rounded-md cursor-pointer hover:bg-[#ffae00] transition-all"
                >
                    Airdrop
                </button>

                <span className="text-red-600 font-bold text-sm my-1">
                    {error}
                </span>

                <div className="w-full mt-5 grid grid-cols-5 gap-2 grid-flow-row border-b text-sm text-center text-gray-500 font-bold border-gray-600">
                    <div className="col-span-1 text-gray-600">#</div>{' '}
                    <div className="col-span-3 text-gray-600">Address</div>{' '}
                    <div className="col-span-1 text-gray-600">Amount </div>{' '}
                </div>

                <div className="w-full h-64 flex flex-col rounded-md justify-between text-sm overflow-y-auto scroll-smooth">
                    {csvResult !== undefined ? (
                        csvResult.map((x, i) => {
                            if (ethers.utils.isAddress(x[0])) {
                                sumCoin += Number(x[1])
                                return (
                                    <AddrList
                                        key={i}
                                        result={x}
                                        num={i + 1}
                                        valid={true}
                                    />
                                )
                            }
                        })
                    ) : (
                        <div />
                    )}
                </div>

                {invalidArr.length !== 0 ? (
                    <div className="mt-2 w-full h-auto max-h-32 flex flex-col rounded-md justify-between text-sm overflow-y-auto scroll-smooth">
                        <div className="col-span-5 sticky top-0 text-rose-500 text-left bg-neutral-800 bg-opacity-70 pl-3">
                            {invalidArr.length} Invalid Addresses were removed
                            from list
                        </div>
                        {invalidArr.map((x, i) => {
                            return (
                                <AddrList
                                    key={i}
                                    result={x}
                                    num={x[2]}
                                    valid={false}
                                />
                            )
                        })}
                    </div>
                ) : (
                    <br />
                )}
            </div>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
                className="flex flex-col"
            >
                <div className="bg-white grid grid-cols-7 gap-3 grid-flow-row m-4 items-center text-center text-gray-900 text-xl font-bold p-3 rounded-lg md:w-2/5 w-full display-linebreak">
                    <div className="col-span-7 blink my-5">{info}</div>
                    <div className="col-span-7">
                        <CircularProgress color="inherit" />
                    </div>
                    <div className="col-span-1"></div>
                    <div className="col-span-1">
                        <MdAppRegistration className="w-full" />
                    </div>
                    <div className="col-span-1 bg-gray-400 h-px"></div>
                    <div className="col-span-1">
                        <CgSandClock className="w-full" size="24px" />
                    </div>
                    <div className="col-span-1 bg-gray-400 h-px"></div>
                    <div className="col-span-1">
                        <BsCashCoin className="w-full" />
                    </div>

                    <div className="col-span-1"></div>

                    <div className="col-span-1"></div>

                    <div className="col-span-1 text-gray-500 text-sm font-normal">
                        Allowance Approve
                    </div>
                    <div className="col-span-1"></div>
                    <div className="col-span-1 text-gray-500 text-sm font-normal">
                        Block Confirmation
                    </div>
                    <div className="col-span-1"></div>
                    <div className="col-span-1 text-gray-500 text-sm font-normal">
                        Transaction Approve
                    </div>
                    <div className="col-span-1"></div>
                    <br />
                </div>
                <br />
            </Backdrop>
        </div>
    )
}

export default Airdrop
