import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import { useSelector } from "react-redux";
import {
  AiOutlineCloudUpload,
  AiOutlineMinusCircle,
  AiOutlineCheckCircle,
  AiOutlineCopy,
} from "react-icons/ai";
import { CgSandClock } from "react-icons/cg";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import AnimatedNumber from "animated-number-react";
import {
  useCSVReader,
  lightenDarkenColor,
  formatFileSize,
} from "react-papaparse";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Links from "./Links";
import axios from "axios";
import Select from "react-select";

const pool = "0xb6e973aC3757FB4a28e649fA807d41dc3Fc327c1"; //Mainnet
const usdt = "0x55d398326f99059fF775485246999027B3197955"; // Mainnet

const relayer = "0x0947b825c5b850c4dcd65f82fb5d1ef51b87c332";
const relayer3 = "0x4c271a23b1C32EC8fd3c23d6f60D9467f19b4Fe6";
const relayer4 = "0x57A358d6129c5ce8Ac62C6C787f86C18BdFA269B";
const relayer5 = "0x19Be01f1fb476A3E7994926962740d797fc6691A";
const tronrelayer = "TRmk9sMXxarQPUo3do3KY6nfiUK38AkjNE";

const CSTAKE = process.env.REACT_APP_CSTAKE;
const CCOIN = process.env.REACT_APP_CCOIN;
const CCSX = process.env.REACT_APP_CCSX;
const busd = process.env.REACT_APP_BUSD;
const randomBoxNFT = process.env.REACT_APP_MYSTRY_BOX_CONTRACT;
const NFT1155STAKE = process.env.REACT_APP_NFT1155STAKE_CONTRACT;
const NFT721STAKE = process.env.REACT_APP_EGGHATCH_CONTRACT;
const AUTOPAYMENT = process.env.REACT_APP_PAYMENT;
//const pool = '0xb6e973aC3757FB4a28e649fA807d41dc3Fc327c1' //Mainnet
//const usdt = '0xF01B601cCa02618f89727F8DDa44f3BA68FA2e47' // Testnet
//const API_URL = 'https://api-testnet.bscscan.com' // Testnet
const API_URL = "https://api.bscscan.com"; // Mainnet
const APIKey = "7WM7AWTBBGCZNESCGTUJH5DRN8SJ1K1ZFH";
const USDTtokenBalanceAPI = `${API_URL}/api?module=account&action=tokenbalance&contractaddress=${usdt}&address=${pool}&tag=latest&apikey=${APIKey}`;
const BUSDtokenBalanceAPI = `${API_URL}/api?module=account&action=tokenbalance&contractaddress=${busd}&address=${pool}&tag=latest&apikey=${APIKey}`;
const BUSDtokenBalanceAPIRandom = `${API_URL}/api?module=account&action=tokenbalance&contractaddress=${busd}&address=${randomBoxNFT}&tag=latest&apikey=${APIKey}`;
const CCOINtokenBalanceAPI = `${API_URL}/api?module=account&action=tokenbalance&contractaddress=${CCOIN}&address=${pool}&tag=latest&apikey=${APIKey}`;
//const RelayerAPI = `${API_URL}/api?module=account&action=balance&address=0x0947b825c5b850c4dcd65f82fb5d1ef51b87c332&apikey=${APIKey}`
//const Relayer3API = `${API_URL}/api?module=account&action=balance&address=0x4c271a23b1C32EC8fd3c23d6f60D9467f19b4Fe6&apikey=${APIKey}`
//const Relayer4API = `${API_URL}/api?module=account&action=balance&address=${relayer4}&apikey=${APIKey}`
//const Relayer5API = `${API_URL}/api?module=account&action=balance&address=${relayer5}&apikey=${APIKey}`
const etherAPI = `https://api.etherscan.io/api?module=account&action=balance&address=${relayer}&tag=latest&apikey=WZAYVUH8THPDWFR1KDABGTA1IR6G618BH9`;
const tronAPI = `https://apilist.tronscanapi.com/api/accountv2?address=TRmk9sMXxarQPUo3do3KY6nfiUK38AkjNE`;

/* global BigInt */
const rcAbi = require("../abi/rcAbi");
const abi = require("../abi/Pool");
const erc1155 = require("../abi/erc1155");
const erc721 = require("../abi/erc721");
const cstakeabi = require("../abi/cstakeabi");
const autopaymentabi = require("../abi/ABIAUTOPAYMENT");
const formatValue = (value) => `${separator(Number(value).toFixed(2))}`;
const formatValueBNB = (value) => `${separator(Number(value).toFixed(3))}`;
let ierc20 = require("../abi/IERC20");

let packagesArr = [];
let avaliableArr = [];
let ccoinArr = [];
let expireArr = [];
let isActiveArr = [];

function RelayerAPI(_relayer) {
  return `${API_URL}/api?module=account&action=balance&address=${_relayer}&apikey=${APIKey}`;
}

function separator(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const isMetaMaskInstalled = () => {
  const { ethereum } = window;
  return Boolean(ethereum && ethereum.isMetaMask);
};

function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}

const erc1155options = [
  {
    value: "Angel Boot",
    label: (
      <div className="flex flex-row text-sm font-bold justify-between items-center">
        <img src="https://nft.crypterium.game/img/pix/1.png" />
        1.Angel Boot
      </div>
    ),
  },
  {
    value: "Angel Guard",
    label: (
      <div className="flex flex-row text-sm font-bold justify-between items-center">
        <img src="https://nft.crypterium.game/img/pix/2.png" />
        2.Angel Guard
      </div>
    ),
  },
  {
    value: "Angel Amulet",
    label: (
      <div className="flex flex-row text-sm font-bold justify-between items-center">
        <img src="https://nft.crypterium.game/img/pix/3.png" />
        3.Angel Amulet
      </div>
    ),
  },
  {
    value: "Angel Halo",
    label: (
      <div className="flex flex-row text-sm font-bold justify-between items-center">
        <img src="https://nft.crypterium.game/img/pix/4.png" />
        4.Angel Halo
      </div>
    ),
  },
  {
    value: "Athena Shield",
    label: (
      <div className="flex flex-row text-sm font-bold justify-between items-center">
        <img src="https://nft.crypterium.game/img/pix/5.png" />
        5.Athena Shield
      </div>
    ),
  },
  {
    value: "Athena Boot",
    label: (
      <div className="flex flex-row text-sm font-bold justify-between items-center">
        <img src="https://nft.crypterium.game/img/pix/6.png" />
        6.Athena Boot
      </div>
    ),
  },
  {
    value: "Ares Ring",
    label: (
      <div className="flex flex-row text-sm font-bold justify-between items-center">
        <img src="https://nft.crypterium.game/img/pix/7.png" />
        7.Ares Ring
      </div>
    ),
  },
  {
    value: "Ares Amulet",
    label: (
      <div className="flex flex-row text-sm font-bold justify-between items-center">
        <img src="https://nft.crypterium.game/img/pix/8.png" />
        8.Ares Amulet
      </div>
    ),
  },
  {
    value: "Apollo Armor",
    label: (
      <div className="flex flex-row text-sm font-bold justify-between items-center">
        <img src="https://nft.crypterium.game/img/pix/9.png" />
        9.Apollo Armor
      </div>
    ),
  },
  {
    value: "Apollo Helmet",
    label: (
      <div className="flex flex-row text-sm font-bold justify-between items-center">
        <img src="https://nft.crypterium.game/img/pix/10.png" />
        10.Apollo Helmet
      </div>
    ),
  },
];

function AddrList({ packNum, avaliable, ccoinOut, expireDate, isActive }) {
  const eXpire = new Date(expireDate * 1000).toLocaleDateString("en-US");
  return (
    <div className="w-full mt-1 grid grid-cols-8 gap-2 grid-flow-row text-sm text-center text-gray-900 font-bold border-gray-600">
      <div className="col-span-1 text-gray-600">{packNum}</div>
      <div className="col-span-2 text-gray-600">
        {separator(avaliable)}
      </div>{" "}
      <div className="col-span-2 text-gray-600">{separator(ccoinOut)} </div>{" "}
      <div className="col-span-2 text-gray-600">{eXpire}</div>
      <div className="col-span-1 text-gray-600">
        {isActive ? (
          <div className="text-green-500">Active</div>
        ) : (
          <div className="text-rose-500">Inactive</div>
        )}
      </div>
    </div>
  );
}

function Package({ num, ccoin, avaliable, expire, active }) {
  const eXpire = new Date(expire * 1000).toLocaleDateString("en-GB");

  return (
    <div className="col-span-2 content-center text-mg text-grey-500 flex flex-col border rounded-md w-full p-2 border-[#4b4314] drop-shadow-md bg-neutral-100/0">
      <span className="text-xl font-medium mt-1 text-gray-500">
        Package {num}
      </span>

      <div className="w-full mt-5 grid grid-cols-2 gap-1 grid-flow-row text-sm text-center text-gray-500 font-bold content-center mb-5 ">
        <div className="col-span-2 flex flex-col row-span-1 content-center text-grey-500 border rounded-md w-full p-2 border-[#4b4314] ">
          <AnimatedNumber
            className="text-2xl font-medium mt-0"
            value={ccoin / 10 ** 18}
            formatValue={formatValue}
            duration="600"
          />
          <span className="text-xs font-medium -mt-1">CCOIN</span>
        </div>
        <div className="col-span-1 justify-self-center text-mg text-grey-500 flex flex-col border rounded-md w-full p-2 border-[#4b4314]">
          <span className="text-xs font-medium -mt-1">Avaliable</span>
          <AnimatedNumber
            className="text-xl font-medium"
            value={avaliable}
            formatValue={formatValue}
            duration="600"
          />
        </div>
        <div className="col-span-1 justify-self-center text-mg text-grey-500 flex flex-col border rounded-md w-full p-2 border-[#4b4314]">
          <span className="text-xs font-medium -mt-1">Expire</span>
          <span className="text-xs font-medium mt-1">{eXpire}</span>
        </div>
        <div className="col-span-2 justify-self-center text-mg text-grey-500 flex flex-col border rounded-md w-full p-2 border-[#4b4314]">
          <span className="text-xs font-medium -mt-1">Status</span>
          {console.log("active:", active)}
          {active === "true" ? (
            <span className="text-xs font-medium mt-1 text-green-500">
              Active
            </span>
          ) : (
            <span className="text-xs font-medium mt-1 text-rose-500">
              Inactivce
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

function PackageEdit({ num, ccoin, avaliable, expire, active }) {
  const [dateValue, setDateValue] = useState(new Date(expire * 1000));
  const [calVis, setCalVis] = useState(false);
  const [isActive, setActive] = useState(active);
  //const eXpire = new Date(Number(expire) * 1000).toLocaleDateString('en-GB')
  useEffect(() => {
    setDateValue(new Date(expire * 1000));
    setActive(active);

    return () => {};
  }, [expire, active]);

  useEffect(() => {}, []);

  function onChange(nextValue) {
    setDateValue(nextValue);
    expireArr[num - 1] = Math.floor(dateValue.getTime() / 1000).toString();
    console.log("expireArr : ", expireArr[num - 1]);
  }

  return (
    <div className=" col-span-2 content-center text-mg text-grey-500 flex flex-col border rounded-md w-full p-2 border-gray-400">
      <span className="text-xl font-medium mt-1">Package {num}</span>

      <div className="w-full mt-5 grid grid-cols-2 gap-1 grid-flow-row text-sm text-center text-gray-500 font-bold content-center mb-5">
        <div className="col-span-1 flex flex-col row-span-1 content-center text-grey-500 border rounded-md w-full p-2 border-gray-500">
          <span className="text-xs font-medium">CCOIN</span>
          <input
            id="ccoin"
            className="text-center border-2 text-black rounded-md bg-white border-gray-500"
            placeholder={(ccoin / 10 ** 18).toFixed(0)}
            onChange={(e) => {
              const re = /^[0-9\b]+$/;
              if (e.target.value === "" || re.test(e.target.value)) {
                ccoinArr[num - 1] = BigInt(
                  e.target.value.toString() + "000000000000000000"
                );
                console.log("ccoinArr:", ccoinArr[num - 1]);
              }
            }}
          ></input>
        </div>
        <div className="col-span-1 justify-self-center text-mg text-gray-500 flex flex-col border rounded-md w-full p-2 border-gray-500">
          <span className="text-xs font-medium">Avaliable</span>
          <input
            id="avaliable"
            className="text-center border-2 text-black rounded-md bg-white border-gray-500"
            placeholder={avaliable}
            keyboardtype="decimal-pad"
            autoComplete="off"
            //value={avaliable}
            onChange={(e) => {
              const re = /^[0-9\b]+$/;
              if (e.target.value === "" || re.test(e.target.value)) {
                avaliableArr[num - 1] = Number(e.target.value);
                console.log("avaliableArr:", num - 1, avaliableArr[num - 1]);
              }
            }}
          />
        </div>
        <div
          className="relative col-span-2 row-span-4 justify-self-center text-mg text-grey-500 flex flex-col border rounded-md w-full h-18 p-2 border-gray-500 transition-all hover:cursor-pointer"
          onMouseOver={() => setCalVis(true)}
          onMouseLeave={() => setCalVis(false)}
        >
          <span className="text-xs font-medium -mt-1">Expire</span>
          <span className="text-xs font-medium mt-1">
            {new Date(dateValue).toLocaleDateString("en-GB")}
          </span>
          {calVis ? (
            <Calendar
              className={`${
                calVis ? "visible" : "invisible"
              } text-sm absolute drop-shadow-lg top-12 left-0 text-center border-1 text-black/75 rounded-md bg-white border-gray-500`}
              //placeholder={eXpire}
              value={dateValue}
              onChange={onChange}
              onMouseOver={() => setCalVis(true)}
              onMouseLeave={() => setCalVis(false)}
            />
          ) : (
            <div />
          )}
        </div>
        <div className="col-span-2 justify-self-center text-mg text-grey-500 flex flex-col border rounded-md w-full p-2 border-gray-500">
          <span className="text-xs font-medium -mt-1">Status</span>
          {isActive ? (
            <span
              className="text-xs font-medium mt-1 text-green-500 transition-all hover:cursor-pointer"
              onClick={() => {
                setActive(false);
                isActiveArr[num - 1] = false;
                console.log("isActiveArr[num - 1] :", num - 1, isActiveArr);
              }}
            >
              Active
            </span>
          ) : (
            <span
              className="text-xs font-medium mt-1 text-rose-500 transition-all hover:cursor-pointer"
              onClick={() => {
                setActive(true);
                isActiveArr[num - 1] = true;
                console.log("isActiveArr[num - 1] :", num - 1, isActiveArr);
              }}
            >
              Inactivce
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

function Pool() {
  const { CSVReader } = useCSVReader();
  const [csvResult, setCsvResult] = useState();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState();
  const [info, setInfo] = useState();
  const [cBalance, setCBalance] = useState();
  const [cStakeBalance, setCStakeBalance] = useState();
  const [state, setState] = useState(0);
  const [account, setAccount] = useState();
  const [package1, setPackage1] = useState([]);
  const [package2, setPackage2] = useState([]);
  const [package3, setPackage3] = useState([]);
  const [package4, setPackage4] = useState([]);
  const [relayerBNB, setRelayerBNB] = useState();
  const [relayer3BNB, setRelayer3BNB] = useState();
  const [relayer4BNB, setRelayer4BNB] = useState();
  const [relayer5BNB, setRelayer5BNB] = useState();
  const [useRelayer, setUseRelayer] = useState(false);
  const [CCXSExchange, setCCXSExchange] = useState(false);
  const [BUSD, setBUSD] = useState();
  const [BUSD2, setBUSD2] = useState();
  const [USDT, setUSDT] = useState();
  const [ETH, setETH] = useState();
  const [TRX, setTRX] = useState();
  const [CCOINSTAKE, setCCOINSTAKE] = useState();
  const [CCSXSTAKE, setCCSXSTAKE] = useState();
  const [randomBoxOwner, setRandomBoxAdmin] = useState();
  const [coinStakeOwner, setCoinStakeOwner] = useState();
  const [totalInStake, setTotalInStake] = useState();
  const [erc1155balance, setErc1155balance] = useState();
  const [erc1155Owner, setErc1155Owner] = useState();
  const [erc1155Admin, setErc1155Admin] = useState();
  const [erc721CCOINBalance, setErc721CCOINBalance] = useState();
  const [erc721CCSXBalance, setErc721CCSXBalance] = useState();
  const [erc721Owner, setErc721Owner] = useState();
  const [erc721Admin, setErc721Admin] = useState();
  const [erc721isHatched, setErc721isHatched] = useState(true);
  const [erc721idnum, setErc721idnum] = useState(true);
  const [eggTotalSupply, setEggTotalSupply] = useState(0);
  const [itemLeft, setItemLeft] = useState([]);
  const [autoPayAdmin, setAutoPayAdmin] = useState();
  const [autoPayCCOIN, setAutoPayCCOIN] = useState();
  const [autoPayBUSD, setAutoPayBUSD] = useState();

  const wc = useSelector((state) => state.walletConnect.value);

  const handleClose = () => {
    setOpen(false);
    setState(state + 1);
  };
  const handleToggle = () => {
    setOpen(!open);
    setState(state + 1);
  };

  let provider;
  let ethereum = window.ethereum;
  let tempSigner;

  if (!!wc.account) {
    console.log("WC!");
    ethereum = wc.provider;
    provider = wc.web3Provider;
    tempSigner = provider.getSigner();
    ethereum.on("accountsChanged", (accounts) => setState(state + 1));
  } else if (isMetaMaskInstalled()) {
    console.log("MT!");
    ethereum = window.ethereum;
    provider = new ethers.providers.Web3Provider(ethereum);
    tempSigner = provider.getSigner();
    ethereum.on("accountsChanged", (accounts) => setState(state + 1));
  }

  useEffect(() => {
    console.log(csvResult);
    setInfo("");
    setError("");
    return () => {};
  }, [csvResult]);

  useEffect(() => {
    callItemLeft();
    return () => {
      setItemLeft([]);
    };
  }, []);

  useEffect(() => {
    if (!!wc.account || isMetaMaskInstalled()) {
      getCurrentAccount();
      getCBalance();
      getPackages(1);
      getPackages(2);
      getPackages(3);
      getPackages(4);
    }
    setError("");
    return () => {};
  }, [state, wc.account, account]);

  useEffect(() => {
    getBUSDBalance();
    getUSDTBalance();
    getBUSD2Balance();
    getRelayerBNB();
    getRelayer3BNB();
    getRelayer4BNB();
    getRelayer5BNB();
    getTRXBalance();
    getETHBalance();
    getTransferMode();
    getOpenCC();
    getRandomBoxOwner();
    getCCOINStakeBalance();
    getCCSXStakeBalance();
    getCoinStakeOwner();
    getTotalInStake();
    getCStakeBalance();
    getERC1155Balance();
    getERC1155Owner();
    getERC1155Admin();
    getERC721CCOINBalance();
    getERC721CCSXBalance();
    getERC721Owner();
    getEggTotalSupply();
    getERC721Admin();
    getAutoPaymentAdmin();
    getAutoPayBUSDBalance();
    getAutoPayCCOINBalance();

    console.log(
      "UNIX:",
      parseInt(
        new Date(Date.UTC("2022", "09", "28", "16", "40", "56")).getTime() /
          1000
      )
    );

    console.log("packagesArr:", packagesArr);
    console.log("avaliableArr:", avaliableArr);
    console.log("ccoinArr:", ccoinArr);
    console.log("expireArr:", expireArr);
    console.log("isActiveArr:", isActiveArr);

    return () => {};
  }, [state]);

  if (csvResult !== undefined) {
    packagesArr.length = 0;
    avaliableArr.length = 0;
    ccoinArr.length = 0;
    expireArr.length = 0;
    isActiveArr.length = 0;
    csvResult.map((result) => {
      packagesArr.push(result[0]);
      avaliableArr.push(result[1]);
      ccoinArr.push(BigInt(result[2].toString() + "000000000000000000"));
      isActiveArr.push(result[3]);
      //ccoinArr.push(BigInt(result[2] * 10 ** 18).toString())

      expireArr.push(result[3]);
      console.log("packagesArr :", packagesArr);
      console.log("avaliableArr :", avaliableArr);
      console.log("ccoinArr :", ccoinArr);
      console.log("expireArr :", expireArr);
      console.log("isActiveArr :", isActiveArr);
    });
  }

  async function getCurrentAccount() {
    const accounts = await ethereum.request({
      method: "eth_accounts",
    });
    setAccount(accounts[0]);
    console.log("accounts[0]:", accounts[0]);
    return accounts[0];
  }

  async function callItemLeft() {
    let _ItemArr = [];
    axios
      .get(
        "https://snybclbtdhxo.grandmoralis.com:2053/server/functions/fetchRemainingCards"
      )
      .then((x) => {
        const result = x.data.result;
        result.forEach((element) => {
          _ItemArr.push(element);
        });
      })
      .then(() => {
        setItemLeft(_ItemArr);
      });
  }

  async function getBlockTimeStamp() {
    const cPool = new ethers.Contract(pool, abi, tempSigner);
    const blockTimeStamp = await cPool.callStatic.getBlockTimeStamp();
    console.log("blockTimeStamp", blockTimeStamp.toString());
    return blockTimeStamp.toString();
  }

  async function getCBalance() {
    const ccoinTokenContract = new ethers.Contract(CCOIN, ierc20, tempSigner);
    await ccoinTokenContract.balanceOf(pool).then((x) => {
      setCBalance(parseInt(ethers.utils.formatEther(x)));
      console.log("balance:", parseInt(ethers.utils.formatEther(x)));
    });
    /*const response = await fetch(CCOINtokenBalanceAPI)
        const myJson = await response.json().then((x) => {
            setCBalance(x.result / 10 ** 18)
        })*/
  }

  async function getCStakeBalance() {
    const ccoinTokenContract = new ethers.Contract(CCOIN, ierc20, tempSigner);
    await ccoinTokenContract.balanceOf(CSTAKE).then((x) => {
      setCStakeBalance(parseInt(ethers.utils.formatEther(x)));
      console.log("balance:", parseInt(ethers.utils.formatEther(x)));
    });
    /*const response = await fetch(CCOINtokenBalanceAPI)
        const myJson = await response.json().then((x) => {
            setCBalance(x.result / 10 ** 18)
        })*/
  }

  async function getBUSDBalance() {
    /*const ccoinTokenContract = new ethers.Contract(busd, ierc20, tempSigner)
        await ccoinTokenContract.balanceOf(pool).then((x) => {
            setBUSD((x / 10 ** 18).toFixed(2))
        })*/
    const response = await fetch(BUSDtokenBalanceAPI);
    const myJson = await response.json().then((x) => {
      setBUSD(x.result / 10 ** 18);
    });

    //console.log('USDT', myJson.result / 10 ** 18)
  }

  async function getETHBalance() {
    const response = await fetch(etherAPI);
    const myJson = await response.json().then((x) => {
      setETH(x.result / 10 ** 18);
    });
  }

  async function getTRXBalance() {
    const response = await fetch(tronAPI);
    const myJson = await response.json().then((x) => {
      setTRX(x.balance / 10 ** 6);
    });
  }

  async function getBUSD2Balance() {
    const response = await fetch(BUSDtokenBalanceAPIRandom);
    const myJson = await response.json().then((x) => {
      setBUSD2(x.result / 10 ** 18);
    });
  }

  async function getUSDTBalance() {
    const response = await fetch(USDTtokenBalanceAPI);
    const myJson = await response.json().then((x) => {
      setUSDT(x.result / 10 ** 18);
    });
  }

  async function getCCOINStakeBalance() {
    const cStake = new ethers.Contract(CCOIN, ierc20, tempSigner);
    const Direct = await cStake.balanceOf(CSTAKE).then((x) => {
      setCCOINSTAKE(parseInt(ethers.utils.formatEther(x)));
    });
  }

  async function getERC1155Balance() {
    const cStake = new ethers.Contract(CCOIN, ierc20, tempSigner);
    const Direct = await cStake.balanceOf(NFT1155STAKE).then((x) => {
      setErc1155balance(parseInt(ethers.utils.formatEther(x)));
    });
  }

  async function getERC721CCOINBalance() {
    const cStake = new ethers.Contract(CCOIN, ierc20, tempSigner);
    const Direct = await cStake.balanceOf(NFT721STAKE).then((x) => {
      setErc721CCOINBalance(parseInt(ethers.utils.formatEther(x)));
    });
  }

  async function getERC721CCSXBalance() {
    const cStake = new ethers.Contract(CCSX, ierc20, tempSigner);
    const Direct = await cStake.balanceOf(NFT721STAKE).then((x) => {
      setErc721CCSXBalance(parseInt(ethers.utils.formatEther(x)));
    });
  }

  async function getAutoPayCCOINBalance() {
    const cStake = new ethers.Contract(CCOIN, ierc20, tempSigner);
    const Direct = await cStake.balanceOf(AUTOPAYMENT).then((x) => {
      setAutoPayCCOIN(parseInt(ethers.utils.formatEther(x)));
    });
  }

  async function getAutoPayBUSDBalance() {
    const cStake = new ethers.Contract(busd, ierc20, tempSigner);
    const Direct = await cStake.balanceOf(AUTOPAYMENT).then((x) => {
      setAutoPayBUSD(parseInt(ethers.utils.formatEther(x)));
    });
  }

  async function getEggTotalSupply() {
    const contract = new ethers.Contract(NFT721STAKE, erc721, tempSigner);
    const result = await contract.callStatic.totalSupply();
    //console.log('hatchersLength', hatchersLength.toString())
    setEggTotalSupply(parseInt(result.toString()));
    //console.log('totalSupply:', parseInt(eggTotalSupply))
    return parseInt(result.toString());
  }

  async function getCCSXStakeBalance() {
    const cStake = new ethers.Contract(CCSX, ierc20, tempSigner);
    const Direct = await cStake.balanceOf(CSTAKE).then((x) => {
      console.log("CCSXSTAKE BALANCE:", parseInt(ethers.utils.formatEther(x)));
      setCCSXSTAKE(parseInt(ethers.utils.formatEther(x)));
    });
  }

  async function getRelayerBNB() {
    const response = await fetch(RelayerAPI(relayer));
    const myJson = await response.json().then((x) => {
      setRelayerBNB(x.result / 10 ** 18);
    });
    //console.log('BNB:', myJson.result / 10 ** 18)
  }

  async function getRelayer3BNB() {
    const response = await fetch(RelayerAPI(relayer3));
    const myJson = await response.json().then((x) => {
      setRelayer3BNB(x.result / 10 ** 18);
    });
  }

  async function getRelayer4BNB() {
    const response = await fetch(RelayerAPI(relayer4));
    const myJson = await response.json().then((x) => {
      setRelayer4BNB(x.result / 10 ** 18);
    });
  }

  async function getRelayer5BNB() {
    const response = await fetch(RelayerAPI(relayer5));
    const myJson = await response.json().then((x) => {
      setRelayer5BNB(x.result / 10 ** 18);
    });
  }

  async function getTransferMode() {
    const cPool = new ethers.Contract(pool, abi, tempSigner);
    const Direct = await cPool.callStatic.Direct().then((x) => {
      setUseRelayer(!x);
    });
  }

  async function getOpenCC() {
    const cPool = new ethers.Contract(pool, abi, tempSigner);
    const Direct = await cPool.callStatic.isOpenCC().then((x) => {
      setCCXSExchange(x);
    });
  }

  async function getRandomBoxOwner() {
    const cPool = new ethers.Contract(randomBoxNFT, rcAbi, tempSigner);
    const Direct = await cPool.callStatic.owner().then((x) => {
      console.log("randomOwner:", x);
      setRandomBoxAdmin(x);
    });
  }

  async function getERC1155Owner() {
    const cPool = new ethers.Contract(NFT1155STAKE, erc1155, tempSigner);
    const Direct = await cPool.callStatic.owner().then((x) => {
      console.log("getERC1155Owner:", x);
      setErc1155Owner(x);
    });
  }

  async function getERC721Owner() {
    const cPool = new ethers.Contract(NFT721STAKE, erc721, tempSigner);
    const Direct = await cPool.callStatic.owner().then((x) => {
      console.log("getERC721Owner:", x);
      setErc721Owner(x);
    });
  }

  async function getERC721Admin() {
    const cPool = new ethers.Contract(NFT721STAKE, erc721, tempSigner);
    const Direct = await cPool.callStatic.adminAddress().then((x) => {
      console.log("getERC721Owner:", x);
      setErc721Admin(x);
    });
  }

  async function getERC1155Admin() {
    const cPool = new ethers.Contract(NFT1155STAKE, erc1155, tempSigner);
    const Direct = await cPool.callStatic.admin().then((x) => {
      console.log("erc1155Admin:", x);
      setErc1155Admin(x);
    });
  }

  async function getAutoPaymentAdmin() {
    const cPool = new ethers.Contract(AUTOPAYMENT, autopaymentabi, tempSigner);
    const Direct = await cPool.callStatic.adminAddress().then((x) => {
      console.log("getAutoPaymentAdmin:", x);
      setAutoPayAdmin(x);
    });
  }

  async function getCoinStakeOwner() {
    const cStake = new ethers.Contract(CSTAKE, cstakeabi, tempSigner);
    const Direct = await cStake.callStatic.owner().then((x) => {
      console.log("setCoinStakeOwner:", x);
      setCoinStakeOwner(x);
    });
  }

  async function getTotalInStake() {
    const cStake = new ethers.Contract(CSTAKE, cstakeabi, tempSigner);
    const Direct = await cStake.callStatic.totalInStake().then((x) => {
      console.log("setTotalInStake:", parseInt(ethers.utils.formatEther(x)));
      setTotalInStake(parseInt(ethers.utils.formatEther(x)));
    });
  }

  async function withdrawToken(token, amount) {
    const tokenContract = new ethers.Contract(pool, abi, tempSigner);
    const tx = await tokenContract.recoverToken(
      token,
      BigInt(amount.toString() + "000000000000000000")
      //BigInt(amount * 10 ** 18)
    );
  }

  async function withdrawTokenRandomBox(token, amount) {
    const tokenContract = new ethers.Contract(randomBoxNFT, rcAbi, tempSigner);
    const tx = await tokenContract.recoverToken(
      token,
      BigInt(amount.toString() + "000000000000000000")
      //BigInt(amount * 10 ** 18)
    );
  }

  async function withdrawTokenCStake(token, amount) {
    const tokenContract = new ethers.Contract(CSTAKE, cstakeabi, tempSigner);
    const tx = await tokenContract.recoverToken(
      token,
      BigInt(amount.toString() + "000000000000000000")
      //BigInt(amount * 10 ** 18)
    );
  }

  async function withdrawTokenAutoPayment(token, amount) {
    const tokenContract = new ethers.Contract(
      AUTOPAYMENT,
      autopaymentabi,
      tempSigner
    );
    const tx = await tokenContract.recoverToken(
      token,
      BigInt(amount.toString() + "000000000000000000")
      //BigInt(amount * 10 ** 18)
    );
  }

  async function mint1155(_to, _ids, _amounts) {
    const tokenContract = new ethers.Contract(
      NFT1155STAKE,
      erc1155,
      tempSigner
    );
    const tx = await tokenContract.mint(_to, _ids, _amounts);
    const txx = await tx.wait(1).then(() => {
      document.getElementById("erc1155reciver").value = "Complete!";
      document.getElementById("erc1155reciver").disabled = false;
    });
  }

  async function mint721(_id, _to, _isHatched) {
    const tokenContract = new ethers.Contract(NFT721STAKE, erc721, tempSigner);
    try {
      const tx = await tokenContract.mintPet(_id, _to, _isHatched);
      const txx = await tx.wait(1).then(() => {
        document.getElementById("erc721reciver").disabled = false;
        document.getElementById("erc721id").disabled = false;
        document.getElementById("erc721id").value = "Complete!";
        document.getElementById("erc721reciver").value = "";
      });
    } catch (error) {
      console.log("error:", error);
      document.getElementById("erc721reciver").disabled = false;
      document.getElementById("erc721id").disabled = false;
      document.getElementById("erc721id").value = "Fail!";
    }
  }

  async function withdrawToken1155Stake(token, amount) {
    const tokenContract = new ethers.Contract(
      NFT1155STAKE,
      erc1155,
      tempSigner
    );
    const tx = await tokenContract.recoverToken(
      token,
      BigInt(amount.toString() + "000000000000000000")
      //BigInt(amount * 10 ** 18)
    );
  }

  async function withdrawToken721Stake(token, amount) {
    const tokenContract = new ethers.Contract(NFT721STAKE, erc721, tempSigner);
    const tx = await tokenContract.recoverToken(
      token,
      BigInt(amount.toString() + "000000000000000000")
      //BigInt(amount * 10 ** 18)
    );
  }

  async function setDirect() {
    const tokenContract = new ethers.Contract(pool, abi, tempSigner);
    const tx = await tokenContract.setDirect();
    const complete = tx.wait(3).then((x) => {
      setState(state + 1);
    });
  }

  async function setIndirect() {
    const tokenContract = new ethers.Contract(pool, abi, tempSigner);
    const tx = await tokenContract.setIndirect();
    const complete = tx.wait(3).then((x) => {
      setState(state + 1);
    });
  }

  async function openCC() {
    const tokenContract = new ethers.Contract(pool, abi, tempSigner);
    const tx = await tokenContract.openCC();
    const complete = tx.wait(3).then((x) => {
      setState(state + 1);
    });
  }

  async function closeCC() {
    const tokenContract = new ethers.Contract(pool, abi, tempSigner);
    const tx = await tokenContract.closeCC();
    const complete = tx.wait(3).then((x) => {
      setState(state + 1);
    });
  }

  async function getPackages(num) {
    const cPool = new ethers.Contract(pool, abi, tempSigner);
    const Packages = await cPool.callStatic.Packages(num);

    let packText = Packages.toString();
    const packArr = packText.split(",");
    if (num == 1) {
      setPackage1(packArr);
    } else if (num == 2) {
      setPackage2(packArr);
    } else if (num == 3) {
      setPackage3(packArr);
    } else if (num == 4) {
      setPackage4(packArr);
    }
  }

  async function addPackages() {
    const poolContract = new ethers.Contract(pool, abi, tempSigner);
    const addPackages = await poolContract.AddMultiPackage(
      packagesArr,
      avaliableArr,
      ccoinArr,
      expireArr,
      isActiveArr
    );
    setInfo("Transfering your coin !");
    const complete = await addPackages.wait(3).then((x) => {
      setCsvResult();
      handleClose();
      setState(state + 1);
    });
  }

  let sumCoin = 0;
  let nft1155Id = 1;
  function onSelect(optionSelected) {
    if (optionSelected.value == "Angel Boot") {
      nft1155Id = 1;
    } else if (optionSelected.value == "Angel Guard") {
      nft1155Id = 2;
    } else if (optionSelected.value == "Angel Amulet") {
      nft1155Id = 3;
    } else if (optionSelected.value == "Angel Halo") {
      nft1155Id = 4;
    } else if (optionSelected.value == "Athena Shield") {
      nft1155Id = 5;
    } else if (optionSelected.value == "Athena Boot") {
      nft1155Id = 6;
    } else if (optionSelected.value == "Ares Ring") {
      nft1155Id = 7;
    } else if (optionSelected.value == "Ares Amulet") {
      nft1155Id = 8;
    } else if (optionSelected.value == "Apollo Armor") {
      nft1155Id = 9;
    } else if (optionSelected.value == "Apollo Helmet") {
      nft1155Id = 10;
    }
  }

  return (
    <div className="flex flex-col w-full justifyitem-center items-center mt-20 h-screen select-none">
      <Links CCOIN={CCOIN} CCSX={CCSX} />

      <div className="mt-10 md:w-3/5 w-full flex flex-row p-5  justifyitem-center mb-10 items-center drop-shadow-lg rounded-xl text-black bg-neutral-100 bg-opacity-70 backdrop-blur-xl">
        <span className="text-blue-400 font-bold text-sm my-1">
          <br />
        </span>
        <div className=" blur-sm absolute -top-4 right-4 text-3xl md:text-4xl self-center -mb-3 font-bold text-yellow-700 break-all">
          R e l a y e r s
        </div>
        <div className=" absolute -top-5 right-5 text-3xl md:text-4xl self-center -mb-3 font-bold text-white break-all">
          R e l a y e r s
        </div>
        <div className="w-full mt-5 grid grid-flow-row md:grid-cols-3 grid-cols-1 gap-1">
          <div className="col-span-1 justify-self-center text-mg text-grey-500 flex flex-col border rounded-md w-full p-2 border-[#4b4314]">
            <span className="text-xs font-medium mt-2">Relayer Balance</span>
            <div className="w-full justify-center place-items-centertext-mg text-grey-800 flex flex-row rounded-md p-1 bg-white/5">
              {relayer.slice(0, 6) + "...." + relayer.slice(-4)}{" "}
              <a href="#">
                <AiOutlineCopy
                  className="text-lg transition-all hover:text-[#ffae00] ml-2"
                  onClick={() => navigator.clipboard.writeText(relayer)}
                />
              </a>
            </div>

            <span className="flex flex-row place-items-center justify-center">
              <AnimatedNumber
                className="text-xl font-medium"
                value={relayerBNB}
                formatValue={formatValueBNB}
                duration="600"
              />
              <span className="text-xs font-bold mt-1 ml-4">BNB</span>
            </span>
            <span className="flex flex-row place-items-center justify-center">
              <AnimatedNumber
                className="text-xl font-medium"
                value={ETH}
                formatValue={formatValueBNB}
                duration="600"
              />
              <span className="text-xs font-bold mt-1 ml-4">ETH</span>
            </span>

            <span className="text-xs flex flex-row place-items-center justify-between mt-3">
              <div> Use Relayer :</div>
              <div>
                {useRelayer ? (
                  <span
                    className="text-green-500 transition-all hover:text-[#ffae00]  hover:cursor-pointer"
                    onClick={() => setDirect()}
                  >
                    On
                  </span>
                ) : (
                  <span
                    className="text-rose-500 transition-all hover:text-[#ffae00]  hover:cursor-pointer"
                    onClick={() => setIndirect()}
                  >
                    Off
                  </span>
                )}
              </div>
            </span>
          </div>
          <div className="col-span-1 justify-self-center text-mg text-grey-500 flex flex-col border rounded-md w-full p-2 border-[#4b4314]">
            <span className="text-xs font-medium mt-2">
              Tron Relayer Balance
            </span>

            <div className="w-full justify-center place-items-centertext-mg text-grey-800 flex flex-row rounded-md p-1 bg-white/5">
              {tronrelayer.slice(0, 6) + "...." + tronrelayer.slice(-4)}{" "}
              <a href="#">
                <AiOutlineCopy
                  className="text-lg transition-all hover:text-[#ffae00] ml-2"
                  onClick={() => navigator.clipboard.writeText(tronrelayer)}
                />
              </a>
            </div>
            <span className="flex flex-row place-items-center justify-center">
              <AnimatedNumber
                className="text-xl font-medium"
                value={TRX}
                formatValue={formatValueBNB}
                duration="600"
              />
              <span className="text-xs font-bold mt-1 ml-4">TRX</span>
            </span>
          </div>
          <div className="col-span-1 justify-self-center text-mg text-grey-500 flex flex-col border rounded-md w-full p-2 border-[#4b4314]">
            <span className="text-xs font-medium mt-2">Relayer 3 Balance</span>
            <div className="w-full justify-center place-items-centertext-mg text-grey-800 flex flex-row rounded-md p-1 bg-white/5">
              {relayer3.slice(0, 6) + "...." + relayer3.slice(-4)}{" "}
              <a href="#">
                <AiOutlineCopy
                  className="text-lg transition-all hover:text-[#ffae00] ml-2"
                  onClick={() => navigator.clipboard.writeText(relayer3)}
                />
              </a>
            </div>

            <span className="flex flex-row place-items-center justify-center">
              <AnimatedNumber
                className="text-xl font-medium"
                value={relayer3BNB}
                formatValue={formatValueBNB}
                duration="600"
              />
              <span className="text-xs font-bold mt-1 ml-4">BNB</span>
            </span>
          </div>
          <div className="col-span-1 justify-self-center text-mg text-grey-500 flex flex-col border rounded-md w-full p-2 border-[#4b4314]">
            <span className="text-xs font-medium mt-2">Relayer 4 Balance</span>
            <div className="w-full justify-center place-items-centertext-mg text-grey-800 flex flex-row rounded-md p-1 bg-white/5">
              {relayer4.slice(0, 6) + "...." + relayer4.slice(-4)}{" "}
              <a href="#">
                <AiOutlineCopy
                  className="text-lg transition-all hover:text-[#ffae00] ml-2"
                  onClick={() => navigator.clipboard.writeText(relayer4)}
                />
              </a>
            </div>

            <span className="flex flex-row place-items-center justify-center">
              <AnimatedNumber
                className="text-xl font-medium"
                value={relayer4BNB}
                formatValue={formatValueBNB}
                duration="600"
              />
              <span className="text-xs font-bold mt-1 ml-4">BNB</span>
            </span>
          </div>
          <div className="col-span-1 justify-self-center text-mg text-grey-500 flex flex-col border rounded-md w-full p-2 border-[#4b4314]">
            <span className="text-xs font-medium mt-2">Relayer 5 Balance</span>
            <div className="w-full justify-center place-items-centertext-mg text-grey-800 flex flex-row rounded-md p-1 bg-white/5">
              {relayer5.slice(0, 6) + "...." + relayer5.slice(-4)}{" "}
              <a href="#">
                <AiOutlineCopy
                  className="text-lg transition-all hover:text-[#ffae00] ml-2"
                  onClick={() => navigator.clipboard.writeText(relayer5)}
                />
              </a>
            </div>

            <span className="flex flex-row place-items-center justify-center">
              <AnimatedNumber
                className="text-xl font-medium"
                value={relayer5BNB}
                formatValue={formatValueBNB}
                duration="600"
              />
              <span className="text-xs font-bold mt-1 ml-4">BNB</span>
            </span>
          </div>
        </div>
      </div>

      <div className="md:w-3/4 w-full p-5 flex flex-col justifyitem-center items-center drop-shadow-lg rounded-xl text-black bg-neutral-100 bg-opacity-70 backdrop-blur-xl">
        <span className="text-blue-400 font-bold text-sm my-1">
          <br />
        </span>
        <div className=" blur-sm absolute -top-4 right-4 text-3xl md:text-4xl self-center -mb-3 font-bold text-yellow-700 break-all">
          P a c k a g e s - A d m i n
        </div>
        <div className=" absolute -top-5 right-5 text-3xl md:text-4xl self-center -mb-3 font-bold text-white break-all">
          P a c k a g e s - A d m i n
        </div>

        <div className="w-full mt-5 grid grid-cols-8 gap-2 grid-flow-row text-sm text-center text-gray-600 font-bold content-center mb-5">
          <div className="col-span-6 justify-self-center text-mg text-gray-600 flex flex-col border rounded-md w-full p-2 border-[#4b4314]">
            <div className="w-full justify-center place-items-centertext-mg text-gray-600 flex flex-row rounded-md p-1 bg-white/5">
              {pool.slice(0, 6) + "...." + pool.slice(-4)}{" "}
              <a href="#">
                <AiOutlineCopy
                  className="text-lg transition-all hover:text-[#ffae00] ml-2"
                  onClick={() => navigator.clipboard.writeText(pool)}
                />
              </a>
            </div>
            <span className="text-xs font-medium">Contract CCOIN Balance</span>
            <AnimatedNumber
              className="text-2xl font-medium"
              value={cBalance}
              formatValue={formatValue}
              duration="600"
            />
            <span className="text-xs font-medium mt-1">
              <button
                className=" m-1 w-16 bg-[#474747] rounded-lg p-1 text-xs text-white transition-all hover:bg-[#ffae00]"
                onClick={() => withdrawToken(CCOIN, cBalance - 1)}
              >
                Withdraw
              </button>
            </span>
          </div>

          <div className="col-span-2 justify-self-center text-mg text-grey-500 flex flex-col border rounded-md w-full p-2 border-[#4b4314]">
            <span className="text-xs font-medium mt-1">USDx Balance</span>
            <span className="flex flex-row place-items-center justify-between">
              <img
                className=" m-1"
                width="24"
                src={process.env.PUBLIC_URL + "/img/coins/busd.png"}
              />
              <AnimatedNumber
                className="text-base font-medium"
                value={BUSD}
                formatValue={formatValue}
                duration="600"
              />
              <button
                className=" m-1 w-16 bg-[#474747] rounded-lg p-1 text-xs text-white transition-all hover:bg-[#ffae00]"
                onClick={() => withdrawToken(busd, BUSD)}
              >
                Withdraw
              </button>
            </span>
            <span className="flex flex-row place-items-center justify-between">
              <img
                className=" m-1"
                width="24"
                src={process.env.PUBLIC_URL + "/img/coins/usdt.png"}
              />
              <AnimatedNumber
                className="text-base font-medium"
                value={USDT}
                formatValue={formatValue}
                duration="600"
              />
              <button
                className=" m-1 w-16 bg-[#474747] rounded-lg p-1 text-xs text-white transition-all hover:bg-[#ffae00]"
                onClick={() => withdrawToken(usdt, USDT)}
              >
                Withdraw
              </button>
            </span>
            <span className="text-xs flex flex-row place-items-center justify-between mt-8">
              <div>CCOIN Classic Exchange :</div>
              <div>
                {CCXSExchange ? (
                  <span
                    className="text-green-500 hover:text-[#ffae00] transition-all hover:cursor-pointer"
                    onClick={() => closeCC()}
                  >
                    On
                  </span>
                ) : (
                  <span
                    className="text-rose-500 hover:text-[#ffae00] transition-all hover:cursor-pointer"
                    onClick={() => openCC()}
                  >
                    Off
                  </span>
                )}
              </div>
            </span>
          </div>
          <Package
            num="1"
            ccoin={package1[1]}
            avaliable={package1[0]}
            expire={package1[2]}
            active={package1[3]}
          />
          <Package
            num="2"
            ccoin={package2[1]}
            avaliable={package2[0]}
            expire={package2[2]}
            active={package2[3]}
          />
          <Package
            num="3"
            ccoin={package3[1]}
            avaliable={package3[0]}
            expire={package3[2]}
            active={package3[3]}
          />
          <Package
            num="4"
            ccoin={package4[1]}
            avaliable={package4[0]}
            expire={package4[2]}
            active={package4[3]}
          />

          <div className="col-span-8 justify-end text-mg text-grey-500 flex flex-row border rounded-md w-full p-2 border-[#4b4314]">
            <span className="w-full border-0 text-rose-500 text-center justify-center items-center">
              {info}
            </span>
            <button
              className=" m-1 w-32 bg-[#474747] rounded-lg p-2 text-xs text-white transition-all hover:bg-[#ffae00]"
              onClick={() => {
                setState(0);
                setInfo("");
              }}
            >
              Reload
            </button>
            <button
              id="edit_btn"
              className=" m-1 w-32 bg-[#474747] rounded-lg p-2 text-xs text-white transition-all hover:bg-[#ffae00]"
              onClick={() => {
                if (!!account) {
                  packagesArr.length = 0;
                  avaliableArr.length = 0;
                  ccoinArr.length = 0;
                  expireArr.length = 0;
                  isActiveArr.length = 0;

                  packagesArr.push(1, 2, 3, 4);
                  avaliableArr.push(
                    package1[0],
                    package2[0],
                    package3[0],
                    package4[0]
                  );
                  ccoinArr.push(
                    package1[1],
                    package2[1],
                    package3[1],
                    package4[1]
                  );
                  expireArr.push(
                    package1[2],
                    package2[2],
                    package3[2],
                    package4[2]
                  );
                  isActiveArr.push(
                    package1[3] === "true",
                    package2[3] === "true",
                    package3[3] === "true",
                    package4[3] === "true"
                  );
                  console.log("packagesArr:", packagesArr);
                  console.log("avaliableArr:", avaliableArr);
                  console.log("ccoinArr:", ccoinArr);
                  console.log("expireArr:", expireArr);
                  console.log("isActiveArr:", isActiveArr);
                  handleToggle();
                } else {
                  setInfo("Connect Wallet Required");
                }
              }}
            >
              Edit
            </button>
          </div>
        </div>

        <CSVReader
          onUploadAccepted={(results: any) => {
            setCsvResult(results.data);
          }}
        >
          {({
            getRootProps,
            acceptedFile,
            ProgressBar,
            getRemoveFileProps,
          }: any) => (
            <>
              <div className="w-full flex flex-row justify-between items-center">
                <button
                  type="button"
                  {...getRootProps()}
                  className="flex flex-row bg-[#36300f] rounded-lg p-5 text-xs border-dashed border-2 border-[#4b4314] text-gray-300"
                >
                  <AiOutlineCloudUpload fontSize={18} /> Browse or Drag & Drop
                  .csv here
                </button>
                <div className="text-md text-white">
                  {/*acceptedFile && acceptedFile.name*/}
                </div>
                <button
                  {...getRemoveFileProps()}
                  className=" bg-[#474747] rounded-lg p-4 text-xs text-white transition-all hover:bg-[#e40202]"
                  onClick={() => setCsvResult()}
                >
                  Clear
                </button>
              </div>
              <ProgressBar />
            </>
          )}
        </CSVReader>

        <br />
        <button
          type="button"
          onClick={() => {
            addPackages();
          }}
          className="text-white w-full mt-2 border-[0px] p-2 bg-[#36300f] rounded-md cursor-pointer transition-all hover:bg-[#ffae00]"
        >
          Inject Packkages
        </button>

        <span className="text-red-600 font-bold text-sm my-1">{error}</span>
        {csvResult !== undefined ? (
          <div className="w-full mt-5 grid grid-cols-8 gap-2 grid-flow-row border-b text-sm text-center text-gray-500 font-bold border-gray-600">
            <div className="col-span-1 text-gray-600">#</div>{" "}
            <div className="col-span-2 text-gray-600">Avaliable</div>{" "}
            <div className="col-span-2 text-gray-600">CCOIN </div>{" "}
            <div className="col-span-2 text-gray-600">Expire Date </div>{" "}
            <div className="col-span-1 text-gray-600">Status </div>
          </div>
        ) : (
          <br />
        )}

        <div className="w-full flex flex-col rounded-md justify-between text-sm overflow-y-auto scroll-smooth">
          {csvResult !== undefined ? (
            csvResult.map((x, i) => {
              return (
                <AddrList
                  key={i}
                  packNum={x[0]}
                  avaliable={x[1]}
                  ccoinOut={x[2]}
                  expireDate={x[3]}
                  isActive={x[4]}
                />
              );
            })
          ) : (
            <div />
          )}
        </div>
      </div>

      {/*----------------------------------------------------------------------------------------------------------------------------*/}
      <div className="mt-10 md:w-3/5 w-full p-5 flex flex-col justifyitem-center mb-10 items-center drop-shadow-lg rounded-xl text-black bg-neutral-100 bg-opacity-70 backdrop-blur-xl">
        <span className="text-blue-400 font-bold text-sm my-1">
          <br />
        </span>
        <div className=" blur-sm absolute -top-4 right-4 text-3xl md:text-4xl self-center -mb-3 font-bold text-yellow-700 break-all">
          T r e a s u r e H u n t - A d m i n
        </div>
        <div className=" absolute -top-5 right-5 text-3xl md:text-4xl self-center -mb-3 font-bold text-white break-all">
          T r e a s u r e H u n t - A d m i n
        </div>

        <div className="col-span-2 justify-self-center text-mg text-grey-500 flex flex-col border rounded-md w-full p-2 border-[#4b4314]">
          Random
          <div className="w-full justify-center place-items-centertext-mg text-gray-600 flex flex-row rounded-md p-1 bg-white/5">
            {randomBoxNFT}
            <AiOutlineCopy
              className="text-lg transition-all hover:text-[#ffae00] ml-2 cursor-pointer"
              onClick={() => navigator.clipboard.writeText(randomBoxNFT)}
            />
          </div>
          <span className="text-xs font-medium mt-1">USDx Balance</span>
          <span className="flex flex-row place-items-center justify-between">
            <img
              className=" m-1"
              width="24"
              src={process.env.PUBLIC_URL + "/img/coins/busd.png"}
            />
            <AnimatedNumber
              className="text-base font-medium"
              value={BUSD2}
              formatValue={formatValue}
              duration="600"
            />
            <span>
              <input
                type="text"
                keyboardtype="decimal-pad"
                pattern="[0-9]*"
                step="1"
                autoComplete="off"
                className="rounded-lg opacity-0 p-1 text-sm font-bold active:outline-none focus:outline-none text-right cursor-auto"
              />
              {!!account && !!randomBoxOwner ? (
                randomBoxOwner.toLowerCase() == account.toLowerCase() ? (
                  <button
                    className=" m-1 w-16 bg-[#474747] rounded-lg p-1 text-xs text-white transition-all hover:bg-[#ffae00]"
                    onClick={() => withdrawTokenRandomBox(busd, BUSD2)}
                  >
                    Withdraw
                  </button>
                ) : (
                  <button className=" m-1 w-16 bg-[#9c9c9c] rounded-lg p-1 text-xs text-white transition-all cursor-auto">
                    Withdraw
                  </button>
                )
              ) : (
                <button className=" m-1 w-16 bg-[#9c9c9c] rounded-lg p-1 text-xs text-white transition-all cursor-auto">
                  Withdraw
                </button>
              )}
            </span>
          </span>
        </div>

        <div className="col-span-2 justify-self-center text-mg text-grey-500 flex flex-col border rounded-md w-full p-2 border-[#4b4314] mt-2">
          ERC1155
          <div className="w-full justify-center place-items-center text-mg text-gray-600 flex flex-row rounded-md p-1 bg-white/5">
            {NFT1155STAKE}

            <AiOutlineCopy
              className="text-lg transition-all hover:text-[#ffae00] ml-2 cursor-pointer"
              onClick={() => navigator.clipboard.writeText(NFT1155STAKE)}
            />
          </div>
          <span className="text-xs font-medium mt-1">CCOIN Balance</span>
          <span className="flex flex-row place-items-center justify-between">
            <img
              className=" m-1"
              width="24"
              src={process.env.PUBLIC_URL + "/img/coins/ccoin.png"}
            />
            <AnimatedNumber
              className="text-base font-medium"
              value={erc1155balance}
              formatValue={formatValue}
              duration="600"
            />

            <div className="col-span-3">
              <input
                id="erc1155Withdraw"
                type="text"
                keyboardtype="decimal-pad"
                pattern="[0-9]*"
                step="1"
                autoComplete="off"
                className="rounded-lg p-1 text-sm font-bold active:outline-none focus:outline-none text-center"
              />

              {!!account && !!erc1155Owner ? (
                erc1155Owner.toLowerCase() == account.toLowerCase() ? (
                  <button
                    className=" m-1 w-16 bg-[#474747] rounded-lg p-1 text-xs text-white transition-all hover:bg-[#ffae00]"
                    onClick={() => {
                      if (
                        parseInt(
                          document.getElementById("erc1155Withdraw").value
                        ) > 0
                      ) {
                        withdrawToken1155Stake(
                          CCOIN,
                          document.getElementById("erc1155Withdraw").value
                        );
                      } else {
                        console.log("COIN STAKE : Invalid value");
                        document.getElementById("erc1155Withdraw").value =
                          "Invalid value";
                      }
                    }}
                  >
                    Withdraw
                  </button>
                ) : (
                  <button className=" m-1 w-16 bg-[#9c9c9c] rounded-lg p-1 text-xs text-white transition-all cursor-auto">
                    Withdraw
                  </button>
                )
              ) : (
                <button className=" m-1 w-16 bg-[#9c9c9c] rounded-lg p-1 text-xs text-white transition-all cursor-auto">
                  Withdraw
                </button>
              )}
            </div>
          </span>
          {/*//////////////////////// Item left /////////////////////////////*/}
          <span className="text-xs font-medium mt-1">NFT Count</span>
          <div className="flex flex-row items-center col-span-3 justify-between text-xs">
            <div className="w-full items-center grid grid-cols-5 grid-flow-row justify-between text-xs">
              {itemLeft.slice(0, 5).map((x, i) => (
                <div
                  key={i}
                  className="flex flex-col justify-center items-center content-center col-span-1 bg-black/5 rounded-lg m-1 p-1"
                >
                  <span>{x.name}</span>
                  {parseInt(x.inStake) + parseInt(x.totalSupply)}/
                  {parseInt(x.cardId) <= 4
                    ? "30"
                    : parseInt(x.cardId) > 4 && parseInt(x.cardId) < 7
                    ? "130"
                    : "300"}
                </div>
              ))}

              {itemLeft.slice(5, 10).map((x, i) => (
                <div
                  key={i + 20}
                  className="flex flex-col justify-center items-center col-span-1 bg-black/5 rounded-lg m-1 p-1"
                >
                  <span>{x.name}</span>
                  {parseInt(x.inStake) + parseInt(x.totalSupply)}/
                  {parseInt(x.cardId) < 4
                    ? "30"
                    : parseInt(x.cardId) > 4 && parseInt(x.cardId) < 7
                    ? "130"
                    : "300"}
                </div>
              ))}
            </div>
          </div>
          {/*////////////// MINT 1155 NFT ////////////////// */}
          <div className="flex flex-row items-center col-span-3 justify-between">
            <span>Mint </span>

            <Select
              defaultValue={erc1155options[0]}
              options={erc1155options}
              className="w-1/2 md:w-1/3 m-2 z-50"
              onChange={onSelect}
            />

            <span> To Address </span>
            <div>
              <input
                id="erc1155reciver"
                type="text"
                keyboardtype="decimal-pad"
                pattern="[0-9]*"
                step="1"
                autoComplete="off"
                className="rounded-lg p-1 text-sm font-bold active:outline-none focus:outline-none text-center"
              />

              {!!account && !!erc1155Admin ? (
                erc1155Admin.toLowerCase() == account.toLowerCase() ? (
                  <button
                    className=" m-1 w-16 bg-[#474747] rounded-lg p-1 text-xs text-white transition-all hover:bg-[#ffae00]"
                    onClick={() => {
                      if (
                        ethers.utils.isAddress(
                          document.getElementById("erc1155reciver").value
                        ) &&
                        document.getElementById("erc1155reciver").value !== null
                      ) {
                        document.getElementById(
                          "erc1155reciver"
                        ).disabled = true;
                        console.log("nft1155Id:", nft1155Id);
                        mint1155(
                          document.getElementById("erc1155reciver").value,
                          [nft1155Id],
                          [1]
                        );
                      } else {
                        console.log("COIN STAKE : Invalid value");
                        document.getElementById("erc1155reciver").value =
                          "Invalid address";
                      }
                    }}
                  >
                    Mint
                  </button>
                ) : (
                  <button className=" m-1 w-16 bg-[#9c9c9c] rounded-lg p-1 text-xs text-white transition-all cursor-auto">
                    Mint
                  </button>
                )
              ) : (
                <button className=" m-1 w-16 bg-[#9c9c9c] rounded-lg p-1 text-xs text-white transition-all cursor-auto">
                  Mint
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {/*-------------------------------------------------------------- E R C   7 2 1 --------------------------------------------------------------*/}
      <div className="mt-10 md:w-3/5 w-full p-5 flex flex-col justifyitem-center mb-10 items-center drop-shadow-lg rounded-xl text-black bg-neutral-100 bg-opacity-70 backdrop-blur-xl -z-10">
        <span className="text-blue-400 font-bold text-sm my-1">
          <br />
        </span>
        <div className=" blur-sm absolute -top-4 right-4 text-3xl md:text-4xl self-center -mb-3 font-bold text-yellow-700 break-all">
          E g g H a t c h - A d m i n
        </div>
        <div className=" absolute -top-5 right-5 text-3xl md:text-4xl self-center -mb-3 font-bold text-white break-all">
          E g g H a t c h - A d m i n
        </div>

        <div className="col-span-2 justify-self-center text-mg text-grey-500 flex flex-col border rounded-md w-full p-2 border-[#4b4314]">
          <div className="w-full justify-center place-items-centertext-mg text-gray-600 flex flex-row rounded-md p-1 bg-white/5">
            {NFT721STAKE}

            <AiOutlineCopy
              className="text-lg transition-all hover:text-[#ffae00] ml-2 cursor-pointer"
              onClick={() => navigator.clipboard.writeText(NFT721STAKE)}
            />
          </div>
          <div className=" justify-center place-items-center m-1 grid grid-flow-row grid-cols-3">
            <span className="text-xs font-medium col-span-3">
              Contract CCOIN Balance
            </span>
            <AnimatedNumber
              className="text-2xl font-medium  col-span-3 "
              value={erc721CCOINBalance}
              formatValue={formatValue}
              duration="600"
            />
            <div className="col-span-3">
              <input
                id="eggHatchWithdraw"
                type="text"
                keyboardtype="decimal-pad"
                pattern="[0-9]*"
                step="1"
                autoComplete="off"
                className="rounded-lg p-1 text-sm font-bold active:outline-none focus:outline-none text-right"
              />

              {!!account && !!erc721Owner ? (
                erc721Owner.toLowerCase() == account.toLowerCase() ? (
                  <button
                    className=" m-1 w-16 bg-[#474747] rounded-lg p-1 text-xs text-white transition-all hover:bg-[#ffae00]"
                    onClick={() => {
                      if (
                        parseInt(
                          document.getElementById("eggHatchWithdraw").value
                        ) > 0
                      ) {
                        withdrawToken721Stake(
                          CCOIN,
                          document.getElementById("eggHatchWithdraw").value
                        );
                      } else {
                        console.log("COIN STAKE : Invalid value");
                        document.getElementById("eggHatchWithdraw").value =
                          "Invalid value";
                      }
                    }}
                  >
                    Withdraw
                  </button>
                ) : (
                  <button className=" m-1 w-16 bg-[#9c9c9c] rounded-lg p-1 text-xs text-white transition-all ">
                    Withdraw
                  </button>
                )
              ) : (
                <button className=" m-1 w-16 bg-[#9c9c9c] rounded-lg p-1 text-xs text-white transition-all ">
                  Withdraw
                </button>
              )}
            </div>
          </div>
          <div className="border rounded-md border-[#4b4314]/50 col-span-2 w-full">
            <span className="flex flex-row place-items-center justify-between">
              <div />
              <span className="text-xs font-bold mt-1 px-2">Balance</span>
            </span>
            <span className="flex flex-row place-items-center justify-between px-2">
              <span className="flex flex-row items-center font-bold">
                <img
                  className="m-1 "
                  width="24"
                  src={process.env.PUBLIC_URL + "/img/coins/ccoin.png"}
                />
                CCOIN
              </span>
              <AnimatedNumber
                className="text-base font-medium"
                value={erc721CCOINBalance}
                formatValue={formatValue}
                duration="600"
              />
            </span>
            <span className="flex flex-row place-items-center justify-between px-2 col-span-2">
              <span className="flex flex-row items-center font-bold">
                <img
                  className=" m-1"
                  width="24"
                  src={process.env.PUBLIC_URL + "/img/coins/ccsx.png"}
                />
                CCSX
              </span>
              <AnimatedNumber
                className="text-base font-medium"
                value={erc721CCSXBalance}
                formatValue={formatValue}
                duration="600"
              />
            </span>
          </div>

          <div className="border rounded-md border-[#4b4314]/50 p-2 mt-2 col-span-1 flex flex-row justify-between">
            Total Eggs Minted
            <AnimatedNumber
              className="text-base font-medium"
              value={eggTotalSupply}
              formatValue={formatValue}
              duration="600"
            />
          </div>
          {/*/////////// M i n t   7 2 1 /////////////// */}
          <div className="w-full items-center grid grid-cols-6 grid-flow-row justify-between text-xs mt-2">
            <div
              className={`flex flex-col text-md justify-center items-center content-center col-span-1 rounded-lg m-1 p-1 transition-all select-text ${
                erc721idnum >= 581 && erc721idnum <= 730
                  ? "bg-[#ffae00]/90 drop-shadow-lg"
                  : "bg-black/5 opacity-80"
              }`}
            >
              <img
                className="m-1 "
                width="56"
                src={`https://nft-assets.crypterium.game/pets/k/0/PHASE${
                  erc721isHatched ? "6" : "3"
                }.gif`}
              />

              <span>Little Dragon</span>
              <span>581-730</span>
            </div>

            <div
              className={`flex flex-col text-md justify-center items-center content-center col-span-1 rounded-lg m-1 p-1 transition-all select-text ${
                erc721idnum >= 731 && erc721idnum <= 980
                  ? "bg-[#ffae00]/90 drop-shadow-lg"
                  : "bg-black/5 opacity-80"
              }`}
            >
              <img
                className="m-1 "
                width="56"
                src={`https://nft-assets.crypterium.game/pets/k/1/PHASE${
                  erc721isHatched ? "6" : "3"
                }.gif`}
              />

              <span>Black Wild Cat</span>
              <span>731-980</span>
            </div>

            <div
              className={`flex flex-col text-md justify-center items-center content-center col-span-1 rounded-lg m-1 p-1 transition-all select-text ${
                erc721idnum >= 981 && erc721idnum <= 1230
                  ? "bg-[#ffae00]/90 drop-shadow-lg"
                  : "bg-black/5 opacity-80"
              }`}
            >
              <img
                className="m-1 "
                width="56"
                src={`https://nft-assets.crypterium.game/pets/k/2/PHASE${
                  erc721isHatched ? "6" : "3"
                }.gif`}
              />

              <span>Red Sky Egle</span>
              <span>981-1230</span>
            </div>

            <div
              className={`flex flex-col text-md justify-center items-center content-center col-span-1 rounded-lg m-1 p-1 transition-all select-text ${
                erc721idnum >= 1231 && erc721idnum <= 1430
                  ? "bg-[#ffae00]/90 drop-shadow-lg"
                  : "bg-black/5 opacity-80"
              }`}
            >
              <img
                className="m-1 "
                width="56"
                src={`https://nft-assets.crypterium.game/pets/k/3/PHASE${
                  erc721isHatched ? "6" : "3"
                }.gif`}
              />

              <span>Silver Tiger</span>
              <span>1231-1430</span>
            </div>

            <div
              className={`flex flex-col text-md justify-center items-center content-center col-span-1 rounded-lg m-1 p-1 transition-all select-text ${
                erc721idnum >= 1431 && erc721idnum <= 1680
                  ? "bg-[#ffae00]/90 drop-shadow-lg"
                  : "bg-black/5 opacity-80"
              }`}
            >
              <img
                className="m-1 "
                width="56"
                src={`https://nft-assets.crypterium.game/pets/k/4/PHASE${
                  erc721isHatched ? "6" : "3"
                }.gif`}
              />

              <span>Great Horn Owl</span>
              <span>1431-1680</span>
            </div>

            <div
              className={`flex flex-col text-md justify-center items-center content-center col-span-1 rounded-lg m-1 p-1 transition-all select-text ${
                erc721idnum >= 1681 && erc721idnum <= 1760
                  ? "bg-[#ffae00]/90 drop-shadow-lg"
                  : "bg-black/5 opacity-80"
              }`}
            >
              <img
                className="m-1 "
                width="56"
                src={`https://nft-assets.crypterium.game/pets/k/5/PHASE${
                  erc721isHatched ? "6" : "3"
                }.gif`}
              />

              <span>Devil Jr.</span>
              <span>1681-1760</span>
            </div>
          </div>
          <div className="flex flex-row items-center col-span-3 justify-between mt-2">
            <span>Mint</span>
            <div
              onClick={() => setErc721isHatched(!erc721isHatched)}
              className=" bg-[#474747]/50 rounded-md text-white px-2 cursor-pointer transition-all hover:bg-[#ffae00] drop-shadow-lg hover:drop-shadow-sm"
            >
              {erc721isHatched ? "Pet" : "Egg"}
            </div>
            <span className="">ID:</span>
            <input
              id="erc721id"
              type="text"
              keyboardtype="decimal-pad"
              pattern="[0-9]*"
              step="1"
              autoComplete="off"
              className="rounded-lg p-1 text-sm font-bold active:outline-none focus:outline-none text-center"
              onChange={() =>
                setErc721idnum(
                  parseInt(document.getElementById("erc721id").value)
                )
              }
            />

            <span> To Address </span>
            <div>
              <input
                id="erc721reciver"
                type="text"
                keyboardtype="decimal-pad"
                pattern="[0-9]*"
                step="1"
                autoComplete="off"
                className="rounded-lg p-1 text-sm font-bold active:outline-none focus:outline-none text-center"
              />

              {!!account && !!erc721Admin ? (
                erc721Admin.toLowerCase() == account.toLowerCase() ? (
                  <button
                    className=" m-1 w-16 bg-[#474747] rounded-lg p-1 text-xs text-white transition-all hover:bg-[#ffae00]"
                    onClick={() => {
                      if (
                        ethers.utils.isAddress(
                          document.getElementById("erc721reciver").value
                        ) &&
                        document.getElementById("erc721reciver").value !== null
                      ) {
                        document.getElementById(
                          "erc721reciver"
                        ).disabled = true;
                        document.getElementById("erc721id").disabled = true;
                        /**mint function  */
                        mint721(
                          document.getElementById("erc721id").value,
                          document.getElementById("erc721reciver").value,
                          erc721isHatched
                        );
                      } else {
                        document.getElementById("erc721reciver").value =
                          "Invalid address";
                      }
                    }}
                  >
                    Mint
                  </button>
                ) : (
                  <button className=" m-1 w-16 bg-[#9c9c9c] rounded-lg p-1 text-xs text-white transition-all cursor-auto">
                    Mint
                  </button>
                )
              ) : (
                <button className=" m-1 w-16 bg-[#9c9c9c] rounded-lg p-1 text-xs text-white transition-all cursor-auto">
                  Mint
                </button>
              )}
            </div>
          </div>
          <div className="border rounded-md border-[#4b4314]/50 p-2 mt-2 col-span-1 flex flex-row justify-between items-center">
            View Hatch of
            <input
              id="viewHatchAddress"
              type="text"
              className="rounded-lg p-1 text-sm font-bold active:outline-none focus:outline-none text-center w-3/5"
            />
            <button
              className=" m-1 w-16 bg-[#474747] rounded-lg p-1 text-xs text-white transition-all hover:bg-[#ffae00]"
              onClick={() => {
                if (
                  ethers.utils.isAddress(
                    document.getElementById("viewHatchAddress").value
                  ) &&
                  document.getElementById("erc1155reciver").value !== null
                ) {
                  window
                    .open(
                      "https://pet.crypterium.game/PreviewEggs?addr=" +
                        document.getElementById("viewHatchAddress").value,
                      "_blank"
                    )
                    .focus();
                } else {
                  console.log("COIN STAKE : Invalid value");
                  document.getElementById("viewHatchAddress").value =
                    "Invalid address";
                }
              }}
            >
              View
            </button>
          </div>
        </div>
      </div>

      {/*----------------------------------------------------------------------------------------------------------------------------*/}
      {/*--------------------------------------------------------------C O I N S t a k e - A d m i n--------------------------------------------------------------*/}
      <div className="mt-10 md:w-3/5 w-full p-5 flex flex-col justifyitem-center mb-10 items-center drop-shadow-lg rounded-xl text-black bg-neutral-100 bg-opacity-70 backdrop-blur-xl -z-10">
        <span className="text-blue-400 font-bold text-sm my-1">
          <br />
        </span>
        <div className=" blur-sm absolute -top-4 right-4 text-3xl md:text-4xl self-center -mb-3 font-bold text-yellow-700 break-all">
          C o i n S t a k e - A d m i n
        </div>
        <div className=" absolute -top-5 right-5 text-3xl md:text-4xl self-center -mb-3 font-bold text-white break-all">
          C o i n S t a k e - A d m i n
        </div>

        <div className="col-span-2 justify-self-center text-mg text-grey-500 flex flex-col border rounded-md w-full p-2 border-[#4b4314]">
          <div className="w-full justify-center place-items-centertext-mg text-gray-600 flex flex-row rounded-md p-1 bg-white/5">
            {CSTAKE}

            <AiOutlineCopy
              className="text-lg transition-all hover:text-[#ffae00] ml-2 cursor-pointer"
              onClick={() => navigator.clipboard.writeText(CSTAKE)}
            />
          </div>
          <div className=" justify-center place-items-center m-1 grid grid-flow-row grid-cols-3">
            <span className="text-xs font-medium col-span-3">
              Contract CCOIN Balance
            </span>
            <AnimatedNumber
              className="text-2xl font-medium  col-span-3 "
              value={cStakeBalance}
              formatValue={formatValue}
              duration="600"
            />
            <div className="col-span-3">
              <input
                id="cctakeWithdraw"
                type="text"
                keyboardtype="decimal-pad"
                pattern="[0-9]*"
                step="1"
                autoComplete="off"
                className="rounded-lg p-1 text-sm font-bold active:outline-none focus:outline-none text-right"
              />

              {!!account && !!coinStakeOwner ? (
                coinStakeOwner.toLowerCase() == account.toLowerCase() ? (
                  <button
                    className=" m-1 w-16 bg-[#474747] rounded-lg p-1 text-xs text-white transition-all hover:bg-[#ffae00]"
                    onClick={() => {
                      if (
                        parseInt(
                          document.getElementById("cctakeWithdraw").value
                        ) > 0
                      ) {
                        withdrawTokenCStake(
                          CCOIN,
                          document.getElementById("cctakeWithdraw").value
                        );
                      } else {
                        console.log("COIN STAKE : Invalid value");
                        document.getElementById("cctakeWithdraw").value =
                          "Invalid value";
                      }
                    }}
                  >
                    Withdraw
                  </button>
                ) : (
                  <button className=" m-1 w-16 bg-[#9c9c9c] rounded-lg p-1 text-xs text-white transition-all ">
                    Withdraw
                  </button>
                )
              ) : (
                <button className=" m-1 w-16 bg-[#9c9c9c] rounded-lg p-1 text-xs text-white transition-all ">
                  Withdraw
                </button>
              )}
            </div>
          </div>
          <div className="border rounded-md border-[#4b4314]/50 col-span-2 w-full">
            <span className="flex flex-row place-items-center justify-between">
              <div />
              <span className="text-xs font-bold mt-1 px-2">Balance</span>
            </span>
            <span className="flex flex-row place-items-center justify-between px-2">
              <span className="flex flex-row items-center font-bold">
                <img
                  className="m-1 "
                  width="24"
                  src={process.env.PUBLIC_URL + "/img/coins/ccoin.png"}
                />
                CCOIN
              </span>
              <AnimatedNumber
                className="text-base font-medium"
                value={totalInStake / 1.05 - CCSXSTAKE - 10000}
                formatValue={formatValue}
                duration="600"
              />
            </span>
            <span className="flex flex-row place-items-center justify-between px-2 col-span-2">
              <span className="flex flex-row items-center font-bold">
                <img
                  className=" m-1"
                  width="24"
                  src={process.env.PUBLIC_URL + "/img/coins/ccsx.png"}
                />
                CCSX
              </span>
              <AnimatedNumber
                className="text-base font-medium"
                value={CCSXSTAKE - 10000}
                formatValue={formatValue}
                duration="600"
              />
            </span>
          </div>

          <div className="border rounded-md border-[#4b4314]/50 p-2 mt-2 col-span-1">
            <span className="text-xs font-medium mt-1 ">Total CCOIN stake</span>

            <span className={`w-1/3 flex flex-row place-items-center `}>
              <div />
              <AnimatedNumber
                className="text-base font-medium"
                value={totalInStake - 21000}
                formatValue={formatValue}
                duration="600"
              />
            </span>
            <span className="text-xs font-medium mt-1 ">CCOIN Balance</span>
            <div
              className={`w-1/3 flex flex-row place-items-center ${
                CCOINSTAKE >= totalInStake - 21000
                  ? "text-blue-500"
                  : "text-red-500"
              }`}
            >
              {formatValue(CCOINSTAKE - totalInStake + 21000)}{" "}
            </div>
          </div>
        </div>
      </div>

      {/*----------------------------------------------------------------------------------------------------------------------------*/}
      <div className="mt-10 md:w-3/5 w-full p-5 flex flex-col justifyitem-center mb-10 items-center drop-shadow-lg rounded-xl text-black bg-neutral-100 bg-opacity-70 backdrop-blur-xl">
        <span className="text-blue-400 font-bold text-sm my-1">
          <br />
        </span>
        <div className=" blur-sm absolute -top-4 right-4 text-3xl md:text-4xl self-center -mb-3 font-bold text-yellow-700 break-all">
          A u t o P a y m e n t - A d m i n
        </div>
        <div className=" absolute -top-5 right-5 text-3xl md:text-4xl self-center -mb-3 font-bold text-white break-all">
          A u t o P a y m e n t - A d m i n
        </div>

        <div className="col-span-2 justify-self-center text-mg text-grey-500 flex flex-col border rounded-md w-full p-2 border-[#4b4314] mt-2">
          <div className="w-full justify-center place-items-center text-mg text-gray-600 flex flex-row rounded-md p-1 bg-white/5">
            {AUTOPAYMENT}

            <AiOutlineCopy
              className="text-lg transition-all hover:text-[#ffae00] ml-2 cursor-pointer"
              onClick={() => navigator.clipboard.writeText(AUTOPAYMENT)}
            />
          </div>
          <span className="text-xs font-medium mt-1">Balance</span>
          {/*// CCOIN //*/}
          <span className="flex flex-row place-items-center justify-between">
            <img
              className=" m-1"
              width="24"
              src={process.env.PUBLIC_URL + "/img/coins/ccoin.png"}
            />
            <AnimatedNumber
              className="text-base font-medium"
              value={autoPayCCOIN}
              formatValue={formatValue}
              duration="600"
            />

            <div className="col-span-3">
              <input
                id="autoPayCCOINWithdraw"
                type="text"
                keyboardtype="decimal-pad"
                pattern="[0-9]*"
                step="1"
                autoComplete="off"
                className="rounded-lg p-1 text-sm font-bold active:outline-none focus:outline-none text-center"
              />

              {!!account && !!autoPayAdmin ? (
                autoPayAdmin.toLowerCase() == account.toLowerCase() ? (
                  <button
                    className=" m-1 w-16 bg-[#474747] rounded-lg p-1 text-xs text-white transition-all hover:bg-[#ffae00]"
                    onClick={() => {
                      if (
                        parseInt(
                          document.getElementById("autoPayCCOINWithdraw").value
                        ) > 0
                      ) {
                        withdrawTokenAutoPayment(
                          CCOIN,
                          document.getElementById("autoPayCCOINWithdraw").value
                        );
                      } else {
                        console.log("COIN AUTOPAY : Invalid value");
                        document.getElementById("autoPayCCOINWithdraw").value =
                          "Invalid value";
                      }
                    }}
                  >
                    Withdraw
                  </button>
                ) : (
                  <button className=" m-1 w-16 bg-[#9c9c9c] rounded-lg p-1 text-xs text-white transition-all cursor-auto">
                    Withdraw
                  </button>
                )
              ) : (
                <button className=" m-1 w-16 bg-[#9c9c9c] rounded-lg p-1 text-xs text-white transition-all cursor-auto">
                  Withdraw
                </button>
              )}
            </div>
          </span>

          {/*// BUSD //*/}
          <span className="flex flex-row place-items-center justify-between">
            <img
              className=" m-1"
              width="24"
              src={process.env.PUBLIC_URL + "/img/coins/busd.png"}
            />
            <AnimatedNumber
              className="text-base font-medium"
              value={autoPayBUSD}
              formatValue={formatValue}
              duration="600"
            />

            <div className="col-span-3">
              <input
                id="autoPayBUSDWithdraw"
                type="text"
                keyboardtype="decimal-pad"
                pattern="[0-9]*"
                step="1"
                autoComplete="off"
                className="rounded-lg p-1 text-sm font-bold active:outline-none focus:outline-none text-center"
              />

              {!!account && !!autoPayAdmin ? (
                autoPayAdmin.toLowerCase() == account.toLowerCase() ? (
                  <button
                    className=" m-1 w-16 bg-[#474747] rounded-lg p-1 text-xs text-white transition-all hover:bg-[#ffae00]"
                    onClick={() => {
                      if (
                        parseInt(
                          document.getElementById("autoPayBUSDWithdraw").value
                        ) > 0
                      ) {
                        withdrawTokenAutoPayment(
                          busd,
                          document.getElementById("autoPayBUSDWithdraw").value
                        );
                      } else {
                        console.log("BUSD AUTOPAY : Invalid value");
                        document.getElementById("autoPayBUSDWithdraw").value =
                          "Invalid value";
                      }
                    }}
                  >
                    Withdraw
                  </button>
                ) : (
                  <button className=" m-1 w-16 bg-[#9c9c9c] rounded-lg p-1 text-xs text-white transition-all cursor-auto">
                    Withdraw
                  </button>
                )
              ) : (
                <button className=" m-1 w-16 bg-[#9c9c9c] rounded-lg p-1 text-xs text-white transition-all cursor-auto">
                  Withdraw
                </button>
              )}
            </div>
          </span>
        </div>
      </div>

      {/*----------------------------------------------------------------------------------------------------------------------------*/}

      <div className="mt-10 md:w-3/5 w-full p-5 flex flex-col justifyitem-center mb-10 items-center drop-shadow-lg rounded-xl text-black bg-neutral-100 bg-opacity-70 backdrop-blur-xl">
        <span className="text-blue-400 font-bold text-sm my-1">
          <br />
        </span>
        <div className=" blur-sm absolute -top-4 right-4 text-3xl md:text-4xl self-center -mb-3 font-bold text-yellow-700 break-all">
          N/A
        </div>
        <div className=" absolute -top-5 right-5 text-3xl md:text-4xl self-center -mb-3 font-bold text-white break-all">
          N/A
        </div>
      </div>
      {/*----------------------------------------------------------------------------------------------------------------------------*/}
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={open}
        className="flex flex-col "
      >
        <div
          className={` bg-white text-gray-500 grid grid-cols-8 gap-2 grid-flow-row m-4 items-center text-center  text-lg p-3 rounded-lg md:w-3/5 w-full display-linebreak
                        
                    `}
        >
          <PackageEdit
            num="1"
            ccoin={package1[1]}
            avaliable={package1[0]}
            expire={package1[2]}
            active={package1[3]}
          />
          <PackageEdit
            num="2"
            ccoin={package2[1]}
            avaliable={package2[0]}
            expire={package2[2]}
            active={package2[3]}
          />
          <PackageEdit
            num="3"
            ccoin={package3[1]}
            avaliable={package3[0]}
            expire={package3[2]}
            active={package3[3]}
          />
          <PackageEdit
            num="4"
            ccoin={package4[1]}
            avaliable={package4[0]}
            expire={package4[2]}
            active={package4[3]}
          />
          <div className="col-span-8 justify-end text-mg text-grey-500 flex flex-row border rounded-md w-full p-2 border-gray-300">
            <button
              className=" m-1 w-32 bg-gray-500 rounded-lg p-2 text-xs text-white transition-all hover:bg-[#ffae00]"
              onClick={() => {
                addPackages();
              }}
            >
              Save
            </button>
            <button
              className=" m-1 w-32 bg-gray-500 rounded-lg p-2 text-xs text-white transition-all hover:bg-[#ff0000]"
              onClick={() => {
                handleClose();
                const clearCcoin = document.getElementById("ccoin");
                clearCcoin.value = "";
                const clearAvaliable = document.getElementById("avaliable");
                clearAvaliable.value = "";
              }}
            >
              Close
            </button>
          </div>
        </div>
        <br />
      </Backdrop>
    </div>
  );
}

export default Pool;
