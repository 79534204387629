const abi = [
    {
        constant: true,
        inputs: [],
        name: 'receiverAddress',
        outputs: [
            {
                name: '',
                type: 'address',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                name: '_tokenAddress',
                type: 'address',
            },
            {
                name: '_to',
                type: 'address[]',
            },
            {
                name: '_value',
                type: 'uint256',
            },
        ],
        name: 'drop',
        outputs: [],
        payable: true,
        stateMutability: 'payable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                name: '_tokenAddress',
                type: 'address',
            },
            {
                name: '_to',
                type: 'address[]',
            },
            {
                name: '_value',
                type: 'uint256[]',
            },
        ],
        name: 'bulksendToken',
        outputs: [],
        payable: true,
        stateMutability: 'payable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                name: '_to',
                type: 'address[]',
            },
            {
                name: '_value',
                type: 'uint256',
            },
        ],
        name: 'bulkSendETHWithSameValue',
        outputs: [],
        payable: true,
        stateMutability: 'payable',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'getReceiverAddress',
        outputs: [
            {
                name: '',
                type: 'address',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                name: '_to',
                type: 'address[]',
            },
            {
                name: '_value',
                type: 'uint256[]',
            },
        ],
        name: 'bulksend',
        outputs: [],
        payable: true,
        stateMutability: 'payable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                name: '_tokenAddress',
                type: 'address',
            },
            {
                name: '_to',
                type: 'address[]',
            },
            {
                name: '_value',
                type: 'uint256[]',
            },
        ],
        name: 'bulkSendCoinWithDifferentValue',
        outputs: [],
        payable: true,
        stateMutability: 'payable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                name: '_to',
                type: 'address[]',
            },
            {
                name: '_value',
                type: 'uint256[]',
            },
        ],
        name: 'bulkSendETHWithDifferentValue',
        outputs: [],
        payable: true,
        stateMutability: 'payable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                name: '_addr',
                type: 'address',
            },
        ],
        name: 'setReceiverAddress',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'owner',
        outputs: [
            {
                name: '',
                type: 'address',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                name: '_tokenAddress',
                type: 'address',
            },
            {
                name: '_to',
                type: 'address[]',
            },
            {
                name: '_value',
                type: 'uint256',
            },
        ],
        name: 'bulkSendCoinWithSameValue',
        outputs: [],
        payable: true,
        stateMutability: 'payable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                name: 'newOwner',
                type: 'address',
            },
        ],
        name: 'transferOwnership',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                name: '_tokenAddress',
                type: 'address',
            },
        ],
        name: 'getBalance',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                name: '_to',
                type: 'address[]',
            },
            {
                name: '_value',
                type: 'uint256',
            },
        ],
        name: 'sendEth',
        outputs: [],
        payable: true,
        stateMutability: 'payable',
        type: 'function',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                name: 'token',
                type: 'address',
            },
            {
                indexed: false,
                name: 'total',
                type: 'uint256',
            },
        ],
        name: 'LogTokenBulkSent',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                name: 'token',
                type: 'address',
            },
            {
                indexed: false,
                name: 'receiver',
                type: 'address',
            },
            {
                indexed: false,
                name: 'balance',
                type: 'uint256',
            },
        ],
        name: 'LogGetToken',
        type: 'event',
    },
]
module.exports = abi
