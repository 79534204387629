import React, { useState, useEffect } from 'react'
import { ethers } from 'ethers'
import { useSelector } from 'react-redux'
import { FiRefreshCcw } from 'react-icons/fi'
import AnimatedNumber from 'animated-number-react'
import { BiLoaderAlt } from 'react-icons/bi'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { TiLockClosedOutline, TiLockOpenOutline } from 'react-icons/ti'
import Countdown from 'react-countdown'
import {
    FaWindowClose,
    FaChevronDown,
    FaRegHandPointDown,
} from 'react-icons/fa'
import { AiOutlineLoading3Quarters, AiOutlineCheck } from 'react-icons/ai'

import StepLine from './Stepline'
import { trueDependencies } from 'mathjs'
import AddNetwork from './AddNetwork'
/* global BigInt */
const POOL = process.env.REACT_APP_CSTAKE
const CCOIN = process.env.REACT_APP_CCOIN
const CCSX = process.env.REACT_APP_CCSX

/*
const POOL = '0xE7aAda0d0Fcf7b69244dEB6C50DEeff76e23509C'
const CCOIN = '0xbFB0be698655eCdEb6dDe80831dfb3C6553c4C6D' //BSC Testnet
const CCSX = '0x0D82Bdd0Db436dBc6473B58f0B55d9854cAE3F87' //BSC Testnet

const POOL = '0x805cC3dA74d12a585e4D069Bb4bF22ED5562d8A6' //BSC Mainnet
const CCOIN = '0xc209831f7349D4E200d420326B3401899Ab9Ae68' //BSC Mainnet
const CCSX = '0x1819615B0643c54A6096af42a72C761f85E7ec37' //BSC Mainnet
*/
const cstakeabi = require('../abi/cstakeabi')
const IERC20 = require('../abi/IERC20')
const formatValue = (value) => `${separator(Number(value).toFixed(1))}`
function separator(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const isMetaMaskInstalled = () => {
    const { ethereum } = window
    return Boolean(ethereum && ethereum.isMetaMask)
}

function dynamicSort(property) {
    var sortOrder = 1
    if (property[0] === '-') {
        sortOrder = -1
        property = property.substr(1)
    }
    return function (a, b) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        var result =
            a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
        return result * sortOrder
    }
}

function AddrList({
    unlockTime,
    amount,
    currentTimeStamp,
    isClaimed,
    viewDate,
}) {
    //console.log('currentTimeStamp', currentTimeStamp)
    let str = 'col-span-2 px-3 text-center '
    return (
        <div className="w-full grid grid-cols-3 md:grid-cols-7 gap-3 md:gap-3 grid-flow-row text-gray-700 text-xm border-b py-1 items-center select-none">
            <div className="col-span-3 md:col-span-2 px-3 text-center font-bold md:font-normal md:text-center text-xs">
                {viewDate ? (
                    <Countdown date={unlockTime * 1000} />
                ) : (
                    new Date(unlockTime * 1000).toLocaleString('en-US', {
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hourCycle: 'h24',
                    })
                )}
            </div>
            <div className="col-span-1 md:col-span-2 text-center w-100 text-gray-400">
                {unlockTime > currentTimeStamp ? (
                    separator(amount)
                ) : (
                    <div>-</div>
                )}
            </div>

            <div
                className={
                    isClaimed
                        ? 'col-span-1 md:col-span-2 px-3 text-center text-gray-400 line-through'
                        : 'col-span-1 md:col-span-2 px-3 text-center text-blue-500'
                }
            >
                {unlockTime < currentTimeStamp ? (
                    separator(amount)
                ) : (
                    <div>-</div>
                )}
            </div>
            <div className="col-span-1 md:col-span-1 px-3 text-center text-grey-300 text-xs">
                {unlockTime < currentTimeStamp ? (
                    isClaimed ? (
                        <div className="text-center text-gray-400 text-xs">
                            Claimed
                        </div>
                    ) : (
                        <div className="text-center text-blue-500 text-xs">
                            Unclaimed
                        </div>
                    )
                ) : (
                    <div />
                )}
            </div>
        </div>
    )
}

function MyTokens() {
    const [open, setOpen] = useState(false)
    const [totalUnlockToken, setTotalUnlockToken] = useState(0)
    const [totalLockToken, setTotalLockToken] = useState(0)
    const [toatlClaimAbleToken, setToatlClaimAbleToken] = useState(0)
    const [toatlClaimedToken, setToatlClaimedToken] = useState(0)
    const [error, setError] = useState()
    const [info, setInfo] = useState()
    const [account, setAccount] = useState()
    const [ledgers, setLedgers] = useState([])
    const [state, setState] = useState(0)
    const [viewDate, setViewDate] = useState(true)
    const [openStake, setOpenStake] = useState(false)
    const [reLoad, setReload] = useState(0)
    const [CCSXBalance, setCCSXBalance] = useState(0)
    const [CCOINBalance, setCCOINBalance] = useState(0)
    const [isCCOIN, setIsCCOIN] = useState(true)

    const wc = useSelector((state) => state.walletConnect.value)

    const handleClose = () => {
        setOpen(false)
    }
    const handleToggle = () => {
        setOpen(!open)
    }

    const currentTimeStamp = Math.floor(Date.now() / 1000)
    let addrsArr = []
    let amountsArr = []
    let timeStampsArr = []

    let provider
    let ethereum = window.ethereum
    let tempSigner

    if (!!wc.account) {
        console.log('WC!')
        ethereum = wc.provider
        provider = wc.web3Provider
        tempSigner = provider.getSigner()
        ethereum.on('accountsChanged', (accounts) => setReload(reLoad + 1))
    } else if (isMetaMaskInstalled()) {
        console.log('MT!')
        ethereum = window.ethereum
        provider = new ethers.providers.Web3Provider(ethereum)
        tempSigner = provider.getSigner()
        ethereum.on('accountsChanged', (accounts) => setReload(reLoad + 1))
    }

    useEffect(() => {
        if (!!wc.account || isMetaMaskInstalled()) {
            getTotalUnlockToken()
            getTotalLockToken()
            getClaimAbleTokens()
            getClaimedToken()
            getCurrentAccount()
            getCCSXBalance()
            getCCOINBalance()
            console.log('account:', account)
            setInfo('')
        }
        return () => {
            setLedgers([])
        }
    }, [state, reLoad, wc.account])

    async function getCCOINBalance() {
        try {
            const constract = new ethers.Contract(CCOIN, IERC20, tempSigner)
            const balance = await constract.callStatic.balanceOf(
                tempSigner.getAddress()
            )
            setCCOINBalance(parseInt(ethers.utils.formatEther(balance)))
        } catch (err) {
            console.log('err: ', err)
            setState(0)
        }
    }

    async function getCCSXBalance() {
        try {
            const constract = new ethers.Contract(CCSX, IERC20, tempSigner)
            const balance = await constract.callStatic.balanceOf(
                tempSigner.getAddress()
            )
            setCCSXBalance(parseInt(ethers.utils.formatEther(balance)))
        } catch (err) {
            console.log('err: ', err)
            setState(0)
        }
    }

    async function getCurrentAccount() {
        const accounts = await ethereum.request({
            method: 'eth_accounts',
        })
        setAccount(accounts[0])
        return accounts[0]
    }
    async function getBlockTimeStamp() {
        const smcPool = new ethers.Contract(POOL, cstakeabi, tempSigner)
        const blockTimeStamp = await smcPool.callStatic.getBlockTimeStamp()
        console.log('blockTimeStamp', blockTimeStamp.toString())
        return blockTimeStamp.toString()
    }

    async function signMgs() {
        const message = 'The Smallest Parts Are Every Where!'
        await tempSigner.signMessage(message).then((signature) => {
            console.log('Signature:', signature)
            // Recover Address from signature
            const result = ethers.utils.verifyMessage(message, signature)
            console.log('Recovered Address:', result)
        })
    }

    async function getTotalUnlockToken() {
        const smcPool = new ethers.Contract(POOL, cstakeabi, tempSigner)
        setTotalUnlockToken(
            ethers.utils.formatEther(
                await smcPool.callStatic.checkUnLockedTokens()
            )
        )
    }
    async function getTotalLockToken() {
        const smcPool = new ethers.Contract(POOL, cstakeabi, tempSigner)
        //const address = await tempSigner.getAddress()
        //const numOfLedger = await smcPool.callStatic.ledgerLength(account)
        setTotalLockToken(
            ethers.utils.formatEther(
                await smcPool.callStatic.checkLockedTokens()
            )
        )
    }

    async function getClaimedToken() {
        const smcPool = new ethers.Contract(POOL, cstakeabi, tempSigner)
        //const address = await tempSigner.getAddress()
        //const numOfLedger = await smcPool.callStatic.ledgerLength(account)
        setToatlClaimedToken(
            ethers.utils.formatEther(
                await smcPool.callStatic.checkClaimedTokens()
            )
        )
    }

    async function getClaimAbleTokens() {
        //console.log('setLedgersAfterReset', ledgers)
        const smcPool = new ethers.Contract(POOL, cstakeabi, tempSigner)
        const address = await tempSigner.getAddress()
        const numOfLedger = await smcPool.callStatic.ledgerLength(address)
        //console.log('tempSigner.getAddress()', await tempSigner.getAddress())

        console.log('numOfLedger', numOfLedger.toString())
        const checkClaimAbleTokens =
            await smcPool.callStatic.checkClaimAbleTokens()
        console.log(
            'checkClaimAbleTokens',
            ethers.utils.formatEther(checkClaimAbleTokens)
        )
        setToatlClaimAbleToken(ethers.utils.formatEther(checkClaimAbleTokens))
        let ledgerAr = []
        for (let i = 0; i < numOfLedger.toString(); i++) {
            ledgerAr.push(await smcPool.callStatic.ledgers(address, i))
        }

        ledgerAr.sort(dynamicSort('unlockTime'))
        setLedgers(ledgerAr)
    }

    async function claimStake() {
        const smcPool = new ethers.Contract(POOL, cstakeabi, tempSigner)
        console.log('tempSigner:', tempSigner)
        const tx = await smcPool.claimStake().catch((err) => {
            console.log(err)
            handleClose()
            //setError(err)
        })

        setInfo('Please wait for block confirmation.')
        const receipt = await tx.wait(1).then((tx) => {
            setState(0)
            setReload(reLoad + 1)
            handleClose()
        })
        setInfo('')
    }

    async function getCCSXApprove(_amount) {
        try {
            const constract = new ethers.Contract(
                isCCOIN ? CCOIN : CCSX,
                IERC20,
                tempSigner
            )
            const balance = await constract.callStatic.balanceOf(
                tempSigner.getAddress()
            )

            if (
                parseInt(ethers.utils.formatEther(balance)) >= parseInt(_amount)
            ) {
                const checkAllowance = await constract.callStatic.allowance(
                    account,
                    POOL
                )

                if (
                    parseInt(_amount) >
                    parseInt(ethers.utils.formatEther(checkAllowance))
                ) {
                    setInfo('Please approve your token')
                    const tx = await constract.approve(
                        POOL,
                        ethers.utils.parseUnits(String(_amount), 'ether')
                    )
                    setInfo('Waiting for block confirmation')
                    const receipt = await tx.wait(1).then((tx) => {
                        setState(3)
                        setInfo('Transfer fund to contract')
                    })
                } else {
                    setState(3)
                    setInfo('Transfer fund to contract')
                }
            } else {
                setInfo('')
                setError('Insufficient CCOIN Classic.')
                setState(1)
                document.getElementById('_ccsxAmt').disabled = false
                document.getElementById('_ccsxInputAmt').style.display = 'block'
            }
        } catch (err) {
            console.log('err: ', err)
            setState(0)
        }
    }

    async function Stake(_amount, _isCCOIN) {
        try {
            const constract = new ethers.Contract(POOL, cstakeabi, tempSigner)
            const tx = await constract.Stake(
                ethers.utils.parseEther(_amount),
                _isCCOIN
            )
            //setState(4)
            setInfo('Waiting for block confirmation')

            const receipt = await tx.wait(1).then((tx) => {
                setState(5)
                setInfo('Thank you, your stake is running a profit.')
            })
        } catch (err) {
            console.log('err: ', err)
            setState(0)
        }
    }

    function CoinSelected({ isCCOIN }) {
        if (isCCOIN) {
            return (
                <>
                    <span className="text-sm text-gray-700 italic "></span>
                    <div className="w-full flex flex-row justify-center py-2">
                        <div
                            className={`md:w-1/3 w-2/3 border-2 rounded-lg mx-2 p-2  transition-all border-yellow-400 bg-gray-100 drop-shadow-md scale-105
        `}
                        >
                            <span className="flex flex-row">
                                <img
                                    className="px-1"
                                    width={36}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/img/coins/ccoin.png'
                                    }
                                />
                                CCOIN
                            </span>
                            <span className="text-xs px-2 pt-2 flex flex-row justify-between">
                                <span className="flex flex-row justify-center">
                                    Your Balance :{' '}
                                </span>
                                {formatValue(CCOINBalance)}
                            </span>
                        </div>
                        <div
                            onClick={() => {
                                setIsCCOIN(false)
                                document.getElementById('_ccsxAmt').value = 0
                            }}
                            className={`md:w-1/3 w-1/2 border-2 cursor-pointer rounded-lg mx-2 p-2 transition-all opacity-25  md:block`}
                        >
                            <span className="flex flex-row">
                                <img
                                    className="px-1"
                                    width={36}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/img/coins/ccsx.png'
                                    }
                                />
                                CCSX
                            </span>
                            <span className="w-full text-xs p-2 flex flex-row justify-between">
                                <span className="flex flex-row justify-center">
                                    Balance:
                                </span>
                                {formatValue(CCSXBalance)}
                            </span>
                        </div>
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <span className="text-sm text-gray-700 italic "></span>
                    <div className="w-full flex flex-row justify-center py-2">
                        <div
                            onClick={() => {
                                setIsCCOIN(true)
                                document.getElementById('_ccsxAmt').value = 0
                            }}
                            className="md:w-1/3 w-1/2 border-2 rounded-lg mx-2 p-2 cursor-pointer transition-all  opacity-25  md:block"
                        >
                            <span className="flex flex-row">
                                <img
                                    className="px-1"
                                    width={36}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/img/coins/ccoin.png'
                                    }
                                />
                                CCOIN
                            </span>
                            <span className="text-xs p-2 flex flex-row justify-between">
                                <span className="flex flex-row justify-center">
                                    Balance:
                                </span>

                                {formatValue(CCOINBalance)}
                            </span>
                        </div>
                        <div className="md:w-1/3 w-2/3 border-2 rounded-lg mx-2 p-2 transition-all border-gray-400 bg-gray-100 drop-shadow-md scale-105">
                            <span className="flex flex-row">
                                <img
                                    className="px-1"
                                    width={36}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/img/coins/ccsx.png'
                                    }
                                />
                                CCSX
                            </span>
                            <span className="text-xs px-2 pt-2 flex flex-row justify-between">
                                <span className="flex flex-row justify-center">
                                    Your Balance :{' '}
                                </span>
                                {formatValue(CCSXBalance)}
                            </span>
                        </div>
                    </div>
                </>
            )
        }
    }

    return (
        <div className="flex flex-col w-full justifyitem-center items-center mt-20 select-none">
            <AddNetwork CCOIN={CCOIN} CCSX={CCSX} />
            <div className="md:w-3/5 w-full p-5 flex flex-col justifyitem-center items-center drop-shadow-lg rounded-xl text-black bg-neutral-100 bg-opacity-70 backdrop-blur-xl">
                <FiRefreshCcw
                    className="self-end text-gray-600 cursor-pointer"
                    onClick={() => setReload(reLoad + 1)}
                />
                <span className="text-blue-400 font-bold text-sm my-1">
                    {info}
                </span>
                <div className="text-sm md:text-xl self-center mb-3 font-bold text-gray-700 break-all">
                    {account}
                </div>
                <br />

                <FaRegHandPointDown
                    size={42}
                    className="animate-bounce -mb-5"
                />
                <div
                    onClick={() => {
                        setInfo('')
                        if (!!account) {
                            setOpenStake(trueDependencies)
                            setState(1)
                            setError('')
                            setInfo('Enter your amount')
                            document.getElementById('_ccsxAmt').disabled = false
                            document.getElementById('_ccsxAmt').value = ''
                            document.getElementById(
                                '_ccsxInput'
                            ).style.display = 'block'
                        } else {
                            setInfo('Please connect your wallet')
                        }
                    }}
                    className="hover:animate-pulse  text-sm md:text-3xl self-center my-5 p-2 border-2 border-[#4b4314] font-bold text-gray-700 cursor-pointer hover:bg-gray-700 hover:text-gray-100 rounded-md transition-all duration-200 drop-shadow-xl"
                >
                    Stake Now!
                </div>
                <div className="w-full mt-5 grid grid-cols-2 md:grid-cols-3 gap-1 grid-flow-row text-sm text-center text-gray-500 font-bold content-center ">
                    <div className="col-span-2 justify-self-center text-md md:text-2xl text-orange-600 flex flex-col border rounded-md w-full p-2 border-[#4b4314]">
                        <span className="text-xs font-medium -mt-1">
                            Total Owned
                        </span>
                        <AnimatedNumber
                            value={
                                parseInt(totalLockToken) +
                                parseInt(totalUnlockToken)
                            }
                            formatValue={formatValue}
                            duration="600"
                        />
                    </div>

                    <div className="col-span-1 row-span-1 justify-self-center text-md md:text-lg text-gray-400 flex flex-col rounded-md border w-full p-2 border-[#4b4314]">
                        <span className="text-xs font-medium -mt-1 ">
                            Claimed
                        </span>
                        <AnimatedNumber
                            className="line-through "
                            value={toatlClaimedToken}
                            formatValue={formatValue}
                            duration="600"
                        />
                    </div>

                    <div className="col-span-1 justify-self-center text-md md:text-lg text-gray-600 flex flex-col rounded-md border w-full p-2 border-[#4b4314]">
                        <span className="text-xs font-medium -mt-1">
                            Locked
                        </span>
                        <AnimatedNumber
                            value={totalLockToken}
                            formatValue={formatValue}
                            duration="600"
                        />
                    </div>
                    <div className="col-span-1 justify-self-center text-md md:text-lg text-green-600 flex flex-col rounded-md border w-full p-2 border-[#4b4314]">
                        <span className="text-xs font-medium -mt-1">
                            Unlocked
                        </span>
                        <AnimatedNumber
                            value={totalUnlockToken}
                            formatValue={formatValue}
                            duration="600"
                        />
                    </div>
                    <div className="col-span-1 row-span-1 justify-self-center text-md md:text-lg text-blue-400 flex flex-col rounded-md border w-full p-2 border-[#4b4314]">
                        <span className="text-xs font-medium -mt-1">
                            Unclaimed
                        </span>
                        <AnimatedNumber
                            value={toatlClaimAbleToken}
                            formatValue={formatValue}
                            duration="600"
                        />
                    </div>
                </div>
                <div className="w-full mt-5 grid grid-cols-3 md:grid-cols-7 gap-3 grid-flow-row border-b text-xs md:text-sm text-center text-gray-500 font-bold content-center border-[#4b4314] ">
                    <div
                        onClick={() => setViewDate(!viewDate)}
                        className="col-span-3 md:col-span-2 cursor-pointer select-none"
                    >
                        {viewDate ? 'Unlock Time' : 'Unlock Date'}
                    </div>
                    <div className="col-span-1 md:col-span-2 justify-self-center text-lg text-gray-400 ">
                        <TiLockClosedOutline />
                    </div>
                    <div className="col-span-1 md:col-span-2 justify-self-center text-lg text-blue-400">
                        <TiLockOpenOutline />
                    </div>
                    <div className="col-span-1 md:col-span-1">Status</div>
                </div>
                <div className="w-full flex flex-col rounded-md justify-between text-sm ">
                    {ledgers.length > 0 ? (
                        ledgers.map((x, i) => (
                            <AddrList
                                key={i}
                                unlockTime={x[0].toString()}
                                amount={ethers.utils.formatEther(x[1])}
                                currentTimeStamp={currentTimeStamp}
                                isClaimed={x[2]}
                                viewDate={viewDate}
                            />
                        ))
                    ) : (
                        <div className="flex flex-row text-zinc-600 my-1 items-center content-center text-center">
                            <BiLoaderAlt className="animate-spin ml-10 mr-2" />
                            <span className="animate-pulse">Loading ...</span>
                        </div>
                    )}
                </div>

                {toatlClaimAbleToken > 0 ? (
                    <button
                        type="button"
                        onClick={() => {
                            setInfo('Waiting for your confirmation on Metamask')
                            handleToggle()
                            setError('')
                            claimStake()
                        }}
                        className="text-white w-full mt-2 border-[1px] p-2 border-[#3d4f7c] bg-[#6778a5] rounded-xl cursor-pointer hover:bg-[#87a0b8]"
                    >
                        Claim {separator(toatlClaimAbleToken)} Token
                    </button>
                ) : (
                    <div className="text-white text-center w-full mt-2 border-[1px] p-2 bg-[#a8a8a8] rounded-xl select-none">
                        No Claimable Token
                    </div>
                )}
                <span className="text-red-600 font-bold text-sm my-1">
                    {error}
                </span>
            </div>

            {
                ////////////////////// Stake CCSX /////////////////////////
            }
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={openStake && state != 0}
                className="flex flex-col"
            >
                <div className="bg-white flex flex-col justify-center items-center m-4 text-center text-gray-900 text-lg p-3 rounded-lg md:w-3/5 w-full display-linebreak  transition-all duration-200 ">
                    <div
                        onClick={() => setState(0)}
                        className={`absolute bg-black/30 w-full backdrop-blur-md backdrop-grayscale-50 z-50 items-center py-40 text-white text-3xl ${
                            !!account ? 'hidden' : null
                        }`}
                    >
                        Please Connect Wallet
                        <br />
                        <br />
                        <span className="text-sm cursor-pointer p-1 border-2 rounded-md hover:bg-black/30">
                            Close
                        </span>
                    </div>
                    <div className="w-full flex flex-row justify-between border-0">
                        <div />
                        <div className="py-7 font-bold text-2xl">
                            {info}
                            <span className="text-rose-500 font-bold">
                                {error}
                            </span>
                        </div>

                        <FaWindowClose
                            fontSize={28}
                            color="red"
                            className={`transition-all duration-100 drop-shadow-lg ${
                                state == 1 || state == 3 || state == 5
                                    ? 'cursor-pointer hover:scale-125 '
                                    : 'grayscale opacity-10'
                            }`}
                            onClick={() => {
                                state == 1 || state == 3 || state == 5
                                    ? setState(0)
                                    : setState(state)
                            }}
                        />
                    </div>
                    <div className="w-2/3 border-0 my-5 h-40">
                        <div
                            id="_ccsxInput"
                            className="px-2 py-1 w-full flex flex-col border-2 rounded-md"
                        >
                            <div className="w-full flex flex-row border-0 justify-center">
                                <CoinSelected isCCOIN={isCCOIN} />
                            </div>

                            <input
                                placeholder="0"
                                type="number"
                                pattern="[0-9]*"
                                min="10"
                                step="1"
                                id="_ccsxAmt"
                                keyboardtype="decimal-pad"
                                autoComplete="off"
                                className="text-right self-end h-10 md:w-2/3 w-full active:outline-none rounded-md px-2 outline-non bg-gray-200 text-slate-500 text-2xl font-bold focus:outline-none"
                            />
                            <br />
                            <span className="items-right text-right text-sm my-1">
                                <span
                                    onClick={() => {
                                        document.getElementById(
                                            '_ccsxAmt'
                                        ).value =
                                            (isCCOIN
                                                ? CCOINBalance
                                                : CCSXBalance) / 10
                                    }}
                                    className="border-2 rounded-md px-1 my-1 text-xs hover:bg-blue-300 hover:text-white cursor-pointer"
                                >
                                    10%
                                </span>
                                <span
                                    onClick={() => {
                                        document.getElementById(
                                            '_ccsxAmt'
                                        ).value =
                                            (isCCOIN
                                                ? CCOINBalance
                                                : CCSXBalance) / 4
                                    }}
                                    className="border-2 rounded-md px-1 my-1 text-xs hover:bg-blue-300 hover:text-white cursor-pointer"
                                >
                                    25%
                                </span>
                                <span
                                    onClick={() => {
                                        document.getElementById(
                                            '_ccsxAmt'
                                        ).value =
                                            (isCCOIN
                                                ? CCOINBalance
                                                : CCSXBalance) / 2
                                    }}
                                    className="border-2 rounded-md px-1 my-1 text-xs hover:bg-blue-300 hover:text-white cursor-pointer"
                                >
                                    50%
                                </span>
                                <span
                                    onClick={() => {
                                        document.getElementById(
                                            '_ccsxAmt'
                                        ).value = isCCOIN
                                            ? CCOINBalance
                                            : CCSXBalance
                                    }}
                                    className="border-2 rounded-md px-1 my-1 text-xs hover:bg-blue-300 hover:text-white cursor-pointer"
                                >
                                    Max
                                </span>
                                <span
                                    onClick={() => {
                                        document.getElementById(
                                            '_ccsxAmt'
                                        ).value = ''
                                    }}
                                    className="border-2 rounded-md px-1 my-1 text-xs hover:bg-blue-300 hover:text-white cursor-pointer"
                                >
                                    Clear
                                </span>
                            </span>
                        </div>
                    </div>

                    {state > 1 &&
                    state < 5 &&
                    document.getElementById('_ccsxAmt').value ? (
                        <div
                            id="_showTimeLine"
                            className={`px-2 py-1 w-4/5 grid grid-flow-row grid-cols-3 border-0 rounded-md justify-between drop-shadow-sm mb-10 -mt-52`}
                        >
                            <div className="rounded-md m-1 p-1 flex flex-col col-span-3 ">
                                <div className="rounded-t-lg bg-blue-500 text-gray-200 text-xs px-3 py-1">
                                    Your Stake
                                </div>
                                <div className=" bg-blue-100 text-xl px-3 py-2">
                                    <AnimatedNumber
                                        value={
                                            document.getElementById('_ccsxAmt')
                                                .value
                                        }
                                        formatValue={formatValue}
                                        duration="600"
                                    />
                                </div>
                            </div>
                            <FaChevronDown className="col-span-1 w-full animate-bounce" />
                            <FaChevronDown className="col-span-1 w-full animate-bounce" />
                            <FaChevronDown className="col-span-1 w-full animate-bounce" />
                            <div className="rounded-md m-1 p-1 flex flex-col col-span-1 hover:scale-110 transition-all">
                                <div className=" bg-gray-100 text-xl px-3 ">
                                    <AnimatedNumber
                                        value={
                                            document.getElementById('_ccsxAmt')
                                                .value * 0.2
                                        }
                                        formatValue={formatValue}
                                        duration="600"
                                    />
                                </div>
                                <div className="rounded-bl-lg bg-gray-300 text-gray-500 text-xs px-3">
                                    15 days
                                </div>
                            </div>
                            <div className="m-1 p-1 flex flex-col col-span-1 hover:scale-110 transition-all">
                                <div className=" bg-blue-50 text-xl px-3">
                                    <AnimatedNumber
                                        value={
                                            document.getElementById('_ccsxAmt')
                                                .value * 0.2
                                        }
                                        formatValue={formatValue}
                                        duration="600"
                                    />
                                </div>
                                <div className=" bg-blue-300 text-blue-800 text-xs px-3">
                                    30days
                                </div>
                            </div>
                            <div className="m-1 p-1 flex flex-col col-span-1 hover:scale-110 transition-all">
                                <div className=" bg-blue-100 text-xl px-3 ">
                                    <AnimatedNumber
                                        value={
                                            document.getElementById('_ccsxAmt')
                                                .value * 0.65
                                        }
                                        formatValue={formatValue}
                                        duration="600"
                                    />
                                </div>
                                <div className="rounded-br-lg bg-blue-500 text-gray-200 text-xs px-3">
                                    60 days + 5% Interest
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div />
                    )}
                    {state == 5 ? (
                        <div
                            id="_showTimeLine"
                            className={`px-2 py-1 w-4/5 -mt-72`}
                        >
                            <img
                                className="scale-50"
                                src={process.env.PUBLIC_URL + '/img/profit.jpg'}
                            />
                        </div>
                    ) : (
                        <div />
                    )}
                    <StepLine step={state - 1} />
                    {state == 1 ? (
                        <div
                            onClick={() => {
                                setState(2)
                                setError('')
                                setInfo('Check CCOIN Classic Allowance')

                                document.getElementById(
                                    '_ccsxAmt'
                                ).disabled = true

                                document.getElementById(
                                    '_ccsxInput'
                                ).style.display = 'none'

                                getCCSXApprove(
                                    document.getElementById('_ccsxAmt').value
                                )
                            }}
                            className="w-2/3 bg-[#0f63ff] text-white font-bold text-2xl p-2 m-2 my-4 rounded-md hover:scale-105 transition-all shadow-lg cursor-pointer"
                        >
                            Start
                        </div>
                    ) : null}
                    {state == 2 ? (
                        <div className="relative w-2/3 bg-gray-400 text-white font-bold text-2xl p-2 m-2 pl-0 my-4 rounded-md shadow-lg items-center justify-center content-center flex flex-row">
                            <AiOutlineLoading3Quarters
                                color="inherit"
                                fontSize={16}
                                className="absolute left-1/4 mx-3 animate-spin"
                            />
                            <span className="animate-pulse">Waiting</span>
                        </div>
                    ) : null}

                    {state == 3 ? (
                        <div
                            onClick={() => {
                                setState(4)
                                //etInfo('Please approve your CCOIN Classic')
                                Stake(
                                    document.getElementById('_ccsxAmt').value,
                                    isCCOIN
                                )
                            }}
                            className="w-2/3 bg-[#0f63ff] text-white font-bold text-2xl p-2 m-2 my-4 rounded-md hover:scale-105 transition-all shadow-lg cursor-pointer"
                        >
                            Transfer fund
                        </div>
                    ) : null}

                    {state == 4 ? (
                        <div className="relative w-2/3 bg-gray-400 text-white font-bold text-2xl p-2 m-2 pl-0 my-4 rounded-md shadow-lg items-center justify-center content-center flex flex-row">
                            <AiOutlineLoading3Quarters
                                color="inherit"
                                fontSize={16}
                                className="absolute left-1/4 mx-3 animate-spin"
                            />
                            <span className="animate-pulse">Waiting</span>
                        </div>
                    ) : null}

                    {state == 5 ? (
                        <div
                            onClick={() => {
                                setState(0)
                            }}
                            className="w-2/3 bg-[#0f63ff] text-white font-bold text-2xl p-2 m-2 my-4 hover:scale-105 transition-all rounded-md shadow-lg items-center justify-center content-center flex flex-row cursor-pointer"
                        >
                            Done
                        </div>
                    ) : null}

                    <br />
                </div>
                <br />
            </Backdrop>

            {
                ///////////////////////////////////////////////
            }
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
                className="flex flex-col"
            >
                <div className="bg-white m-4 text-center text-gray-900 text-lg p-3 rounded-lg md:w-2/5 w-full display-linebreak">
                    <CircularProgress color="inherit" />
                    <br />
                    {info}
                </div>
                <br />
            </Backdrop>
        </div>
    )
}

export default MyTokens
