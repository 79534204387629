import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { ethers } from "ethers";
import { useSelector } from "react-redux";
import AnimatedNumber from "animated-number-react";
import Backdrop from "@mui/material/Backdrop";
import Tooltip from "@mui/material/Tooltip";
import {
  AiOutlineLoading3Quarters,
  AiOutlineCopy,
  AiFillEdit,
  AiFillDelete,
  AiFillCaretLeft,
} from "react-icons/ai";
import { MdOutlineAccountBalanceWallet, MdVerified } from "react-icons/md";
import { BsFillPersonFill } from "react-icons/bs";
import { BiImage, BiMailSend } from "react-icons/bi";
import { FaWindowClose, FaBell } from "react-icons/fa";
import AddNetwork from "./AddNetwork";
import axios from "axios";
import { i } from "mathjs";
/* Verify Contract */
let verifyContract = [];
verifyContract.push(process.env.REACT_APP_NFT1155STAKE_CONTRACT.toLowerCase());
verifyContract.push(process.env.REACT_APP_EGGHATCH_CONTRACT.toLowerCase());

const CCOIN = process.env.REACT_APP_CCOIN;
const CCSX = process.env.REACT_APP_CCSX;
const BUSD = process.env.REACT_APP_BUSD;
const MARKRT = process.env.REACT_APP_MARKRT;

const ABIMARKET = require("../abi/ABIMARKET");
const IERC20 = require("../abi/IERC20");
const formatValue = (value) => `${separator(Number(value).toFixed(0))}`;
function separator(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const isMetaMaskInstalled = () => {
  const { ethereum } = window;
  return Boolean(ethereum && ethereum.isMetaMask);
};

function Marketplace() {
  const [error, setError] = useState();
  const [info, setInfo] = useState();
  const [account, setAccount] = useState();
  const [state, setState] = useState(0);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [BUSDBalance, setBUSDBalance] = useState(0);
  const [totalList, setTotalList] = useState();
  const [list, setList] = useState([]);
  const [reLoad, setReload] = useState(0);

  const [nftImg, setNftImg] = useState();
  const [nftID, setNftID] = useState();
  const [nftName, setNftName] = useState();
  const [nftPrice, setNftPrice] = useState();
  const [nftAddress, setNftAddress] = useState();
  const [sellerAddress, setSellerAddress] = useState();
  const [attributes, setAttributes] = useState();
  const [listID, setListID] = useState();
  const [onlyYours, setOnlyYours] = useState(false);
  const [isPublic, setIsPublic] = useState(true);
  const [nftBuyer, setNftBuyer] = useState();

  const wc = useSelector((state) => state.walletConnect.value);

  let provider;
  let ethereum = window.ethereum;
  let tempSigner;

  if (!!wc.account) {
    console.log("WC!");
    ethereum = wc.provider;
    provider = wc.web3Provider;
    tempSigner = provider.getSigner();
    console.log("tempSignerW:", tempSigner);
    ethereum.on("accountsChanged", (accounts) => setReload(reLoad + 1));
  } else if (isMetaMaskInstalled()) {
    console.log("MT!");
    ethereum = window.ethereum;
    provider = new ethers.providers.Web3Provider(ethereum);
    tempSigner = provider.getSigner();
    ethereum.on("accountsChanged", (accounts) => setReload(reLoad + 1));
    console.log("tempSigner:", tempSigner);
  }

  useEffect(() => {
    //console.clear()
    if (!!wc.account || isMetaMaskInstalled()) {
      getCurrentAccount();
      getBUSDBalance();
      gettotalList();
      getAllList();

      //getNFTsData()

      console.log("account:", account);
      console.log("wc.account:", wc.account);

      const getWalletConnect = JSON.parse(
        localStorage.getItem("walletconnect")
      );
      if (!!getWalletConnect) {
        console.log("getWalletConnect:", getWalletConnect.accounts[0]);
        setAccount(getWalletConnect.accounts[0]);
      } else {
        getCurrentAccount();
      }
    }
    return () => {};
  }, [wc.account, account, state, reLoad]);

  //console.log('tempSigner:', tempSigner)
  //// GET Require error from contract using catch promist ////
  async function reason(_hash) {
    let hash = _hash;
    let tx = await provider.getTransaction(hash);
    if (!tx) {
      console.log("Reason : tx not found");
    } else {
      let code = await provider.call(tx, tx.blockNumber);
      return ethers.utils.toUtf8String(code.substr(138));
    }
  }

  const contract = new ethers.Contract(MARKRT, ABIMARKET, tempSigner);

  async function getNFTsData() {
    for (let i = 0; i < verifyContract.length; i++) {
      await getNFTsByContract(verifyContract[i]);

      console.log("verifyContract[", i, "]:", verifyContract[i]);
      //console.log('NTTTrunk:', getNFTsByContract(verifyContract[i]))
    }
  }

  //console.log('NTTTrunk:', NTTTrunk)

  async function getCurrentAccount() {
    const accounts = await ethereum.request({
      method: "eth_accounts",
    });
    setAccount(accounts[0]);
    console.log("accounts[0]:", accounts[0]);
    return accounts[0];
  }

  async function gettotalList() {
    console.log("tempSigner:", tempSigner.getAddress());
    try {
      await contract.callStatic.totalList().then((x) => {
        setTotalList(x.toNumber());
        return x.toNumber();
      });
    } catch (err) {
      console.log("err: ", err);
    }
  }
  console.log("totalList:", totalList);

  async function getAllList() {
    let _list = [];
    try {
      await contract.callStatic.getAllList().then((result) => {
        result.map((data) => {
          _list.push(data);
        });
        setList(_list);
      });
    } catch (err) {
      console.log("err: ", err);
    }
  }
  console.log("list:", list);

  console.log("tempSigner.getAddress():", tempSigner.getAddress());
  async function getBUSDBalance() {
    try {
      const constract = new ethers.Contract(BUSD, IERC20, tempSigner);
      const balance = await constract.callStatic.balanceOf(
        tempSigner.getAddress()
      );
      setBUSDBalance(parseInt(ethers.utils.formatEther(balance)));
    } catch (err) {
      console.log("err: ", err);
    }
  }

  async function getBUSDApprove(_amount) {
    try {
      const constract = new ethers.Contract(BUSD, IERC20, tempSigner);

      if (BUSDBalance >= parseInt(_amount)) {
        const checkAllowance = await constract.callStatic.allowance(
          account,
          MARKRT
        );

        if (
          parseInt(_amount) > parseInt(ethers.utils.formatEther(checkAllowance))
        ) {
          setInfo("Please Approve Your BUSD");
          console.log("BUSDBalance:", BUSDBalance);
          const tx = await constract.approve(
            MARKRT,
            ethers.utils.parseEther(BUSDBalance.toString())
          );
          setInfo("Waiting for block confirmation");
          const receipt = await tx.wait(1).then((tx) => {
            setState(3);
            setInfo("Click Purchase to get your NFTs.");
          });
        } else {
          setState(3);
          setInfo("Click Purchase to get your NFTs.");
        }
      } else {
        setInfo("");
        setError("Insufficient BUSD Balance.");
        setState(1);
      }
    } catch (err) {
      console.log("err: ", err);
      setState(0);
    }
  }

  async function buy(_listId) {
    console.log("parseInt(_listId):", parseInt(_listId));
    const contract = new ethers.Contract(MARKRT, ABIMARKET, tempSigner);
    try {
      const tx = await contract.Buy(parseInt(_listId));
      setInfo("Waiting for block confirmation");
      const receipt = await tx.wait(1).then((tx) => {
        setState(5);
        setInfo("Transaction Complete");
      });
    } catch (err) {
      console.log("err: ", err);
      setState(0);
    }
  }

  async function cancelList(_listID) {
    console.log("parseInt(_listID):", parseInt(_listID));
    const contract = new ethers.Contract(MARKRT, ABIMARKET, tempSigner);
    try {
      const tx = await contract.CancelList(parseInt(_listID));
      setInfo("Waiting for block confirmation");
      const receipt = await tx.wait(1).then((tx) => {
        setState(5);
        setInfo("Transaction Complete");
      });
    } catch (err) {
      console.log("err: ", err);
      setState(0);
    }
  }

  async function editList(_listID, _sellingPrice, _sellToAddr) {
    console.log("parseInt(_listID):", parseInt(_listID));
    const contract = new ethers.Contract(MARKRT, ABIMARKET, tempSigner);
    try {
      const tx = await contract.EditlList(
        parseInt(_listID),
        ethers.utils.parseEther(_sellingPrice.toString()),
        _sellToAddr
      );
      setInfo("Waiting for block confirmation");
      const receipt = await tx.wait(1).then((tx) => {
        setState(5);
        setInfo("Transaction Complete");
      });
    } catch (err) {
      console.log("err: ", err);
      setState(0);
    }
  }

  async function getNFTsByContract(_contract) {
    try {
      const options = {
        method: "GET",
        url: "https://deep-index.moralis.io/api/v2/nft/" + _contract,
        params: {
          chain: "0x61",
          format: "decimal",
          normalizeMetadata: "true",
        },
        headers: {
          accept: "application/json",
          "X-API-Key":
            "2hr05pidx8lH2wSpcXDAKZrKE2pVI98urjqVt4hZ6lpnTkWBtv5sjtDrwXYt8dWu",
        },
      };

      await axios
        .request(options)
        .then(function (response) {
          console.log(response.data);
          console.log(response.data);
          window.localStorage.setItem(
            _contract.toLowerCase(),
            JSON.stringify(response.data)
          );
          return response.data;
        })
        .catch(function (error) {
          console.error(error);
        });
    } catch (err) {
      console.log("err: ", err);
    }
  }

  /*//////////////////////////////////////////////////////////////////*/
  function Lists({
    nftId,
    price,
    nftAddr,
    seller,
    buyer,
    isSold,
    dateList,
    dateLast,
    listID,
  }) {
    let NFTData = {};
    let attributes = {};
    const NFTsData = localStorage.getItem(nftAddr.toLowerCase());
    const NFTs = JSON.parse(NFTsData);
    console.log("x.nftAddr:", NFTs.result);
    for (let i = 0; i < NFTs.result.length; i++) {
      if (NFTs.result[i].token_id == nftId) {
        NFTData = NFTs.result[i].normalized_metadata;
        attributes = JSON.parse(NFTs.result[i].metadata);
      }
    }

    console.log("NFTData:", NFTData);
    console.log("attributes:", attributes.attributes);
    // const NFTsDataObj = JSON.parse(NFTsData)

    useEffect(() => {
      //getNftData()
      return () => {};
    }, []);

    //console.log('nftData.attributes:', nftData.attributes)

    if (
      (typeof attributes.attributes === "object" ||
        typeof attributes.attributes === "function") &&
      attributes.attributes !== null
    ) {
      attributes = Object.entries(attributes.attributes);

      //console.log('attributes:', attributes)
    }
    const addrZero = "0x0000000000000000000000000000000000000000";
    return (
      <div
        onClick={() => {}}
        className={`col-span-1 transition-all duration-600 m-2 hover:scale-105 ${
          isSold
            ? "hidden"
            : account.toLowerCase() == buyer.toLowerCase() ||
              account.toLowerCase() == seller.toLowerCase() ||
              (onlyYours ? null : buyer == addrZero)
            ? ""
            : "hidden"
        }`}
      >
        <div className="item-bg flex h-[350px] flex-col justify-center overflow-hidden items-center rounded-xl transition-all duration-200 ">
          <div className="h-[300px] overflow-hidden">
            {seller.toLowerCase() == account.toLowerCase() ? (
              <div className="fixed text-white m-2 flex flex-row">
                <div
                  onClick={() => {
                    setInfo("Edit your list");
                    setNftImg(NFTData.image);
                    setNftID(nftId.toNumber());
                    setNftName(NFTData.name);
                    setNftPrice(ethers.utils.formatEther(price));
                    setNftAddress(nftAddr);
                    setSellerAddress(seller);
                    setAttributes(attributes);
                    setState(8);
                    setOpenBackdrop(true);
                    setListID(listID);
                    setNftBuyer(buyer);

                    buyer != addrZero ? setIsPublic(false) : setIsPublic(true);

                    console.log("nftID:", nftID);
                  }}
                  className="bg-black/80 flex flex-row items-center mx-1 p-1 px-2 rounded-lg text-sm font-bold drop-shadow-lg cursor-pointer hover:bg-[#ffae00] hover:scale-105 transition-all duration-200"
                >
                  <AiFillEdit /> Edit
                </div>
                <div
                  onClick={() => {
                    setInfo("Do you want to Delist this NFT ?");
                    setNftImg(NFTData.image);
                    setNftID(nftId.toNumber());
                    setNftName(NFTData.name);
                    setNftPrice(ethers.utils.formatEther(price));
                    setNftAddress(nftAddr);
                    setSellerAddress(seller);
                    setAttributes(attributes);
                    setState(7);
                    setOpenBackdrop(true);
                    setNftBuyer(buyer);
                    buyer != addrZero ? setIsPublic(false) : setIsPublic(true);
                    setListID(listID);
                    console.log("nftID:", nftID);
                  }}
                  className="bg-red-700/80 flex flex-row items-center p-1 px-2 rounded-lg text-sm font-bold drop-shadow-lg cursor-pointer hover:bg-[#ffae00] hover:scale-105 transition-all duration-200"
                >
                  <AiFillDelete /> Delist
                </div>
                {buyer != addrZero ? (
                  <div className="bg-blue-500/80 flex flex-row items-center mx-1 p-1 px-2 rounded-lg text-xs font-bold drop-shadow-lg transition-all duration-200">
                    <BsFillPersonFill />{" "}
                    {buyer.slice(0, 3) + "..." + buyer.slice(-4)}
                  </div>
                ) : (
                  <div />
                )}
              </div>
            ) : buyer.toLowerCase() == account.toLowerCase() ? (
              <div className="fixed text-white m-2 flex flex-row overflow-hidden">
                <div className="bg-blue-500/80 text-white flex flex-row items-center mx-1 p-1 px-2 rounded-lg text-sm font-bold drop-shadow-lg transition-all duration-200">
                  <BsFillPersonFill /> Just for you
                </div>
              </div>
            ) : (
              <div />
            )}

            {NFTData.image == null ? (
              <BiImage
                className="items-centers text-white/20 place-items-center mt-16"
                size={120}
              />
            ) : (
              <img
                className="px-1 transition-all duration-1000"
                src={NFTData.image}
              />
            )}
          </div>
          <div className="relative translate-y-14 -mt-16 w-full bg-white  text-gray-500 p-4 justify-center text-center transition-all duration-500 hover:-translate-y-0">
            <span className="flex flex-row text-yellow-500 font-bold -mt-3 items-center">
              Crypterium Fantasy
              {verifyContract.includes(nftAddr.toLowerCase()) ? ( // Check verify contract
                <MdVerified className="text-green-500 " />
              ) : null}
            </span>
            <span className="flex flex-row text-black text-sm font-bold">
              {!(NFTData.name == null) ? NFTData.name : "undefinded"}
            </span>
            <div className="w-full flex flex-row justify-between ">
              <span className="text-xs">
                {nftAddr.slice(0, 5) + "...." + nftAddr.slice(-5)}
              </span>
              <span className="text-xs">#{nftId.toNumber()}</span>
            </div>
            <div className="w-full flex flex-row justify-between">
              <span className="text-xs">Seller</span>
              <span className="text-xs">
                {seller.toLowerCase() == account.toLowerCase() ? (
                  <span className="font-bold text-blue-500">You</span>
                ) : (
                  seller.slice(0, 5) + "...." + seller.slice(-5)
                )}
                {}
              </span>
            </div>

            <div className="w-full flex flex-row justify-between mb-3">
              <span>Price</span>
              <span className="flex flex-row text-black font-bold">
                <img
                  className="scale-75"
                  src={process.env.PUBLIC_URL + "/img/coins/busd.png"}
                />
                {formatValue(ethers.utils.formatEther(price))}
              </span>
            </div>
            <div
              onClick={() => {
                setInfo("NFT Detail");
                setNftImg(NFTData.image);
                setNftID(nftId.toNumber());
                setNftName(NFTData.name);
                setNftPrice(ethers.utils.formatEther(price));
                setNftAddress(nftAddr);
                setSellerAddress(seller);
                setAttributes(attributes);
                setState(1);
                setOpenBackdrop(true);
                setListID(listID);
              }}
              className="text-white w-full mt-2 border-[0px] p-2 bg-[#36300f] rounded-md cursor-pointer hover:bg-[#ffae00] transition-all"
            >
              View
            </div>
          </div>
        </div>
      </div>
    );
  }

  /*//////////////////////////////////////////////////////////////////*/
  return (
    <div className="flex flex-col w-full justifyitem-center items-center mt-20 select-none">
      <AddNetwork CCOIN={CCOIN} CCSX={CCSX} BUSD={BUSD} />

      <div className="border-0 w-full md:w-5/6 grid grid-flow-row grid-cols-2 md:grid-cols-4 gap-2 p-5 mt-10 rounded-xl  bg-neutral-100 bg-opacity-70 backdrop-blur-xl transition-all">
        <div className="hidden md:block blur-sm absolute -top-4 md:right-4 text-3xl md:text-4xl self-center -mb-3 font-bold text-yellow-700 ">
          M a r k e t p l a c e
        </div>
        <div className="hidden md:block absolute -top-5 md:right-5 text-3xl md:text-4xl self-center -mb-3 font-bold text-white ">
          M a r k e t p l a c e
        </div>

        <div className="md:hidden -mt-10 col-span-2 text-3xl font-bold text-yellow-700 blur-sm">
          M a r k e t p l a c e
        </div>
        <div className="md:hidden -mt-12 col-span-2 text-3xl font-bold text-white z-40">
          M a r k e t p l a c e
        </div>
        <div className="md:col-span-4 col-span-2 bg-black/10 drop-shadow-md rounded-lg p-3 mt-5 text-white font-bold text-xl flex flex-row justify-between items-center">
          <Link to="/MyWallet">
            <span className="cursor-pointer flex flex-row justify-between items-center bg-black/70 px-2 mx-1 rounded-lg hover:bg-[#ffae00] transition-all p-1">
              <AiFillCaretLeft className="items-center" />
              <MdOutlineAccountBalanceWallet
                className="items-center"
                size={28}
              />
              Your NFT Wallet
            </span>
          </Link>
          <div
            onClick={() => {
              setState(state + 100);
            }}
          >
            CLICK
          </div>
          <span className="flex flex-row items-center text-white/90">
            <span
              onClick={() => setOnlyYours(!onlyYours)}
              className={` px-3 rounded-l-lg  transition-all p-1 ${
                onlyYours ? "bg-black/70" : "bg-black/20 cursor-pointer"
              }`}
            >
              Your List
            </span>
            <span
              onClick={() => setOnlyYours(!onlyYours)}
              className={`px-3 rounded-r-lg  transition-all p-1 ${
                !onlyYours ? "bg-black/70" : "bg-black/20  cursor-pointer"
              }`}
            >
              All Lists
            </span>
          </span>
        </div>
        {!!account ? (
          list.length > 0 ? (
            list
              .slice(0)
              .reverse()
              .map((x, i) => {
                return (
                  <Lists
                    key={i}
                    listID={list.length - i}
                    nftId={x.nftId}
                    price={x.price}
                    nftAddr={x.nftAddr}
                    seller={x.seller}
                    buyer={x.buyer}
                    isSold={x.isSold}
                    dateList={x.dateList}
                    dateLast={x.dateLast}
                  />
                );
              })
          ) : (
            <>
              <div className=" my-10 w-full col-span-2 md:col-span-5 flex flex-row items-center text-black animate-pulse justify-center">
                <div id="loadingBox">
                  <AiOutlineLoading3Quarters
                    color="inherit"
                    fontSize={20}
                    className="mx-3 font-bold animate-spin"
                  />
                  Loading
                </div>
              </div>
              <div className="hidden">
                {setTimeout(() => {
                  try {
                    document.getElementById("loadingBox").textContent =
                      "Unable to load data.";
                  } catch (e) {
                    //console.error(e)
                  }
                }, 9000)}
              </div>
            </>
          )
        ) : (
          <div className="w-full col-span-2 md:col-span-4 flex flex-row justify-center">
            <div className="text-black text-2xl my-20 text-center">
              Please Connect Wallet
            </div>
          </div>
        )}
      </div>

      {/*///////////////////// //////////////////////////// */}
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openBackdrop && state != 0}
        className="flex flex-col"
      >
        <div className="bg-white flex flex-col justify-center items-center m-4 text-center text-gray-900 text-lg p-3 rounded-lg md:w-3/5 w-full display-linebreak">
          <div
            onClick={() => setState(0)}
            className={`absolute bg-black/30 w-full backdrop-blur-md backdrop-grayscale-50 z-50 items-center py-40 text-white text-3xl ${
              !!account ? "hidden" : null
            }`}
          >
            Please Connect Wallet
            <br />
            <br />
            <span className="text-sm cursor-pointer p-1 border-2 rounded-md hover:bg-black/30">
              Close
            </span>
          </div>

          <div className="w-full flex flex-row justify-between">
            <span className="text-gray-100">#{state}</span>
            <FaWindowClose
              fontSize={28}
              color="#cc0e00"
              className={`transition-all duration-100 drop-shadow-lg ${
                state == 1 ||
                state == 3 ||
                state == 5 ||
                state == 7 ||
                state == 8
                  ? "cursor-pointer hover:scale-125 "
                  : "grayscale opacity-10"
              }`}
              onClick={() => {
                setError("");
                state == 1 ||
                state == 3 ||
                state == 5 ||
                state == 7 ||
                state == 8
                  ? setState(0)
                  : setState(state);
              }}
            />
          </div>
          <div className="w-full flex flex-row justify-center">
            <div className="py-7 text-gray-800 text-3xl font-bold">
              {info}
              <span className="text-rose-500 font-bold">{error}</span>
            </div>
          </div>

          {state == 1 ? (
            <>
              <div className="w-4/5 rounded-lg drop-shadow-md flex flex-col justify-center py-7 bg-black/5 ">
                <div className="w-11/12 mx-6 flex flex-row my-1 p-2 bg-white rounded-lg ">
                  <span className="w-full flex flex-row text-black font-bold justify-center items-center">
                    {nftAddress}
                    {verifyContract.includes(nftAddress.toLowerCase()) ? ( // Check verify contract
                      <MdVerified className="text-green-500 " />
                    ) : null}
                    <AiOutlineCopy
                      onClick={() => {
                        navigator.clipboard.writeText(nftAddress);
                      }}
                      className="cursor-pointer hover:text-orange-400"
                    />
                  </span>
                </div>
                <span className="w-full flex flex-row">
                  {nftImg == null ? (
                    <BiImage
                      className="items-centers text-white/70 place-items-center mx-16 mt-14"
                      size={120}
                    />
                  ) : (
                    <img
                      className="w-1/2 px-1 transition-all duration-1000 scale-100"
                      src={nftImg}
                    />
                  )}

                  <span className="w-1/2 -ml-5 mt-5 flex flex-col justify-center p-5 bg-white rounded-lg text-md">
                    <div className="w-full flex flex-row justify-between">
                      <span className="font-bold">ID</span>
                      <span className="flex flex-row text-black">#{nftID}</span>
                    </div>
                    <div className="w-full flex flex-row justify-between">
                      <span className="font-bold">Name</span>
                      <span className="flex flex-row text-black">
                        {nftName == null ? "Undefined" : nftName}
                      </span>
                    </div>
                    <div className="w-full flex flex-row justify-between">
                      <Tooltip title={sellerAddress} placement="right">
                        <span className="font-bold">Seller</span>
                      </Tooltip>
                      <span className="flex flex-row text-black items-center">
                        {sellerAddress.slice(0, 5) +
                          "...." +
                          sellerAddress.slice(-4)}

                        <AiOutlineCopy
                          onClick={() => {
                            navigator.clipboard.writeText(sellerAddress);
                          }}
                          className="cursor-pointer hover:text-orange-400"
                        />
                      </span>
                    </div>
                    <div className="w-full flex flex-row justify-between">
                      <span className="font-bold">Price</span>

                      <span className="flex flex-row text-black ">
                        <img
                          className="scale-75"
                          src={process.env.PUBLIC_URL + "/img/coins/busd.png"}
                        />
                        {formatValue(nftPrice)}
                      </span>
                    </div>
                    <span className="w-full text-left font-bold">
                      Attributes
                    </span>
                    {!!attributes ? (
                      <div className="bg-gray-400/5 drop-shadow-lg flex flex-col rounded-lg mt-0 p-2 text-left text-gray-600 text-sm">
                        {attributes.map((x) => (
                          <div className="flex flex-row justify-between">
                            <span>{x[0] != "Properties" ? x[0] : ""}</span>
                            <span>{x[1]}</span>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div />
                    )}
                  </span>
                </span>
              </div>
              <div
                onClick={() => {
                  setState(2);
                  setError("");
                  setInfo("Approve BUSD");
                  getBUSDApprove(nftPrice);
                }}
                className="w-2/3 bg-[#0059ff] hover:bg-[#ffae00] text-white text-3xl font-bold p-2 m-2 my-4 rounded-md hover:scale-105 transition-all shadow-lg cursor-pointer"
              >
                Buy Now
              </div>
            </>
          ) : null}
          {state == 2 ? (
            <>
              <div className="relative w-2/3 bg-gray-400 text-white p-2 m-2 pl-0 my-4 rounded-md shadow-lg items-center justify-center content-center flex flex-row">
                <AiOutlineLoading3Quarters
                  color="inherit"
                  fontSize={24}
                  className="absolute left-1/4 mx-3 font-bold animate-spin"
                />
                <span className="animate-pulse text-3xl font-bold">
                  Waiting
                </span>
              </div>
            </>
          ) : null}

          {state == 3 ? (
            <>
              <div className="w-4/5 rounded-lg drop-shadow-md flex flex-col justify-center py-7 bg-black/5 ">
                <div className="w-11/12 mx-6 flex flex-row my-1 p-2 bg-white rounded-lg ">
                  <span className="w-full flex flex-row text-black font-bold justify-center items-center">
                    {nftAddress}
                    {verifyContract.includes(nftAddress.toLowerCase()) ? ( // Check verify contract
                      <MdVerified className="text-green-500 " />
                    ) : null}
                    <AiOutlineCopy
                      onClick={() => {
                        navigator.clipboard.writeText(nftAddress);
                      }}
                      className="cursor-pointer hover:text-orange-400"
                    />
                  </span>
                </div>
                <span className="w-full flex flex-row">
                  {nftImg == null ? (
                    <BiImage
                      className="items-centers text-white/70 place-items-center mx-16 mt-14"
                      size={120}
                    />
                  ) : (
                    <img
                      className="w-1/2 px-1 transition-all duration-1000 scale-100"
                      src={nftImg}
                    />
                  )}

                  <span className="w-1/2 -ml-5 mt-5 flex flex-col justify-center p-5 bg-white rounded-lg text-md">
                    <div className="w-full flex flex-row justify-between">
                      <span className="font-bold">ID</span>
                      <span className="flex flex-row text-black">#{nftID}</span>
                    </div>
                    <div className="w-full flex flex-row justify-between">
                      <span className="font-bold">Name</span>
                      <span className="flex flex-row text-black">
                        {nftName == null ? "Undefined" : nftName}
                      </span>
                    </div>
                    <div className="w-full flex flex-row justify-between">
                      <Tooltip title={sellerAddress} placement="right">
                        <span className="font-bold">Seller</span>
                      </Tooltip>
                      <span className="flex flex-row text-black items-center">
                        {sellerAddress.slice(0, 5) +
                          "...." +
                          sellerAddress.slice(-4)}

                        <AiOutlineCopy
                          onClick={() => {
                            navigator.clipboard.writeText(sellerAddress);
                          }}
                          className="cursor-pointer hover:text-orange-400"
                        />
                      </span>
                    </div>
                    <div className="w-full flex flex-row justify-between">
                      <span className="font-bold">Price</span>

                      <span className="flex flex-row text-black ">
                        <img
                          className="scale-75"
                          src={process.env.PUBLIC_URL + "/img/coins/busd.png"}
                        />
                        {formatValue(nftPrice)}
                      </span>
                    </div>
                    <span className="w-full text-left font-bold">
                      Attributes
                    </span>
                    {!!attributes ? (
                      <div className="bg-gray-400/5 drop-shadow-lg flex flex-col rounded-lg mt-0 p-2 text-left text-gray-600 text-sm">
                        {attributes.map((x) => (
                          <div className="flex flex-row justify-between">
                            <span>{x[0] != "Properties" ? x[0] : ""}</span>
                            <span>{x[1]}</span>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div />
                    )}
                  </span>
                </span>
              </div>
              <div
                onClick={() => {
                  setState(4);
                  setInfo("Please Confirm Metamask");
                  buy(listID);
                }}
                className="w-2/3 bg-[#0f63ff] flex flex-row text-white text-3xl text-center justify-center font-bold  p-2 m-2 my-4 rounded-md hover:scale-105 transition-all shadow-lg cursor-pointer"
              >
                Purchase - (
                <img
                  className="scale-75 mt-1"
                  src={process.env.PUBLIC_URL + "/img/coins/busd.png"}
                />
                {formatValue(nftPrice)})
              </div>
            </>
          ) : null}

          {state == 4 ? (
            <>
              <div className="relative w-2/3 bg-gray-400 text-white p-2 m-2 pl-0 my-4 rounded-md shadow-lg items-center justify-center content-center flex flex-row">
                <AiOutlineLoading3Quarters
                  color="inherit"
                  fontSize={24}
                  className="absolute left-1/4 mx-3 font-bold animate-spin"
                />
                <span className="animate-pulse text-3xl font-bold">
                  Waiting
                </span>
              </div>
            </>
          ) : null}

          {state == 5 ? (
            <>
              <div
                onClick={() => {
                  setOpenBackdrop(false);
                  setState(0);
                }}
                className="w-2/3 bg-[#0f63ff] text-white text-3xl font-bold p-2 m-2 my-4 hover:scale-105 transition-all rounded-md shadow-lg items-center justify-center content-center flex flex-row cursor-pointer"
              >
                Done
              </div>
            </>
          ) : null}

          {state == 6 ? (
            <>
              <div
                id="coinSelect"
                className="w-full flex flex-row justify-center py-2"
              >
                <div className="md:w-1/3 w-2/3 rounded-lg mx-2 p-3 transition-all  bg-gray-700 text-gray-200 drop-shadow-md scale-105">
                  <span className="text-xs flex flex-row justify-end">
                    Your Balance
                  </span>
                  <span className="flex flex-row justify-between">
                    <span className="flex flex-row">
                      <img
                        className="px-1"
                        width={36}
                        src={process.env.PUBLIC_URL + "/img/coins/busd.png"}
                      />
                      BUSD
                    </span>
                    <AnimatedNumber
                      value={BUSDBalance}
                      formatValue={formatValue}
                      duration="500"
                    />
                  </span>
                </div>
              </div>
              <div
                onClick={() => {
                  setInfo("");
                  setError("");
                  setState(0);
                }}
                className="w-2/3 bg-[#0059ff] text-white text-3xl font-bold p-2 m-2 my-4 rounded-md hover:scale-105 transition-all shadow-lg cursor-pointer"
              >
                Close
              </div>
            </>
          ) : null}
          {/*//////////////////////// D E L I S T //////////////////////////// */}
          {state == 7 ? (
            <>
              <div className="md:w-4/5 w-full rounded-3xl drop-shadow-md flex flex-col justify-center py-7 bg-gray-800/10 ">
                <span className="w-full flex flex-row">
                  {nftImg == null ? (
                    <BiImage
                      className="w-1/2 -ml-3 items-centers text-white/20 place-items-center mt-16"
                      size={96}
                    />
                  ) : (
                    <img
                      className="w-1/2 px-1 transition-all duration-1000 scale-100 -ml-3"
                      src={nftImg}
                    />
                  )}

                  <span className="w-1/2 -ml-5 mt-5 flex flex-col justify-center p-5 bg-white/50 text-gray-900 rounded-lg text-md">
                    <span className="w-full text-left text-4xl font-bold -mt-2 mb-2">
                      {nftName}
                    </span>
                    <div className="w-full flex flex-row justify-between">
                      <span className="font-bold">Token ID</span>
                      <span className="flex flex-row text-gray-900">
                        #{nftID}
                      </span>
                    </div>

                    <span className="w-full text-left font-bold mb-1 flex flex-row">
                      Selling Price
                      <img
                        className="scale-75 mt-1"
                        src={process.env.PUBLIC_URL + "/img/coins/busd.png"}
                      />
                    </span>
                    <div className="text-right m-2 w-full rounded-md px-4 outline-non bg-white/80 text-slate-600 text-3xl font-bold ">
                      {separator(nftPrice)}
                    </div>

                    <div className="w-full flex flex-row justify-between my-1">
                      <span className="font-bold">Sell To</span>
                      <span className="flex flex-row text-gray-200">
                        <div
                          className={` rounded-lg px-2 font-bold mx-1 transition-all ${
                            isPublic
                              ? "bg-blue-600"
                              : "bg-black/10 text-gray-400"
                          }`}
                        >
                          Public
                        </div>
                        <div
                          className={` rounded-lg px-2 font-bold mx-1 transition-all ${
                            !isPublic
                              ? "bg-blue-600"
                              : "bg-black/10 text-gray-400"
                          }`}
                        >
                          Private
                        </div>
                      </span>
                    </div>
                    <div
                      className={`text-right m-2 w-full  transition-all rounded-md px-4 outline-non  text-slate-600 text-3xl font-bold  ${
                        !isPublic
                          ? "bg-white/80"
                          : "bg-white/10 text-gray-500 opacity-40"
                      }`}
                    >
                      {nftBuyer.slice(0, 4) + "..." + nftBuyer.slice(-4)}
                    </div>
                  </span>
                </span>
                <span className="text-sm mt-3 -mb-5 text-red-500">
                  - NFT will return to owner, no-refund for listing fee -
                </span>
              </div>
              <div
                onClick={() => {
                  setInfo("Please Confirm on Metamask");
                  setError("");
                  setState(4);
                  cancelList(listID);
                }}
                className="w-2/3 bg-red-600 text-white text-3xl font-bold p-2 m-2 my-4 rounded-md hover:scale-105 transition-all shadow-lg cursor-pointer"
              >
                Confirm
              </div>
            </>
          ) : null}
          {/*//////////////////////// E D I T //////////////////////////// */}
          {state == 8 ? (
            <>
              <div className="md:w-4/5 w-full rounded-3xl drop-shadow-md flex flex-col justify-center py-7 bg-gray-800/10 ">
                <span className="w-full flex flex-row">
                  {nftImg == null ? (
                    <BiImage
                      className="w-1/2 -ml-3 items-centers text-white/20 place-items-center mt-16"
                      size={96}
                    />
                  ) : (
                    <img
                      className="w-1/2 px-1 transition-all duration-1000 scale-100 -ml-3"
                      src={nftImg}
                    />
                  )}

                  <span className="w-1/2 -ml-5 mt-5 flex flex-col justify-center p-5 bg-white/50 text-gray-900 rounded-lg text-md">
                    <span className="w-full text-left text-4xl font-bold -mt-2 mb-2">
                      {nftName}
                    </span>
                    <div className="w-full flex flex-row justify-between">
                      <span className="font-bold">Token ID</span>
                      <span className="flex flex-row text-gray-900">
                        #{nftID}
                      </span>
                    </div>

                    <span className="w-full text-left font-bold mb-1 flex flex-row">
                      Selling Price
                      <img
                        className="scale-75 mt-1"
                        src={process.env.PUBLIC_URL + "/img/coins/busd.png"}
                      />
                    </span>
                    <input
                      placeholder={nftPrice}
                      type="number"
                      pattern="[0-9]"
                      min="1"
                      step="10"
                      id="_sellingPrice"
                      keyboardtype="decimal-pad"
                      autoComplete="off"
                      className="text-right m-2 w-full active:outline-none rounded-md px-4 outline-non bg-white text-slate-600 text-3xl font-bold focus:outline-none"
                    />

                    <div className="w-full flex flex-row justify-between my-1">
                      <span className="font-bold">Sell To</span>
                      <span className="flex flex-row text-gray-200">
                        <div
                          onClick={() => {
                            setIsPublic(true);

                            document.getElementById(
                              "_sellToAddress"
                            ).disabled = true;
                          }}
                          className={`cursor-pointer  rounded-lg px-2 font-bold mx-1 transition-all ${
                            isPublic
                              ? "bg-blue-600"
                              : "bg-black/10 text-gray-400"
                          }`}
                        >
                          Public
                        </div>
                        <div
                          onClick={() => {
                            setIsPublic(false);

                            document.getElementById(
                              "_sellToAddress"
                            ).disabled = false;
                          }}
                          className={`cursor-pointer  rounded-lg px-2 font-bold mx-1 transition-all ${
                            !isPublic
                              ? "bg-blue-600"
                              : "bg-black/10 text-gray-400"
                          }`}
                        >
                          Private
                        </div>
                      </span>
                    </div>
                    <input
                      placeholder={
                        nftBuyer.slice(0, 4) + "..." + nftBuyer.slice(-4)
                      }
                      type="text"
                      min="1"
                      step="10"
                      id="_sellToAddress"
                      keyboardtype="decimal-pad"
                      autoComplete="off"
                      className={`text-right m-2 w-full  transition-all rounded-md px-4 outline-non  text-slate-600 text-3xl font-bold  ${
                        !isPublic
                          ? "bg-white focus:outline-none active:outline-none"
                          : "bg-white/10 text-gray-500 opacity-40"
                      }`}
                    />
                  </span>
                </span>
              </div>
              <div className="w-4/5 flex flex-row justify-end">
                <div
                  onClick={() => {
                    setInfo("");
                    setError("");
                    setState(0);
                  }}
                  className="w-1/2 self-end bg-blue-600 text-gray-200   text-3xl font-bold p-2 m-2 my-4 rounded-xl hover:scale-105 transition-all shadow-lg cursor-pointer"
                >
                  Cancel
                </div>
                <div
                  onClick={() => {
                    const _newPrice =
                      document.getElementById("_sellingPrice").value;

                    if (_newPrice > 0 || nftPrice > 0) {
                      /*document.getElementById(
                                                '_sellingPrice'
                                            ).value*/

                      if (isPublic) {
                        // GO NEXT
                        console.log("GO NEXT");
                        setInfo("Please confirm Metamask");
                        setError("");
                        editList(
                          listID,
                          _newPrice > 0 ? _newPrice : nftPrice,
                          "0x0000000000000000000000000000000000000000"
                        );
                        //

                        setState(4);
                      } else {
                        if (
                          ethers.utils.isAddress(
                            document.getElementById("_sellToAddress").value
                          ) &&
                          document.getElementById("_sellToAddress").value !=
                            "0x0000000000000000000000000000000000000000"
                        ) {
                          //GO NEXT
                          setInfo("Please confirm Metamask");
                          setError("");

                          editList(
                            listID,
                            _newPrice > 0 ? _newPrice : nftPrice,
                            document.getElementById("_sellToAddress").value
                          );

                          setState(4);
                        } else if (
                          nftBuyer !=
                          "0x0000000000000000000000000000000000000000"
                        ) {
                          setInfo("Please confirm Metamask");
                          setError("");
                          editList(listID, _newPrice, nftBuyer);
                          setState(4);
                        } else {
                          setInfo("");
                          setError("Invalid Sell to Address");
                        }
                      }
                    } else {
                      setInfo("");
                      setError("Please enter New Selling Price");
                    }
                  }}
                  className="w-1/2 self-end bg-blue-600 text-gray-200  text-3xl font-bold p-2 m-2 my-4 rounded-xl hover:scale-105 transition-all shadow-lg cursor-pointer"
                >
                  Save
                </div>
              </div>
            </>
          ) : null}

          <br />
        </div>
        <br />
      </Backdrop>
    </div>
  );
}

export default Marketplace;
