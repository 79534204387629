import React, { useEffect, useState } from 'react'
import { TxList } from '../components'
import { FaArrowAltCircleDown } from 'react-icons/fa'
import { BiLoaderAlt } from 'react-icons/bi'
import { RiCloseCircleFill } from 'react-icons/ri'
import { ethers } from 'ethers'
import Select from 'react-select'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import AnimatedNumber from 'animated-number-react'
import AddNetwork from './AddNetwork'
import WalletConnectProvider from '@walletconnect/web3-provider'
import { useSelector } from 'react-redux'
/* global BigInt */
const USDTAddr = '0x55d398326f99059fF775485246999027B3197955'
const BUSDAddr = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
const CCSXAddr = '0x1819615B0643c54A6096af42a72C761f85E7ec37'
const CCOIN = '0xc209831f7349D4E200d420326B3401899Ab9Ae68' //BSC Mainnet
const pool = '0xb6e973aC3757FB4a28e649fA807d41dc3Fc327c1'

// PACKAGE CONFIG //
const package1Price = 50
const package1Get = 1750 + 250
const package2Price = 200
const package2Get = 7000 + 1250
const package3Price = 500
const package3Get = 17500 + 3500
const package4Price = 1000
const package4Get = 35000 + 8000
////////////////////

const formatValue = (value) => `${separator(Number(value).toFixed(2))}`

const isMetaMaskInstalled = () => {
    const { ethereum } = window
    return Boolean(ethereum && ethereum.isMetaMask)
}

function separator(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function Loader() {
    return (
        <button
            type="button"
            className="text-gray-500 w-full mt-2 border-[1px] p-2 border-gray-300 rounded-full cursor-not-allowed flex flex-row items-center justify-center"
        >
            <BiLoaderAlt className="animate-spin mr-2" />
            <span className="animate-pulse">Processing... </span>
        </button>
    )
}

function Exchange() {
    const [ccoin, setCcoin] = useState(350)
    const [usdt, setUsdt] = useState(0)
    const [isProcess, setIsProcess] = useState(false)
    const [error, setError] = useState()
    const [info, setInfo] = useState()
    const [ustdBalance, setUsdtBalance] = useState(0)
    const [open, setOpen] = useState(false)
    const [focusedInput, setFocus] = useState(null)
    const [coin, setCoin] = useState(USDTAddr)
    const [rate, setRate] = useState()
    const [account, setAccount] = useState(null)
    const wc = useSelector((state) => state.walletConnect.value)
    let bonus = 0
    let total = 0
    useEffect(() => {
        if (coin == CCSXAddr) {
            setCcoin(usdt)
            bonus = 0
        } else {
            setCcoin(usdt * rate)
        }

        return () => {}
    }, [ccoin, usdt, focusedInput, coin])

    const handleClose = () => {
        setOpen(false)
    }
    const handleToggle = () => {
        setOpen(!open)
    }
    let provider
    let ethereum = window.ethereum
    let tempSigner

    if (!!wc.account) {
        console.log('WC!')
        ethereum = wc.provider
        provider = wc.web3Provider
        tempSigner = provider.getSigner()
    } else if (isMetaMaskInstalled()) {
        console.log('MT!')
        ethereum = window.ethereum
        provider = new ethers.providers.Web3Provider(ethereum)
        tempSigner = provider.getSigner()
    }

    let ierc20 = require('../abi/IERC20')
    const abi = require('../abi/Pool')

    const tokenContract = new ethers.Contract(coin, ierc20, tempSigner)

    if (usdt == package1Price && coin != CCSXAddr) {
        bonus = package1Get - ccoin
        total = package1Get
    } else if (usdt == package2Price && coin != CCSXAddr) {
        bonus = package2Get - ccoin
        total = package2Get
    } else if (usdt == package3Price && coin != CCSXAddr) {
        bonus = package3Get - ccoin
        total = package3Get
    } else if (usdt == package4Price && coin != CCSXAddr) {
        bonus = package4Get - ccoin
        total = package4Get
    } else {
        total = ccoin
        bonus = 0
    }

    async function getCurrentAccount() {
        const accounts = await ethereum.request({ method: 'eth_accounts' })
        console.log('accounts[0]:', accounts[0])
        return accounts[0]
    }

    getCurrentAccount()

    getUserBalance()
    getCCoinRate()
    async function getUserBalance() {
        await tokenContract.balanceOf(getCurrentAccount()).then((x) => {
            setUsdtBalance(x / 10 ** 18)
            console.log('balance', (x / 10 ** 18).toFixed(2))
        })
    }

    async function getCCoinRate() {
        const cPool = new ethers.Contract(pool, abi, tempSigner)
        const cRate = await cPool.callStatic._ccoinPrice()
        setRate(Number(cRate.toString()))
        console.log('getCCoinRate():', Number(cRate.toString()))
    }

    async function buy(ccoin) {
        //document.getElementById('usdt').setAttribute('disabled', 'disabled')
        const tx = await tokenContract
            .transfer(pool, ethers.utils.parseUnits(ccoin, 18))
            .catch((error) => {
                setIsProcess(false)
                setError('Payment error, please check your wallet balance')
                handleClose()
            })

        setInfo('Please wait for a block confirmation')

        const receipt = await tx
            .wait(1)
            .then((x) => {
                //console.log('tx:', x)
                console.log('txHash:', x.transactionHash)
                if (x.confirmations >= 1) {
                    console.log('Confirmed:', x.confirmations)
                }
                console.log('USDT Check :', x.to) // USDT contract address
                setUsdt(0)
                getUserBalance()
                setIsProcess(false)

                handleClose()
                setInfo('')
            })
            .catch((error) => {
                console.log('error code:', error.code)
                console.error(error)
                setIsProcess(false)
                //document.getElementById('usdt').removeAttribute('disabled')
                handleClose()
            })
    }

    const options = [
        {
            value: 'usdt',
            label: (
                <div className="flex flex-row text-sm font-bold justify-between items-center">
                    <img src={process.env.PUBLIC_URL + '/img/coins/usdt.png'} />
                    USDT
                </div>
            ),
        },
        {
            value: 'busd',
            label: (
                <div className="flex flex-row text-sm font-bold justify-between items-center">
                    <img src={process.env.PUBLIC_URL + '/img/coins/busd.png'} />
                    BUSD
                </div>
            ),
        },
    ]

    function onSelect(optionSelected) {
        console.log('optionSelected.value', optionSelected.value)
        if (optionSelected.value == 'usdt') {
            setCoin(USDTAddr)
        } else if (optionSelected.value == 'busd') {
            setCoin(BUSDAddr)
        } else if (optionSelected.value == 'ccsx') {
            setCoin(CCSXAddr)
        }
        getUserBalance()
        setFocus('usd')
    }

    return (
        <div className="flex flex-col w-full justifyitem-center items-center mt-20 select-none">
            <AddNetwork CCOIN={CCOIN} CCSX={CCSXAddr} />
            <div className="md:w-1/3 w-full p-5 flex flex-col justifyitem-center items-center drop-shadow-lg rounded-3xl border-[#4b4314] bg-neutral-100 bg-opacity-70 backdrop-blur-xl">
                <span className="text-blue-400 font-bold text-sm my-1">
                    <br />
                </span>
                <div className=" blur-sm absolute -top-4 right-4 text-3xl md:text-4xl self-center -mb-3 font-bold text-yellow-700 break-all">
                    B U Y - C C O I N
                </div>
                <div className=" absolute -top-5 right-5 text-3xl md:text-4xl self-center -mb-3 font-bold text-white break-all">
                    B U Y - C C O I N
                </div>
                <div className="rounded-2xl border border-gray-300 w-full bg-white">
                    <div className=" w-full flex justify-between my-0">
                        <span className="text-slate-500 text-xs p-3">
                            You sell
                        </span>
                        <span className="text-slate-500 text-xs p-3 flex flex-row">
                            <div
                                onClick={() => setUsdt(Number(usdt) + 10)}
                                className="rounded-md border border-gray-300 p-1 ml-1 hover:cursor-pointer"
                            >
                                10
                            </div>
                            <div
                                onClick={() => setUsdt(Number(usdt) + 50)}
                                className="rounded-md border border-rose-500 p-1 ml-1 hover:cursor-pointer"
                            >
                                50
                            </div>
                            <div
                                onClick={() => setUsdt(Number(usdt) + 100)}
                                className="rounded-md border border-gray-300 p-1 ml-1 hover:cursor-pointer"
                            >
                                100
                            </div>
                            <div
                                onClick={() => setUsdt(Number(usdt) + 200)}
                                className="rounded-md border border-rose-500 p-1 ml-1 hover:cursor-pointer"
                            >
                                200
                            </div>
                            <div
                                onClick={() => setUsdt(Number(usdt) + 500)}
                                className="rounded-md border border-rose-500 p-1 ml-1 hover:cursor-pointer"
                            >
                                500
                            </div>
                            <div
                                onClick={() => setUsdt(Number(usdt) + 1000)}
                                className="rounded-md border border-rose-500 p-1 ml-1 hover:cursor-pointer"
                            >
                                1,000
                            </div>
                            <div
                                onClick={() =>
                                    setUsdt(Number(ustdBalance.toFixed(0)))
                                }
                                className="rounded-md border border-gray-300 p-1 ml-1 hover:cursor-pointer"
                            >
                                Max
                            </div>
                        </span>
                    </div>
                    <div className=" w-full flex justify-between">
                        <Select
                            defaultValue={options[0]}
                            options={options}
                            className="w-1/3 m-2"
                            onChange={onSelect}
                        />
                        <input
                            placeholder="USDT"
                            type="text"
                            pattern="[0-9]*"
                            min="400"
                            step="1"
                            id="usdt"
                            keyboardtype="decimal-pad"
                            value={usdt}
                            autoComplete="off"
                            onFocus={() => setFocus('usd')}
                            onChange={(e) => {
                                const re = /^[0-9\b]+$/
                                if (
                                    e.target.value === '' ||
                                    re.test(e.target.value)
                                ) {
                                    setUsdt(e.target.value)
                                }
                            }}
                            className="text-right my-2 w-2/5 active:outline-none rounded-sm pr-10 outline-non bg-transparent text-slate-500 border-none text-3xl font-bold focus:outline-none"
                        />
                        <RiCloseCircleFill
                            className="absolute right-10 text-slate-400/50 hover:text-rose-500 hover:cursor-pointer"
                            fontSize={18}
                            onClick={() => {
                                setUsdt(0)
                                setFocus('usd')
                            }}
                        />
                    </div>

                    <div className=" w-full flex justify-between my-0">
                        <span className="text-slate-500 text-sm p-3">
                            Balance{' '}
                        </span>

                        <span className="text-slate-500 text-sm p-3 pr-10">
                            $
                            <AnimatedNumber
                                className="text-md font-medium mt-5"
                                value={ustdBalance}
                                formatValue={formatValue}
                                duration="600"
                            />
                        </span>
                    </div>
                </div>
                <FaArrowAltCircleDown
                    fontSize={32}
                    color="#6778a5"
                    className="-m-3 z-10"
                />
                <div className="rounded-2xl border border-gray-300 w-full bg-white">
                    <div className=" w-full flex justify-between my-0">
                        <span className="text-slate-500 text-xs p-3">
                            You buy
                        </span>{' '}
                        <span className="text-slate-500 text-xs p-3"></span>
                    </div>
                    <div className="w-full flex justify-between">
                        <span className="p-3 text-md text-slate-500 flex flex-row">
                            <img
                                width="40"
                                className="pr-3 "
                                src={
                                    process.env.PUBLIC_URL +
                                    '/img/coins/ccoin.png'
                                }
                            />
                            CCOIN
                        </span>
                        <div />
                    </div>
                    <div className="w-full flex justify-between">
                        <span className="px-10 text-sm text-slate-500 flex flex-row">
                            CCOIN
                        </span>
                        <AnimatedNumber
                            className="px-10 text-sm text-slate-500 flex flex-row"
                            value={ccoin}
                            formatValue={formatValue}
                            duration="600"
                        />
                    </div>
                    <div className="w-full flex justify-between">
                        <span className="px-10 text-sm text-slate-500 flex flex-row">
                            Bonus
                        </span>

                        <AnimatedNumber
                            className="px-10 text-sm text-slate-500 flex flex-row"
                            value={bonus}
                            formatValue={formatValue}
                            duration="600"
                        />
                    </div>
                    <hr className="w-full mt-2" />
                    <div className="w-full flex justify-between my-3">
                        <span className="px-10 text-xl text-slate-500 flex flex-row">
                            Total
                        </span>

                        <AnimatedNumber
                            className="px-10 text-xl text-slate-500 flex flex-row"
                            value={total}
                            formatValue={formatValue}
                            duration="600"
                        />
                    </div>
                </div>
                {isProcess ? (
                    <Loader />
                ) : (
                    <button
                        type="button"
                        onClick={() => {
                            if (usdt < 1) {
                                setError('$1 Minimum Purchase required')
                            } else {
                                setInfo(
                                    'Waiting for your confirmation on Metamask'
                                )
                                handleToggle()
                                setIsProcess(true)
                                setError('')
                                buy(usdt.toString())
                            }
                        }}
                        className="text-white w-full mt-2 border-[1px] p-2  bg-[#6778a5] rounded-xl cursor-pointer transition-all hover:bg-[#ffae00]"
                    >
                        Purchase
                    </button>
                )}
                <span className="text-red-600 font-bold text-sm my-1">
                    {error}
                </span>
            </div>
            <div className=" w-1/3 flex justify-between m-3 px-10">
                <span className="text-gray-500 text-sm my-1">Rate USDx</span>
                <span className="text-gray-500 text-sm my-1">{rate} ccoin</span>
            </div>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
                className="flex flex-col"
            >
                <div className="bg-white m-4 text-center text-gray-900 text-lg p-3 rounded-lg md:w-2/5 w-full display-linebreak">
                    <CircularProgress color="inherit" />
                    <br />
                    {info}
                </div>
                <br />
            </Backdrop>
        </div>
    )
}

export default Exchange
