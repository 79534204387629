const info = {
    ccom: {
        name: 'C-COMMUNITY',
        contract: '0xee83458833b0179413936c83303875a61Efd097F',
        relayer: '0x0947B825c5B850c4dcd65F82fb5D1EF51b87c332',
        ctcm: 'cmct',
        rycm: 'cmry',
        color: '#4682B4',
    },
    ccoinbot: {
        name: 'C-COINBOT',
        contract: '0xDE3c17D14345a783A74890DD1fBcE7c86C3bB998',
        relayer: '0x4c271a23b1C32EC8fd3c23d6f60D9467f19b4Fe6',
        ctcm: 'cbct',
        rycm: 'cbry',
        color: '#8FBC8B',
    },
    cweb3: {
        name: 'C-Web3',
        contract: '0x3D9f675Df01874d62DB49a256763e6242Bc14B6d',
        relayer: '0x9fA87BE43cf0397d08A9FF98aC79C93c5d8130c9',
        ctcm: 'cwct',
        rycm: 'cwry',
        color: '#6666ff',
    },
};

const coins = {
    ccoin: {
        address: '0xc209831f7349D4E200d420326B3401899Ab9Ae68',
        symbo: 'CCOIN',
        img: 'https://bscscan.com/token/images/crypteriumcoin__32.png',
    },
    usdt: {
        address: '0x55d398326f99059ff775485246999027b3197955',
        symbo: 'USDT',
        img: 'https://bscscan.com/token/images/busdt_32.png',
    },
    bnb: {
        address: '',
        symbo: 'BNB',
        img: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png',
    },
};

module.exports = { info, coins };
