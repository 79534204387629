import React, { useState, useEffect } from 'react'
import { PaginationProvider } from './PaginationContext'
import { usePagination } from './PaginationContext'
import { AiOutlineCopy } from 'react-icons/ai'
import { MdRefresh } from 'react-icons/md'
import {
    BsBoxArrowRight,
    BsBoxArrowInLeft,
    BsBoxArrowLeft,
} from 'react-icons/bs'

import AnimatedNumber from 'animated-number-react'
import Links from './Links'
import axios from 'axios'
import { ethers } from 'ethers'
const { coins } = require('../info')
const API_URL = 'https://api.bscscan.com' // Mainnet
const APIKey = '7WM7AWTBBGCZNESCGTUJH5DRN8SJ1K1ZFH'
const domain = 'https://autopayment.cpay.global'
const relayers = [
    '0x0947b825c5b850c4dcd65f82fb5d1ef51b87c332',
    '0x4c271a23b1C32EC8fd3c23d6f60D9467f19b4Fe6',
    '0x57A358d6129c5ce8Ac62C6C787f86C18BdFA269B',
    '0x19Be01f1fb476A3E7994926962740d797fc6691A',
    '0x0aF2f3D511f217A1a69A53205765877587b8a28C',
]

const tronrelayer = 'TRmk9sMXxarQPUo3do3KY6nfiUK38AkjNE'
const tronAPI = `https://apilist.tronscanapi.com/api/accountv2?address=TRmk9sMXxarQPUo3do3KY6nfiUK38AkjNE`
const CCOIN = process.env.REACT_APP_CCOIN
const CCSX = process.env.REACT_APP_CCSX

function separator(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function MonitorComponent() {
    const [monitorData, setMonitorData] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        fetchMonitorData()
        const interval = setInterval(fetchMonitorData, 3000)
        return () => clearInterval(interval)
    }, [])

    async function fetchMonitorData() {
        try {
            const response = await axios.get(domain + '/monitor')
            setMonitorData(response.data)
            setLoading(false)
        } catch (error) {
            console.error('Error fetching monitor data:', error)
            setLoading(false)
        }
    }

    async function togglePause() {
        try {
            const response = await axios.post(domain + '/togglepause', null, {
                headers: {
                    'x-password': document.getElementById('togglepause').value,
                },
            })
            fetchMonitorData()
        } catch (error) {
            console.error('Error Toggle Pause:', error)
        }
    }

    if (loading) {
        return <div>Loading...</div>
    }

    return (
        <div className="grid grid-flow-row grid-cols-1 md:grid-cols-3 gap-1 w-full text-sm">
            <MdRefresh
                className="absolute right-8 font-lg cursor-pointer"
                onClick={() => fetchMonitorData()}
            />
            <div className="col-span-1 md:col-span-3">
                <span className="font-bold ">Start Time:</span>{' '}
                {monitorData['Start Time']}
            </div>
            <div className="col-span-1 md:col-span-3">
                <span className="font-bold text-sm">Status:</span>{' '}
                <span
                    className={`${
                        monitorData.Status == 'Running'
                            ? 'text-green-600'
                            : 'text-yellow-600'
                    }`}
                >
                    {monitorData.Status}
                </span>
                <input
                    id="togglepause"
                    type="password"
                    autoComplete="off"
                    className=" m-1 w-ful md:w-1/4 rounded-lg px-1 text-sm text-black font-bold active:outline-none focus:outline-none text-center"
                />
                <button
                    className=" m-1 w-16 bg-[#474747] rounded-lg p-1 text-xs text-white transition-all hover:bg-[#ffae00]"
                    onClick={() => {
                        togglePause()
                    }}
                >
                    {`${monitorData.Status == 'Running' ? 'Pause' : 'Resume'}`}
                </button>
            </div>

            <div className="col-span-1 flex flex-col justify-center items-center border rounded-md p-2 border-[#4b4314]">
                <span className="font-bold text-sm">Tx Queue</span>
                <span className="text-lg">{monitorData['Tx Queue']}</span>
            </div>
            <div className="col-span-1 flex flex-col justify-center items-center border rounded-md p-2 border-[#4b4314]">
                <span className="font-bold text-sm">Available Buffer</span>
                <span className="text-lg">
                    {monitorData['Avaliable Buffer']}
                </span>
            </div>
            <div className="col-span-1 flex flex-col justify-center items-center border rounded-md p-2 border-[#4b4314]">
                <span className="font-bold text-sm">Next Queue in</span>
                <span className="text-lg">{monitorData['Next Queue in']}</span>
            </div>
            <div className="col-span-1 flex flex-col justify-center items-center border rounded-md p-2 border-[#4b4314]">
                <span className="font-bold text-sm">Completed Tx</span>
                <span className="text-lg">{monitorData['Total Tx']}</span>
            </div>
            <div className="col-span-1 flex flex-col justify-center items-center border rounded-md p-2 border-[#4b4314]">
                <span className="font-bold text-sm">Throttled Tx</span>
                <span className="text-lg">{monitorData['Throttled Tx']}</span>
            </div>
            <div className="col-span-1 flex flex-col justify-center items-center border rounded-md p-2 border-[#4b4314]">
                <span className="font-bold text-sm">Fail Tx</span>
                <span className="text-lg text-red-600">
                    {monitorData['Fail Tx']}
                </span>
            </div>
        </div>
    )
}

function Queue() {
    const [monitorData, setMonitorData] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        fetchMonitorData()
        const interval = setInterval(fetchMonitorData, 3000)
        return () => clearInterval(interval)
    }, [])

    async function fetchMonitorData() {
        try {
            const response = await axios.get(domain + '/monitor')
            setMonitorData(response.data)
            setLoading(false)
        } catch (error) {
            console.error('Error fetching monitor data:', error)
            setLoading(false)
        }
    }

    async function qrt(_ref) {
        console.log('_ref:', _ref)
        try {
            const response = await axios.post(
                domain + '/qrt',
                {
                    ref: _ref,
                },
                {
                    headers: {
                        'x-password': document.getElementById('qrtpass').value,
                    },
                }
            )

            if (response.status === 200) {
                // Successful response
                console.log('Response:', response.data)
                fetchMonitorData() // Assuming this function updates your data
            } else {
                // Handle other status codes if needed
                console.error('Unexpected status code:', response.status)
            }
        } catch (error) {
            // Handle network errors or other exceptions
            console.error('Error quarantine:', error)
        }
    }

    function DataTable({ data }) {
        const itemsPerPage = 60
        //const [currentPage, setCurrentPage] = useState(1)
        const [hiddenItems, setHiddenItems] = useState([])
        const { currentPage, setCurrentPage } = usePagination()
        const totalPages = Math.ceil(data.length / itemsPerPage)
        const startIndex = (currentPage - 1) * itemsPerPage
        const endIndex = startIndex + itemsPerPage

        const handlePageChange = (page) => {
            setCurrentPage(page)
        }

        const handleHideDiv = (ref) => {
            setHiddenItems((prevHiddenItems) => [...prevHiddenItems, ref])
        }

        return (
            <>
                <div className="pagination bg-white/75 py-1 rounded flex justify-center">
                    Page:
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index}
                            onClick={() => handlePageChange(index + 1)}
                            className={`mx-1 px-2 pagination-button rounded-md ${
                                currentPage === index + 1
                                    ? 'bg-[#4b4314] text-white'
                                    : ''
                            } `}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
                {data.slice(startIndex, endIndex).map((tx, index) => {
                    if (hiddenItems.includes(tx.ref)) {
                        return null // Hide this div
                    }
                    return (
                        <div
                            key={index + 2000}
                            id={tx.ref}
                            className="col-span-1 bg-white/30 mt-1 rounded-md md:col-span-3 grid grid-flow-row grid-cols-10 gap-1 w-full text-sm hover:bg-white/70"
                        >
                            <div className="col-span-1 w-full justify-center place-items-center text-mg text-grey-800 flex flex-row rounded-md p-1 bg-white/5 ">
                                {startIndex + index + 1}
                            </div>
                            <div
                                className="col-span-2 w-full justify-center place-items-center text-mg text-grey-800 flex flex-row rounded-md p-1 bg-white/5 cursor-pointer hover:text-[#ffae00]"
                                onClick={() =>
                                    navigator.clipboard.writeText(
                                        '/qrt' + tx.ref
                                    )
                                }
                            >
                                {tx.ref}
                            </div>
                            <div
                                className="col-span-3 w-full justify-center place-items-center text-mg text-grey-800 flex flex-row rounded-md p-1 bg-white/5 cursor-pointer hover:text-[#ffae00]"
                                onClick={() =>
                                    navigator.clipboard.writeText(tx.recipient)
                                }
                            >
                                {tx.recipient.slice(0, 5) +
                                    '....' +
                                    tx.recipient.slice(-5)}
                            </div>
                            <div className="col-span-3 flex flex-row  items-center justify-between">
                                <img
                                    className="w-[18px] h-[18px] items-center mx-2 "
                                    src={
                                        tx.token == coins.ccoin.address
                                            ? coins.ccoin.img
                                            : tx.token == coins.usdt.address
                                            ? coins.usdt.img
                                            : coins.ccoin.img
                                    }
                                />
                                <span className="items items-end pr-2">
                                    {separator(
                                        ethers.utils.formatEther(tx.amount)
                                    )}
                                </span>
                            </div>
                            {monitorData.Status == 'Running' ? (
                                <button className="col-span-1 m-1 flex justify-center bg-gray-400 rounded-lg p-1 text-xs text-white transition-all disabled cursor-default">
                                    <BsBoxArrowRight />
                                </button>
                            ) : (
                                <button
                                    className="col-span-1 m-1 flex justify-center bg-red-800 rounded-lg p-1 text-xs text-white transition-all hover:bg-red-300"
                                    onClick={() => {
                                        qrt(tx.ref).then(() =>
                                            handleHideDiv(tx.ref)
                                        )
                                    }}
                                >
                                    <BsBoxArrowRight />
                                </button>
                            )}
                        </div>
                    )
                })}
            </>
        )
    }

    if (loading) {
        return <div>Loading...</div>
    }

    return (
        <PaginationProvider>
            <div className="grid grid-flow-row grid-cols-1 md:grid-cols-3 gap-1 w-full text-sm">
                <MdRefresh
                    className="absolute right-8 font-lg cursor-pointer"
                    onClick={() => fetchMonitorData()}
                />

                <div className="col-span-1 md:col-span-3">
                    <span className="font-bold text-sm">Status:</span>{' '}
                    <span
                        className={`${
                            monitorData.Status == 'Running'
                                ? 'text-green-600'
                                : 'text-yellow-600'
                        }`}
                    >
                        {monitorData.Status}
                    </span>
                    <input
                        id="qrtpass"
                        type="password"
                        autoComplete="off"
                        className=" m-1 w-ful md:w-1/4 rounded-lg px-1 text-sm text-black font-bold active:outline-none focus:outline-none text-center"
                    />
                </div>

                <div className="col-span-1 md:col-span-3">
                    <span className="font-bold "></span>
                    <DataTable data={monitorData['requestQueue']} />
                </div>
            </div>
        </PaginationProvider>
    )
}

function Quarantine() {
    const [monitorData, setMonitorData] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        fetchMonitorData()
        const interval = setInterval(fetchMonitorData, 3000)
        return () => clearInterval(interval)
    }, [])

    async function fetchMonitorData() {
        try {
            const response = await axios.get(domain + '/monitor')
            setMonitorData(response.data)
            setLoading(false)
        } catch (error) {
            console.error('Error fetching monitor data:', error)
            setLoading(false)
        }
    }

    async function acq(_ref) {
        console.log('_ref:', _ref)
        try {
            const response = await axios.post(
                domain + '/acq',
                {
                    ref: _ref,
                },
                {
                    headers: {
                        'x-password': document.getElementById('qrtpass').value,
                    },
                }
            )

            if (response.status === 200) {
                // Successful response
                console.log('Response:', response.data)
                fetchMonitorData() // Assuming this function updates your data
            } else {
                // Handle other status codes if needed
                console.error('Unexpected status code:', response.status)
            }
        } catch (error) {
            // Handle network errors or other exceptions
            console.error('Error quarantine:', error)
        }
    }

    function DataTable({ data }) {
        const itemsPerPage = 10
        const { currentPage, setCurrentPage } = usePagination()
        const [hiddenItems, setHiddenItems] = useState([])

        const totalPages = Math.ceil(data.length / itemsPerPage)
        const startIndex = (currentPage - 1) * itemsPerPage
        const endIndex = startIndex + itemsPerPage

        const handlePageChange = (page) => {
            setCurrentPage(page)
        }

        const handleHideDiv = (ref) => {
            setHiddenItems((prevHiddenItems) => [...prevHiddenItems, ref])
        }

        return (
            <>
                <div className="pagination bg-white/75 py-1 rounded flex justify-center">
                    Page:
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index}
                            onClick={() => handlePageChange(index + 1)}
                            className={`mx-1 px-2 pagination-button rounded-md ${
                                currentPage === index + 1
                                    ? 'bg-[#4b4314] text-white'
                                    : ''
                            } `}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
                {data.slice(startIndex, endIndex).map((tx, index) => {
                    if (hiddenItems.includes(tx.ref)) {
                        return null // Hide this div
                    }
                    return (
                        <div
                            key={index + 2000}
                            id={tx.ref}
                            className="col-span-1 bg-red-200 mt-1 rounded-md md:col-span-3 grid grid-flow-row grid-cols-10 gap-1 w-full text-sm hover:bg-red-300"
                        >
                            <div className="col-span-1 w-full justify-center place-items-center text-mg text-grey-800 flex flex-row rounded-md p-1 bg-white/5 ">
                                {startIndex + index + 1}
                            </div>
                            <div
                                className="col-span-2 w-full justify-center place-items-center text-mg text-grey-800 flex flex-row rounded-md p-1 bg-white/5 cursor-pointer hover:text-[#ffae00]"
                                onClick={() =>
                                    navigator.clipboard.writeText(
                                        '/acq' + tx.ref
                                    )
                                }
                            >
                                {tx.ref}
                            </div>
                            <div
                                className="col-span-3 w-full justify-center place-items-center text-mg text-grey-800 flex flex-row rounded-md p-1 bg-white/5 cursor-pointer hover:text-[#ffae00]"
                                onClick={() =>
                                    navigator.clipboard.writeText(tx.recipient)
                                }
                            >
                                {tx.recipient.slice(0, 5) +
                                    '....' +
                                    tx.recipient.slice(-5)}
                            </div>
                            <div className="col-span-3 flex flex-row  items-center justify-between">
                                <img
                                    className="w-[18px] h-[18px] items-center mx-2 "
                                    src={
                                        tx.token == coins.ccoin.address
                                            ? coins.ccoin.img
                                            : tx.token == coins.usdt.address
                                            ? coins.usdt.img
                                            : coins.ccoin.img
                                    }
                                />
                                <span className="items items-end pr-2">
                                    {separator(
                                        ethers.utils.formatEther(tx.amount)
                                    )}
                                </span>
                            </div>
                            <button
                                className="col-span-1 m-1 flex justify-center bg-blue-600 rounded-lg p-1 text-xs text-white transition-all hover:bg-blue-300"
                                onClick={() => {
                                    acq(tx.ref).then(() =>
                                        handleHideDiv(tx.ref)
                                    )
                                }}
                            >
                                <BsBoxArrowLeft />
                            </button>
                        </div>
                    )
                })}
            </>
        )
    }

    if (loading) {
        return <div>Loading...</div>
    }

    return (
        <PaginationProvider>
            <div className="grid grid-flow-row grid-cols-1 md:grid-cols-3 gap-1 w-full text-sm ">
                <MdRefresh
                    className="absolute right-8 font-lg cursor-pointer"
                    onClick={() => fetchMonitorData()}
                />

                <div className="col-span-1 md:col-span-3">
                    <span className="font-bold "></span>
                    <DataTable data={monitorData['quarantineTx']} />
                </div>
            </div>
        </PaginationProvider>
    )
}

function Relayers() {
    const [balances, setBalances] = useState({})
    const [TRX, setTRX] = useState()

    useEffect(() => {
        getTRXBalance()
        relayers.forEach(async (relayer) => {
            const response = await fetch(
                `${API_URL}/api?module=account&action=balance&address=${relayer}&apikey=${APIKey}`
            )
            const data = await response.json()
            setBalances((prev) => ({
                ...prev,
                [relayer]: data.result / 10 ** 18,
            }))
        })
        // Add other fetch functions like getETHBalance and getTRXBalance here if needed
    }, [])

    async function getTRXBalance() {
        const response = await fetch(tronAPI)
        const myJson = await response.json().then((x) => {
            setTRX(x.balance / 10 ** 6)
        })
    }

    return (
        <div className="flex flex-col w-full justifyitem-center items-center mt-20 h-screen select-none">
            <Links CCOIN={CCOIN} CCSX={CCSX} />

            <div className="mt-10 md:w-3/5 w-full flex flex-row p-5 justifyitem-center mb-10 items-center drop-shadow-lg rounded-xl text-black bg-neutral-100 bg-opacity-70 backdrop-blur-xl">
                <span className="text-blue-400 font-bold text-sm my-1">
                    <br />
                </span>
                <div className="blur-sm absolute -top-4 right-4 text-3xl md:text-4xl self-center -mb-3 font-bold text-yellow-700 break-all">
                    R e l a y e r s
                </div>
                <div className="absolute -top-5 right-5 text-3xl md:text-4xl self-center -mb-3 font-bold text-white break-all">
                    R e l a y e r s
                </div>
                <div className="w-full mt-5 grid grid-flow-row md:grid-cols-3 grid-cols-1 gap-1">
                    {relayers.map((relayer, i) => (
                        <div
                            key={relayer}
                            className="col-span-1 justify-self-center text-mg text-grey-500 flex flex-col border rounded-md w-full p-2 border-[#4b4314]"
                        >
                            <span className="text-xs font-medium mt-2">
                                Relayer {i + 2} Balance
                            </span>
                            <div className="w-full justify-center place-items-center text-mg text-grey-800 flex flex-row rounded-md p-1 bg-white/5">
                                {relayer.slice(0, 6) +
                                    '....' +
                                    relayer.slice(-4)}
                                <a href="#">
                                    <AiOutlineCopy
                                        className="text-lg transition-all hover:text-[#ffae00] ml-2"
                                        onClick={() =>
                                            navigator.clipboard.writeText(
                                                relayer
                                            )
                                        }
                                    />
                                </a>
                            </div>
                            <span className="flex flex-row place-items-center justify-center">
                                <AnimatedNumber
                                    className="text-xl font-medium"
                                    value={balances[relayer]}
                                    formatValue={(value) =>
                                        `${separator(Number(value).toFixed(3))}`
                                    }
                                    duration="600"
                                />
                                <span className="text-xs font-bold mt-1 ml-4">
                                    BNB
                                </span>
                            </span>
                        </div>
                    ))}
                    {/* ------------------------------ TRX RELAYER  ------------------------------ */}
                    <div className="col-span-1 justify-self-center text-mg text-grey-500 flex flex-col border rounded-md w-full p-2 border-[#4b4314]">
                        <span className="text-xs font-medium mt-2">
                            Tron Relayer Balance
                        </span>

                        <div className="w-full justify-center place-items-centertext-mg text-grey-800 flex flex-row rounded-md p-1 bg-white/5">
                            {tronrelayer.slice(0, 6) +
                                '....' +
                                tronrelayer.slice(-4)}{' '}
                            <a href="#">
                                <AiOutlineCopy
                                    className="text-lg transition-all hover:text-[#ffae00] ml-2"
                                    onClick={() =>
                                        navigator.clipboard.writeText(
                                            tronrelayer
                                        )
                                    }
                                />
                            </a>
                        </div>
                        <span className="flex flex-row place-items-center justify-center">
                            <AnimatedNumber
                                className="text-xl font-medium"
                                value={TRX}
                                formatValue={(value) =>
                                    `${separator(Number(value).toFixed(3))}`
                                }
                                duration="600"
                            />
                            <span className="text-xs font-bold mt-1 ml-4">
                                TRX
                            </span>
                        </span>
                    </div>
                </div>
            </div>

            {/* ------------------------------ Tx Queue  ------------------------------ */}
            <div className="mt-10 md:w-3/5 w-full flex flex-row p-5 justifyitem-center mb-10 items-center drop-shadow-lg rounded-xl text-black bg-neutral-100 bg-opacity-70 backdrop-blur-xl">
                <span className="text-blue-400 font-bold text-sm my-1">
                    <br />
                </span>
                <div className="blur-sm absolute -top-4 right-4 text-3xl md:text-4xl self-center -mb-3 font-bold text-yellow-700 break-all">
                    T x Q u e u e
                </div>
                <div className="absolute -top-5 right-5 text-3xl md:text-4xl self-center -mb-3 font-bold text-white break-all">
                    T x Q u e u e
                </div>
                <div className="w-full mt-5">
                    <div className="w-full justify-self-center text-mg text-grey-500 border rounded-md p-2 border-[#4b4314]">
                        <MonitorComponent />
                    </div>
                </div>
            </div>
            {/* ------------------------------ Q u a r a n t i n e ------------------------------ */}
            <div className="mt-10 md:w-3/5 w-full flex flex-row p-5 justifyitem-center mb-10 items-center drop-shadow-lg rounded-xl text-black bg-neutral-100 bg-opacity-70 backdrop-blur-xl">
                <span className="text-blue-400 font-bold text-sm my-1">
                    <br />
                </span>
                <div className="blur-sm absolute -top-4 right-4 text-3xl md:text-4xl self-center -mb-3 font-bold text-yellow-700 break-all">
                    Q u a r a n t i n e
                </div>
                <div className="absolute -top-5 right-5 text-3xl md:text-4xl self-center -mb-3 font-bold text-white break-all">
                    Q u a r a n t i n e
                </div>
                <div className="w-full mt-5">
                    <div className="w-full justify-self-center text-mg text-grey-500 border rounded-md p-2 border-[#4b4314]">
                        <Quarantine />
                    </div>
                </div>
            </div>
            {/* ------------------------------ Q u e u e  ------------------------------ */}
            <div className="mt-10 md:w-3/5 w-full flex flex-row p-5 justifyitem-center mb-10 items-center drop-shadow-lg rounded-xl text-black bg-neutral-100 bg-opacity-70 backdrop-blur-xl">
                <span className="text-blue-400 font-bold text-sm my-1">
                    <br />
                </span>
                <div className="blur-sm absolute -top-4 right-4 text-3xl md:text-4xl self-center -mb-3 font-bold text-yellow-700 break-all">
                    Q u e u e
                </div>
                <div className="absolute -top-5 right-5 text-3xl md:text-4xl self-center -mb-3 font-bold text-white break-all">
                    Q u e u e
                </div>
                <div className="w-full mt-5">
                    <div className="w-full justify-self-center text-mg text-grey-500 border rounded-md p-2 border-[#4b4314]">
                        <Queue />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Relayers
