import {
  Navbar,
  // Presale,
  MyTokens,
  AdminConsole,
  Aridrop,
  Pool,
  Relayers,
  ERC20,
  Exchange,
  VCWallet,
  Marketplace,
} from "./components";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="App h-max p-5">
        <Navbar />

        <Routes>
          <Route path="/" element={<Exchange />} />
          <Route path="/Airdrop" element={<Aridrop />} />
          <Route path="/admin" element={<Pool />} />
          <Route path="/Relayers" element={<Relayers />} />
          <Route path="/ERC20" element={<ERC20 />} />
          <Route path="/stake" element={<MyTokens />} />
          <Route path="/VCWallet" element={<VCWallet />} />
          <Route path="/Exchange" element={<Exchange />} />
          <Route path="/AdminConsole" element={<AdminConsole />} />
          <Route path="/Marketplace" element={<Marketplace />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
